import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Circle, Map, TileLayer, Marker, Rectangle, Tooltip, Polygon, Popup } from 'react-leaflet';
import L from 'leaflet';
//import OpenPopup from '../Maps/OpenPopup';

import 'font-awesome/css/font-awesome.min.css';

class GeofenceMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarCollapsed: false,
      sidebarSelected: 'geofences',
      newGeofencePosition: props.center ? props.center : [42.877742, -97.380979],
      newGeofenceRadius: 100,
      showNewGeofence: true,
      initialLoad: true,
      mapBounds: [ [49.3457868, -66.9513812], [24.7433195, -124.7844079] ],
      center: props.center,
      // zoom: 5,
      mapId: 'mapbox.streets',
    }
  }

  onSidebarOpen(id) {
    this.setState({
      sidebarCollapsed: false,
      sidebarSelectedId: id
    });
  }

  onSidebarClose() {
    this.setState({
      sidebarCollapsed: true,
      sidebarSelectedId: 'geofences'
    });
  }

  onMapMove(e) {
    console.log(e.target.getCenter());
    this.setState({
      centerMarkerPos: e.target.getCenter()
    });
  }

  onRadiusChange(e) {
    //console.log(e);
    this.setState({
      newGeofenceRadius: e.value
    });
  }

  onMarkerMoveEnd(e) {
    //console.log(e.target.options.position);
    this.setState({
      showNewGeofence: true,
      newGeofencePosition: e.target.options.position
    });
    this.props.onNewGeofencePositionChange(e.target.options.position);
  }

  onMapClick(e) {
    //console.log('On Map Click:', e);
    //console.log('Old pos:', this.state.newGeofencePosition);
    //console.log('Map E:', e);
    
    if (e.latlng && e.latlng.lat && e.latlng.lng) {
      this.setState({
        showNewGeofence: true,
        newGeofencePosition: [e.latlng.lat, e.latlng.lng]
      });
      this.props.onNewGeofencePositionChange([e.latlng.lat, e.latlng.lng]);

      // TEST check for overlap
      this.props.geofences.forEach((gf) => {
        if (this.pointsInRange({lat: e.latlng.lat, lon: e.latlng.lng}, {lat: gf.latitude, lon: gf.longitude}, this.props.newGeofenceRadius, this.props.newGeofenceType)) {
          console.log('Overlap with: ', gf.name);
        } else {
          //console.log('No overlap with:', gf.name);
        }
      });
    }
  }

  pointsInRange(A, B, radius, type) {
    const R = 6378137;
    const latARad = A.lat*Math.PI/180;
    const latBRad = B.lat*Math.PI/180;
    const latDelta = (B.lat-A.lat)*Math.PI/180;
    const lonDelta = (B.lon-A.lon)*Math.PI/180;
    const a = Math.sin(latDelta/2) * Math.sin(latDelta/2) +
      Math.cos(latARad) * Math.cos(latBRad) *
      Math.sin(lonDelta/2) * Math.sin(lonDelta/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c;

    if (type == 'circle') {
      // Circle
      return Math.abs(d) < radius ? true : false;
    } else {
      // Square
    }
  }

  componentDidMount() {
    if (this.props.geofences && this.props.geofences.length > 0) {
      this.zoomToUnits(this.props.geofences);
    }
  }

  componentWillReceiveProps(props) {
    if (props.geofences && props.geofences.length > 0 && !props.match.params.unitLabel) {
      this.zoomToUnits(props.geofences);
    }

    if (props.center) {
      this.setState({
        center: props.center,
        newGeofencePosition: props.setFromAddress ? props.center : this.state.newGeofencePosition
      });
    }

    if (props.zoom) {
      this.setState({
        zoom: props.zoom
      });
    }

    if (this.props != props) {
      if ((((this.refs || {}).map || {}).leafletElement || {}).invalidateSize) {
        console.log('Recalculate map size');
        setTimeout(() => {
          this.refs.map.leafletElement.invalidateSize();
        }, 100);
      }
    }
  }

  zoomToUnits(geofences) {
    // Map center and zoom
    /*
    const avgLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return accum+=gf.latitude;
      } else {
        return accum;
      }
    }, 0) / geofences.length;
    const avgLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return accum+=gf.longitude;
      } else {
        return accum;
      }
    }, 0) / geofences.length;
    */
    const minLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return (gf.latitude < accum ? gf.latitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].latitude);
    const maxLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return (gf.latitude > accum ? gf.latitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].latitude);
    const minLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return (gf.longitude < accum ? gf.longitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].longitude);
    const maxLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return (gf.longitude > accum ? gf.longitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].longitude);
    const latDist = maxLat-minLat;
    const longDist = maxLong-minLong;
    const avgLat = (minLat+maxLat)/2;
    const avgLong = (minLong+maxLong)/2;

    console.log('Lat Dist:', latDist);

    const mapBounds = L.latLngBounds([geofences[0].latitude, geofences[0].longitude])
    
    geofences.forEach((gf) => {
      mapBounds.extend([gf.latitude,gf.longitude])
    });

    console.log('Center:', [avgLat, avgLong]);
    // console.log('GFMAP Zoom Level:', zoom);

    this.setState({
      center: [avgLat, avgLong],
      // zoom,
      // mapBounds: [[minLat, minLong], [maxLat, maxLong]],
      mapBounds,
      initialLoad: false
    });
  }

  getRectBounds(pos, radius) {
    //console.log('Radius:', radius);
    const R = 6378137;
    const lat = pos[0];
    const lon = pos[1];

    const latDelta = radius/R;
    const lonDelta = radius/(R*Math.cos(Math.PI*lat/180));

    const latA = lat-latDelta*180/Math.PI;
    const latB = lat+latDelta*180/Math.PI;
    const lonA = lon-lonDelta*180/Math.PI;
    const lonB = lon+lonDelta*180/Math.PI;

    return [
      [latA, lonA],
      [latB, lonB]
    ];
  }

  getMidLatLong(A, B) {
    const lat1 = A[0] * Math.PI / 180;
    const lon1 = A[1] * Math.PI / 180;
    const lat2 = B[0] * Math.PI / 180;
    const lon2 = B[1] * Math.PI / 180;

    const Bx = Math.cos(lat2) * Math.cos(lon2-lon1);
    const By = Math.cos(lat2) * Math.sin(lon2-lon1);
    const latMid = (Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt( (Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By))) * 180 / Math.PI;
    const lonMid = (lon1 + Math.atan2(By, Math.cos(lat1) + Bx)) * 180 / Math.PI;

    /*
    console.log('A:', A);
    console.log('B:', B);
    console.log('Mid:', [latMid, lonMid]);
    */

    return [latMid, lonMid];
  }

  render() {
    //console.log('Selected Type:', this.props.newGeofenceType);
    console.log('Bounds:', this.state.mapBounds);
    console.log('Zoom:', this.state.zoom);

    return <div>      
      <Map
        {...this.props}
        ref={'map'}
        className="sidebar-map"
        onMove={this.onMapMove.bind(this)}
        onClick={this.onMapClick.bind(this)}
        center={this.state.center}
        zoom={this.state.zoom}
        bounds={this.state.mapBounds}
        animate={true}     
        scrollWheelZoom={false}
        dragging={ !L.Browser.mobile }
      >
        <TileLayer
          attribution=""
          url={'https://api.tiles.mapbox.com/v4/' + (this.props.mapId ? this.props.mapId : this.state.mapId) + '/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoiZ3BzbGVhZGVycyIsImEiOiJjamw4aXhmNXIwMHVmM3BydW04cmJ5cXE0In0.mLNavvJJLhaP6DANzEyatw'}
        />
        {
          this.props.geofences && this.props.geofences.length > 0 && this.props.newGeofenceType != 'polygon'
          ? this.props.geofences.map((gf) => {
            const pos = [gf.latitude, gf.longitude];
            return <React.Fragment>
              <Marker position={pos} onClick={() => { 
                this.props.onGeofenceClick([gf.latitude, gf.longitude]);
                this.setState({ zoom: 16 });
                }}>
                <Tooltip permanent>{gf.name}</Tooltip>
              </Marker>             
              {
                gf.type == 'polygon'
                ? <React.Fragment>
                  <Polygon positions={gf.points.coordinates.map((pos) => ([pos.latitude, pos.longitude]))} />                  
                </React.Fragment>
                : <React.Fragment>                  
                  {                
                    gf.type == 'circle'
                    ? <Circle center={pos} radius={gf.radius/3.28084} />
                    : <Rectangle bounds={this.getRectBounds(pos, gf.radius/3.28084)} />
                  }
                </React.Fragment>
              }             
            </React.Fragment>
          })
          : ''
        }
        {
          this.state.showNewGeofence && this.props.newGeofenceType != ''
          ? this.props.newGeofenceType == 'polygon'
            ? <React.Fragment>
              <Polygon positions={this.props.newGeofencePoints} />
              <Marker position={this.props.newGeofencePosition}>
                <Tooltip permanent>{this.props.newGeofenceName.length > 0 ? this.props.newGeofenceName : 'New'}</Tooltip>
              </Marker>
              {
                this.props.newGeofencePoints.map((vertex, index) => {
                  return <Marker position={vertex} draggable={true} onDrag={(e) => {this.props.onNewPolygonVertexDrag(e, index)}}>
                    <Tooltip permanent>{(index+1)}</Tooltip>
                  </Marker>
                })
              }
              {
                /*
                this.props.newGeofencePoints.map((vertex, index) => {
                  let pos = [0,0]
                  if (index >= this.props.newGeofencePoints.length-1) {
                    pos = this.getMidLatLong(vertex, this.props.newGeofencePoints[0]);
                  } else {
                    pos = this.getMidLatLong(vertex, this.props.newGeofencePoints[index+1]);
                  }
                  return <CircleMarker 
                    center={pos} 
                    onClick={(e) => {
                      console.log('CircleMarker Click:', e);
                      e.originalEvent.preventDefault();
                      e.originalEvent.stopPropagation();
                      e.originalEvent.stopImmediatePropagation();                      
                      
                      this.props.insertNewPolygonVertex(e, index);
                    }}
                  />
                })
                */
              }
            </React.Fragment>
            : <React.Fragment>
              <Marker position={this.state.newGeofencePosition}>
                <Tooltip permanent>{this.props.newGeofenceName.length > 0 ? this.props.newGeofenceName : 'New'}</Tooltip>
              </Marker>
              {
                this.props.newGeofenceType == 'circle'
                ? <Circle center={this.props.newGeofencePosition} radius={this.props.newGeofenceRadius} />
                : <Rectangle bounds={this.getRectBounds(this.props.newGeofencePosition, this.props.newGeofenceRadius)} />
              }              
            </React.Fragment>
          : ''
        }
      </Map>
    </div>
  }
}

export default withRouter(GeofenceMap);