import React from 'react';
import { Circle, CircleMarker, Map, TileLayer, Marker, Rectangle, Tooltip, Polygon, Polyline, Popup } from 'react-leaflet';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import { Button } from 'primereact/components/button/Button';

/* Misc */
import moment from 'moment-timezone';
import L from 'leaflet';
import Helpers from '../../libs/Helpers';

//import TrailMarker from './TrailMarker';

class TrailMap extends React.Component {

  popupRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      mapId: 'mapbox.streets',
      center: [42.877742, -97.380979],
      zoom: 13,
      bounds: [[42.877742, -97.380979],[42.877742, -97.380979]],
      selectedEvent: null,
      trailEvents: [
        {device_type: 'fj1000', event_type: 3},
        {device_type: 'concoxat3', event_type: 35}
      ]
    };
  }

  componentDidMount() {
    this.handleOpen();
  }

  handleOpen() {
    this.setState({showPopUp: true})
  }
  componentWillReceiveProps(props) {
    this.setState({
      selectedEvent: props.selectedEvent,
      heartBeatOnly: props.heartBeatOnly
    }, () => {
      if (props.trails && props.trails.length > 0) {

        const allCords = props.trails.reduce((accum, trail) => {
          return accum.concat(trail.trail.filter((event) => {
            return event.event_data && event.event_data.latitude && event.event_data.longitude
              ? true
              : false;
          }));
        }, []);
        
        const eventCords = props.trails.reduce((accum, trail) => {
          return accum.concat(trail.trail.filter((event) => {
            return event.event_data && event.event_data.latitude && event.event_data.longitude && 
            this.eventFilter(event.device_type, event.event_data.event_type)
              ? true
              : false;
          }));
        }, []);
        this.setBoundsFromCoords((props.heartBeatOnly) ? eventCords: allCords);
      }      
    });

    if (this.props != props) {
      if ((((this.refs || {}).map || {}).leafletElement || {}).invalidateSize) {
        console.log('Recalculate map size');
        setTimeout(() => {
          this.refs.map.leafletElement.invalidateSize();
        }, 100);
      }
    }
  }

  eventFilter(device_type, event_type) {
    return ((device_type === this.state.trailEvents[0].device_type && parseInt(event_type) === this.state.trailEvents[0].event_type) ||
      (device_type === this.state.trailEvents[1].device_type && parseInt(event_type) === this.state.trailEvents[1].event_type));
  }

  componentWillUpdate() {
    this.popupRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.popupRef && this.popupRef.current && this.popupRef.current.leafletElement) {
      console.log('Open')
      const position = [this.state.selectedEvent.event_data.latitude, this.state.selectedEvent.event_data.longitude];
      console.log('Position:', position);

      this.popupRef.current.leafletElement.openPopup(position);
    }
  }

  setCenterFromCoords(coords) {
    const avgLatitude = coords.reduce( (accum, trail) => (accum += trail.event_data.latitude), 0) / coords.length;
    const avgLongitude = coords.reduce( (accum, trail) => (accum += trail.event_data.longitude), 0) / coords.length;
    this.setState({
      center: [avgLatitude, avgLongitude]
    });
  }

  async setBoundsFromCoords(coords) {
    if (this.state.selectedEvent) {
      this.setState({
        bounds: [
          [this.state.selectedEvent.event_data.latitude, this.state.selectedEvent.event_data.longitude],
          [this.state.selectedEvent.event_data.latitude, this.state.selectedEvent.event_data.longitude],
        ]
      })
    } else {
    
      try {
        if (coords[0]) {
          let minLatitude = coords[0].event_data.latitude;
          let minLongitude = coords[0].event_data.longitude;
          let maxLatitude = coords[0].event_data.latitude;
          let maxLongitude = coords[0].event_data.longitude;
    
          await new Promise( (resolve, reject) => {
            coords.forEach( (coord, index) => {
              minLatitude = coord.event_data.latitude < minLatitude ? coord.event_data.latitude : minLatitude;
              maxLatitude = coord.event_data.latitude > maxLatitude ? coord.event_data.latitude : maxLatitude;
              minLongitude = coord.event_data.longitude < minLongitude ? coord.event_data.longitude : minLongitude;
              maxLongitude = coord.event_data.longitude > maxLongitude ? coord.event_data.longitude : maxLongitude;
              if (index => coords.length-1) {
                resolve();
              }
            } );
          } ).then( (res) => {
            this.setState({
              bounds: [
                [minLatitude, minLongitude],
                [maxLatitude, maxLongitude]
              ]
            });
          } );        
        }
      } catch (error) {
        console.info(error);
      }

    }
  }

  defineIcon(icon) {
    let customIcon = new L.Icon.Default();
    if (icon !== 'default') {
      customIcon = new L.Icon({
        iconUrl: require(`../../libs/icons/${icon}`),
        iconRetinaUrl: require(`../../libs/icons/${icon}`),
      });
    }
    return customIcon;
  }

  renderMarkers(cleanTrail, index, trailIcon) {
    try {
      let icon;
      try {
        icon = this.defineIcon(trailIcon)      
      } catch (error) {
        icon = new L.Icon.Default();
      }
      return <React.Fragment>
        <Polyline positions={cleanTrail.map((event) => [event.event_data.latitude, event.event_data.longitude])} key={'trail-' + index} color={'red'} />
        {cleanTrail.map((event, index) => {
          if (this.state.selectedEvent) {
            if (event.event_data.device_time == this.state.selectedEvent.event_data.device_time && event.event_data.latitude == this.state.selectedEvent.event_data.latitude && event.event_data.longitude == this.state.selectedEvent.event_data.longitude) {
              return (
              <React.Fragment>
                <Marker icon={icon} position={[event.event_data.latitude, event.event_data.longitude]} 
                        onClick={() => { this.handleOpen() }}></Marker>
                { (this.state.showPopUp) ? this.renderPopUp(this.state.selectedEvent, index, cleanTrail) : null }  
              </React.Fragment>                      
              );
            }
          }
          else {
            
            return (<Marker icon={icon} position={[event.event_data.latitude, event.event_data.longitude]} onClick={() => {
              this.props.onSelectEvent(event);
            } }>
              <Tooltip permanent>{index == 0 ? 'Start' : index >= cleanTrail.length - 1 ? 'End' : index + 1}</Tooltip>
            </Marker>);
          }
        })}
      </React.Fragment>;      
    } catch (error) {
      // no err
    }
  }  

  handleClose() {
    this.setState({ showPopUp: false })
  }

  renderPopUp(event, index, cleanTrail) {
    const userTimezone = this.props.userTimezone && this.props.userTimezone.length > 0
    ? this.props.userTimezone
    : 'America/Los_Angeles';
    return (<Popup 
      position={[event.event_data.latitude, event.event_data.longitude]} >  
      <div># {index == 0 ? 'Start' : index >= cleanTrail.length - 1 ? 'End' : index + 1}</div>
      <div>Event: {Helpers.cleanEventType(event.device_type, event.event_data.event_type)}</div>
      <div>Time: {moment.unix(event.event_data.device_time).tz(userTimezone).format('MM/DD/YYYY h:mm:ss A')}</div>
      <div>Coords: {Helpers.cleanLatLong(event.event_data.latitude, event.event_data.longitude)}</div>
      <div>Address: {event.event_data.address ? event.event_data.address : 'None'}</div>
      <div>Speed: {Helpers.cleanSpeed(event.device_type, event.event_data.speed ? event.event_data.speed : 0)}</div>
      <div>Heading: {Helpers.degreesToDirection(event.event_data.heading_degrees)}</div>
      <div>Satellites: {event.event_data.satellite_count}</div>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button label="< Prev" disabled={index == 0} onClick={() => {
          if (index > 0) {
            setTimeout(() => this.handleOpen(), 400);
            this.props.onSelectEvent(cleanTrail[index - 1]);
          }
        } } />
        <Button label="Next >" disabled={index >= cleanTrail.length - 1} onClick={() => {
          // console.log('Index:', index);
          // console.log('Event:', cleanTrail[index]);
          if (index < cleanTrail.length - 1) {
            setTimeout(() => this.handleOpen(), 400);            
            this.props.onSelectEvent(cleanTrail[index + 1]);
          }
        } } />
      </div>
    </Popup>
    );
  }
  render() {
    const userTimezone = this.props.userTimezone && this.props.userTimezone.length > 0
    ? this.props.userTimezone
    : 'America/Los_Angeles';

    const getTrails = this.props.trails.map((trail, index) => {
      const validTrail = (this.state.heartBeatOnly) ? 
        trail.trail.filter((event) => (event.event_data && event.event_data.latitude && event.event_data.longitude && this.eventFilter(event.device_type,event.event_data.event_type))) :
        trail.trail.filter((event) => (event.event_data && event.event_data.latitude && event.event_data.longitude));  
      return this.renderMarkers(validTrail, index, trail.icon);
    });

    const trails = this.props.trails && this.props.trails.length > 0 ? getTrails : null

    return <Map
        {...this.props}
        ref={'map'}
        className="sidebar-map"      
        onMove={this.handleClose.bind(this)}
        onClick={this.handleClose.bind(this)}
        zoom={this.state.selectedEvent ? 16 : this.state.zoom}
        bounds={this.state.bounds}
        animate={true}
        scrollWheelZoom={false}
        dragging={ !L.Browser.mobile }
      >
        <TileLayer
          attribution=""
          url={'https://api.tiles.mapbox.com/v4/' + (this.props.mapId ? this.props.mapId : this.state.mapId) + '/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoiZ3BzbGVhZGVycyIsImEiOiJjamw4aXhmNXIwMHVmM3BydW04cmJ5cXE0In0.mLNavvJJLhaP6DANzEyatw'}
        />
        { trails }
        {/* { (this.state.selectedEvent) ? this.renderPopUp(this.state.selectedEvent) : null }         */}
      </Map>;
  }
}

export default TrailMap;