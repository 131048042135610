import React, { Component } from 'react'

/* Prime React */
import { Column } from 'primereact/components/column/Column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import { repoHistory, repoTop, REPORTTYPES, topTable } from './eventTable';

export default class ReportTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      configureModalMap: {
        coords: "",
        display: false
      }
    };

  }

  componentDidMount() {
    const { reportType ='', repo = false } = this.props;
    const reportNameCaps = reportType.toUpperCase();
    const reportToDisplay = (repo)  ? (reportNameCaps === 'HISTORY') ? repoHistory : repoTop :
      REPORTTYPES.reduce((accum, report) => (reportNameCaps.includes(report.name) ? report : accum));
    this.setState({ 
      columns: (reportNameCaps === 'TOP STOPS REPORT') ? topTable.columns : reportToDisplay.columns 
    })
  }


  render() {
    return (this.state.columns.length > 0) ? (
      <React.Fragment>
        <DataTable value={this.props.data} paginator={true} rows={10} resizableColumns={true}
         style={{ fontSize: (this.props.repo) ? '9px' : '12px'}}>
          {
            this.state.columns.map((column, index) => <Column key={index}
              columnKey={column.columnKey}
              field={column.columnKey}
              header={column.header}
              style={column.style}
              sortable={column.sortable}
            />)
          }
        </DataTable>
      </React.Fragment>
    ) : <ProgressSpinner />;
  }

}
