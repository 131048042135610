import React, { Component } from 'react';

import { Card } from 'primereact/components/card/Card';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { ListBox } from 'primereact/components/listbox/ListBox';
import { Panel } from 'primereact/components/panel/Panel';
import { TabView } from 'primereact/components/tabview/TabView';

export default class VehicleOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listViewChecked: true,
      mapViewChecked: true,
      viewSplit: 'horizontal'
    }
  }

  componentDidMount() {
    // Load from local storage
  }

  onListViewCBChange(e) {
    this.setState({
      listViewChecked: e.checked
    });
  }

  onMapViewCBChange(e) {
    this.setState({
      mapViewChecked: e.checked
    });
  }

  render() {
    return <div className="right-panel-scroll-content">
      <Panel header="Options">
        <div className="layout-rightpanel-content clearfix">
          <div className="ui-g-12">
            <Checkbox inputId="listViewCB" checked={this.state.listViewChecked} onChange={this.onListViewCBChange.bind(this)} />
            <label htmlFor="listViewCB">Vehicle List</label>
          </div>
          <div className="ui-g-12">
            <Checkbox inputId="mapViewCB" checked={this.state.mapViewChecked} onChange={this.onMapViewCBChange.bind(this)} />
            <label htmlFor="mapViewCB">Map View</label>
          </div>
        </div>
      </Panel>
    </div>
  }
}