import React, { Component } from 'react';

import { Button } from 'primereact/components/button/Button';
import { Card } from 'primereact/components/card/Card';
import { Column } from 'primereact/components/column/Column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { InputText } from 'primereact/components/inputtext/InputText';
import { ListBox } from 'primereact/components/listbox/ListBox';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { Message } from 'primereact/components/message/Message';
import { Messages } from 'primereact/components/messages/Messages';

import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';
import moment from 'moment-timezone';
import ReactCountdownClock from 'react-countdown-clock';

import VehicleMap from './Vehicles/VehicleMap';

import Helpers from '../libs/Helpers';
import { inputStyles } from '../libs/AppStyle';
import { utimes } from 'fs';

const fancyTime = 'MMM. Do. YYYY h:mm A';
const secondsInADay = 86400;

const USER_QUERY = gql`
  query {
    user {
      timezone
    }
  }
`;

const VEHICLE_MAKE_QUERY = gql`
query {
  vehicleMakes {
		name
    vehiclemake_id
  }
}
`;

const GROUPS_QUERY = gql`
 query {
  groups {
    group_id
    compid
    name
    unitCount
    units {
      unit_id     
      serial
      compid
      repotokenexpire
      repotoken
      lot {
        lot_id
        compid
        name
        createdAt
      }   
      laststoptime
      lastmovetime     
      unittype_id
      imei
      serial
      simnumber
      simphone
      label
      trackingmode
      trackingexpire
      lastreportedvoltage
      createdAt
      lastEvent
      vehicle {
        unit_id
        vehicle_id
        label
        vehiclemake_id
        model
        year
        color
        vin
        stocknumber
        license
        address
        notes
        installed
        icon
        odometer
      }
    }
  }
}`;

const heightOffset = 'calc(100vh - 175px)';
const heightOffsetHalf = 'calc((100vh - 175px) / 2)';

class Vehicles extends Component {

  constructor(props) {
    super(props);

    let showListView = true;
    let showMapView = false;
    let viewSplitHorizontal = true;
    let showListFirst = false;
    let currentHeaders = '';
    let currentFilter = '';
    if (localStorage.getItem('gpsleaders-showlistview') == 1 || localStorage.getItem('gpsleaders-showlistview') == 0) {
      showListView = localStorage.getItem('gpsleaders-showlistview') == 1;
      showMapView = localStorage.getItem('gpsleaders-showmapview') == 1;
      viewSplitHorizontal = localStorage.getItem('gpsleaders-viewsplithorizontal') == 1;
      showListFirst = localStorage.getItem('gpsleaders-showlistfirst') == 1;
    } else {
      localStorage.setItem('gpsleaders-showlistview', 1);
      localStorage.setItem('gpsleaders-showmapview', 0);
      localStorage.setItem('gpsleaders-viewsplithorizontal', 1);
      localStorage.setItem('gpsleaders-showlistfirst', 0);
    }

    if (localStorage.getItem('gpsleaders-currentHeaders')) {
      currentHeaders = localStorage.getItem('gpsleaders-currentHeaders').split(',')
    } else {
      localStorage.setItem('gpsleaders-currentHeaders', ",Event,Address,Time of Event,Time Received");
      currentHeaders = localStorage.getItem('gpsleaders-currentHeaders').split(',')
    }

    /*
    if (localStorage.getItem('gpsleaders-currentFilter')) {
      currentFilter = localStorage.getItem('gpsleaders-currentFilter')
    } else {
      localStorage.setItem('gpsleaders-currentFilter', "");
      currentFilter = localStorage.getItem('gpsleaders-currentFilter')
    }
    */

    this.state = {
      searchText: '',
      showTable: false,
      showFilters: (currentFilter) ? true: false,
      mapWidth: 0,
      mapHeight: 0,
      selectedUnitId: 0,
      inititalLoad: true,
      showListView,
      showMapView,
      viewSplitHorizontal,
      showListFirst,
      mapId: 'mapbox.streets',
      enableTrackingModeButtonDisabled: false,
      enableTrackingModeButtonText: 'Enable Tracking Mode',
      disableTrackingModeButtonDisabled: false,
      disableTrackingModeButtonText: 'Disable Tracking Mode',
      modalIsOpen: false,
      currentHeaders,
      setHeaders: currentHeaders,
      currentFilter,
      setFilter: currentFilter,
      currentMake: 0,
      configureModal: {
        display: false
      },
      vehicle: {},
      repo: {
        email: '',
        phone: '',
        timeframe: 1,
        scriptUpdate: false,
        validEmail: false,
        validPhone: false,
        canSubmit: false,
        unit_id: 0,
        unittype_id: 0
      },
      trackingMode: {
        timeframe: 1,
        interval_seconds: 0,
        unit_id: 0,
        unittype_id: 0
      },
      displayRepoModal: false,
      displayTrackingModeModal: false,
      unit: {},
      toggleMapView: false,
      countdownSeconds: 0,
      //zoomToUnits: [],
      //overlayInfo: {},
      //showInfoOverlay: false,
      //mapZoom: 4,
      //overlayPosition: [41.850033, -87.6500523],
    };
    
    this.closeVehicleModal = this.closeVehicleModal.bind(this);
    this.closeHeaderModal = this.closeHeaderModal.bind(this);
    this.closeFilterModal = this.closeFilterModal.bind(this);
    this.closeRepoModal = this.closeRepoModal.bind(this);
    this.closeTrackingModeModal = this.closeTrackingModeModal.bind(this);
    this.cancelTrackingMode = this.cancelTrackingMode.bind(this);
    this.openHeaderModal = this.openHeaderModal.bind(this);
    this.updateVehicle = this.updateVehicle.bind(this);
    this.submitRepo = this.submitRepo.bind(this);
    this.submitTrackingMode = this.submitTrackingMode.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }

  filterUnits(fullGroups) {
    let groups = fullGroups.concat();

    console.log('Search Text:', this.state.searchText);

    if (this.state.searchText && this.state.searchText.length > 0) {
      groups = groups.map( (group) => {
        return {
          ...group,
          units: group.units.filter( (unit) => {
            if (unit) {
              console.log('Unit:', unit);
              if (unit.label && unit.label.toLowerCase().includes(this.state.searchText.toLowerCase())) return true;
              else if (unit.imei && unit.imei.toLowerCase().includes(this.state.searchText.toLowerCase())) return true;
              else if (unit.serial && unit.serial.toLowerCase().includes(this.state.searchText.toLowerCase())) return true;
              else if (unit.simnumber && unit.simnumber.toLowerCase().includes(this.state.searchText.toLowerCase())) return true;
              else return false;
            } else {
              return false;
            }
          } )
        };
      } );
    }

    if (this.state.showFilters) {
      return groups.map( (group) => {
        return {
          ...group,
          units: group.units.filter( (unit) => {
            switch (this.state.currentFilter) {
              case 'Not Reported (30)':
                if (
                  unit.lastEvent &&
                  unit.lastEvent.event_data &&
                  unit.lastEvent.event_data.device_time
                ) {
                  return unit.lastEvent.event_data.device_time <= (moment().add(-30, 'days').valueOf()/1000);
                } else {
                  return false;
                }
              case 'Not Reported (60)':
                if (
                  unit.lastEvent &&
                  unit.lastEvent.event_data &&
                  unit.lastEvent.event_data.device_time
                ) {
                  return unit.lastEvent.event_data.device_time <= (moment().add(-60, 'days').valueOf()/1000);
                } else {
                  return false;
                }
              case 'Not Reported (90)':
                if (
                  unit.lastEvent &&
                  unit.lastEvent.event_data &&
                  unit.lastEvent.event_data.device_time
                ) {
                  return unit.lastEvent.event_data.device_time <= (moment().add(-90, 'days').valueOf()/1000);
                } else {
                  return false;
                }
              case 'Lots':
                if (
                  unit.lastEvent &&
                  unit.lastEvent.event_data &&
                  unit.lastEvent.event_data.device_time
                ) {
                  return unit.lot !== null;
                } else {
                  return false;
                }
              default:
                return unit;
            }
          } )
        };
      } );
    } else {
      return groups;
    }
  }

  /*
  closeToggle() {
    let { toggleMapView, showMapView } = this.state;
    if (toggleMapView) {
      showMapView = true;
      toggleMapView = false;
      this.setState({ toggleMapView, showMapView }, () => {
        this.zoomToUnits(this.filterUnits(this.props.groups.groups));
      });
    }
  }

  openToggle() {
    let { toggleMapView, showMapView } = this.state;
    if (showMapView) {
      toggleMapView = true;
      showMapView = false;
      this.setState({ toggleMapView, showMapView });
    }
  }
  */

  closeVehicleModal() {
    //this.closeToggle();
    this.setState({ configureModal: { display: false }, showTable: false })
  }

  closeRepoModal() {
    //this.closeToggle();

    const repo = {
      email: '',
      phone: '',
      timeframe: 1,
      scriptUpdate: false,
      validEmail: false,
      validPhone: false,
      canSubmit: false,
      unit_id: 0,
      unittype_id: 0
    };
    this.setState({ repo, displayRepoModal: false });
  }

  closeTrackingModeModal() {

    const trackingMode = {
      timeframe: 1,
      interval_seconds: 0,
      unit_id: 0,
      unittype_id: 0
    };
    this.setState({ trackingMode, displayTrackingModeModal: false });
  }

  openVehicleModal(unit) {
    //this.openToggle();
    const vehicle = unit.vehicle;
    this.setState({ unit, vehicle, label: unit.label, configureModal: { display: true } })
  }

  openRepoModal(unit) {
    //this.openToggle();
    const { unit_id, unittype_id } = unit;
    const repo = { unit_id, unittype_id, scriptUpdate: false, timeframe: 1 };
    this.setState({ displayRepoModal: true, repo, /* showMapView: false */ });
  }

  openTrackingModeModal(unit) {
    const { unit_id, unittype_id } = unit;
    const trackingMode = { unit_id, unittype_id, scriptUpdate: false, timeframe: 1 };
    this.setState({ displayTrackingModeModal: true, trackingMode, /* showMapView: false */ });
  }

  openHeaderModal() {
    //this.openToggle();
    this.setState({ modalIsOpen: true, /* showMapView: false */ });
  }

  closeHeaderModal(action) {
    //this.closeToggle();
    this.setState({
      modalIsOpen: false,
      // showMapView: true,
      currentHeaders: (action) ? this.state.currentHeaders : this.state.setHeaders,
    });
    if (action) {
      this.setState({ setHeaders: this.state.currentHeaders })
      localStorage.setItem('gpsleaders-currentHeaders', this.state.currentHeaders);
    }
  }
  closeFilterModal(action) {
    //this.closeToggle();
    this.zoomToUnits(this.filterUnits(this.props.groups.groups));

    this.setState({
      showFilterModal: false,
      currentFilter: (action) ? this.state.currentFilter : this.state.setFilter,
    });

    if (action) {
      this.setState({ setFilter: this.state.currentFilter })
      //localStorage.setItem('gpsleaders-currentFilter', this.state.currentFilter);
    }
  }

  renderFilterModal() {
    return (
      <Dialog
        visible={this.state.showFilterModal}
        onHide={this.closeFilterModal}
        modal={true}
        appendTo={document.getElementById('app-footer')}
        closable={false}
      >
      {/*
      <Modal
        isOpen={this.state.showFilterModal}
        style={customStyles}
        contentLabel="Set Filters"
      >
        <p> Select Filters </p>
        <ListBox
          options={Helpers.filterOptions}
          value={this.state.currentFilter}
          onChange={(e) => this.setState({ showFilters: true, currentFilter: e.value })}
          style={{ width: '100%', maxHeight: '625px' }}
        />
        <br />
        <br />
        <Button label='Submit' className="ui-button-success" onClick={() => this.closeFilterModal(true)}  />
        <Button label='Cancel' className="ui-button-success" onClick={() => this.closeFilterModal(false)}  />
      */}
        <p> Select Filter </p>
        <ListBox
          options={Helpers.filterOptions}
          value={this.state.currentFilter}
          onChange={(e) => {
            this.setState({
              showFilters: true,
              currentFilter: e.value
            }, () => {
              //this.zoomToUnits(this.props.groups.groups);
            });
          }}
          style={{ width: '100%' }}
        />
        <br />
        <br />
        <Button label='Submit' className="ui-button-success" onClick={() => this.closeFilterModal(true)} />
        <Button label='Cancel' className="ui-button-danger" onClick={() => this.closeFilterModal(false)} /> 
      
      {/* </Modal> */}
      </Dialog>
    );
  }

  renderSetColumnsModal() {
    return (
      <Dialog
        visible={this.state.modalIsOpen}
        onHide={this.closeHeaderModal}
        modal={true}
        appendTo={document.getElementById('app-footer')}
        closable={false}
      >
      {/*
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeHeaderModal}
        style={{
          content: {
            position: 'absolute',
            height: '70%',
            width: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto'
          }
        }}
        contentLabel="Set Headers"
      >
      */}
        <p> Select Table Header </p>
        <ListBox
          multiple={true}
          options={Helpers.headerOptions}
          value={this.state.currentHeaders}
          onChange={(e) => this.setState({ currentHeaders: e.value })}
          style={{ width: '100%' }}
        />
        <br />
        <br />
        <Button label='Submit' className="ui-button-success" onClick={() => this.closeHeaderModal(true)} />
        <Button label='Cancel' className="ui-button-danger" onClick={() => this.closeHeaderModal(false)} />

      {/*</Modal>*/}
      </Dialog>
    )
  }

  componentDidMount() {
    this.setState({
      mapWidth: document.getElementById('map-container').clientWidth - 30,
      mapHeight: 600,//'calc(100vh - 250px)',
      showListView: localStorage.getItem('gpsleaders-showlistview') == 1,
      showMapView: localStorage.getItem('gpsleaders-showmapview') == 1,
      viewSplitHorizontal: localStorage.getItem('gpsleaders-viewsplithorizontal') == 1,
      showListFirst: localStorage.getItem('gpsleaders-showlistfirst') == 1,
      countdownSeconds: 60,
    });

    if (this.props.groups) {
      this.props.groups.refetch();
      /*
      this.mapRefreshIntervalHandle = setInterval(() => {
        this.props.groups.refetch();
      }, 60000);
      */
    }

    if (this.props.groups.groups && this.props.groups.groups.length > 0) {
      this.zoomToUnits(this.props.groups.groups);
    }
  }

  handleRefresh() {
    this.setState({
      countdownSeconds: 0
    });

    //console.log('Refreshing groups...');
    this.props.groups.refetch();

    //console.log('Restarting timer...');
    this.setState({
      countdownSeconds: 60,
    });
  }

  /*
  componentWillUnmount() {
    if (this.mapRefreshIntervalHandle) {
      // cancel timeout
      clearInterval(this.mapRefreshIntervalHandle);
    }
  }
  */

  componentWillReceiveProps(props) {
    if (props.groups.groups && props.groups.groups.length > 0 && this.state.inititalLoad) {
      //console.log('componentWillReceiveProps')
      this.zoomToUnits(props.groups.groups);
    }
  }

  /*
  showOverlay(unit) {
    console.log('Show Overlay:', unit);
    if (unit.unit_id != this.state.selectedUnitId) {
      if (this.map && this.map.leafletElement) {
        this.map.leafletElement.closePopup();
      }

      try {      
        const showInfoOverlay = (this.state.selectedUnitId == unit.unit_id) ? !this.state.showInfoOverlay : true;
        const overlayPosition = [unit.lastEvent.event_data.latitude, unit.lastEvent.event_data.longitude];
        const overlayInfo = unit;
        
        this.setState({
          selectedUnitId: unit.unit_id,
          overlayPosition,
          showInfoOverlay,
          overlayInfo,
          //currentFilter: 'unit',
          //mapZoom: 16
        }, () => {
          //console.log('showOverlay Unit: ', unit);
          this.zoomToUnits([{
            units: [unit]
          }]);
        });
      } catch (error) {
        console.info(error);
      }
    } else {
      console.log('Same unit')
    }
  }
  */

  zoomToUnits(groups) {
    console.log('Zoom to units:', groups);
    // Map center and zoom
    let units = groups.reduce((accum, group, index) => accum.concat(group.units), []).filter(unit => unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.latitude && unit.lastEvent.event_data.longitude);
    /*
    const avgLat = units.reduce((accum, unit, index) => {
      if (unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.latitude) {
        return accum+=unit.lastEvent.event_data.latitude;
      } else {
        return accum;
      }
    }, 0) / units.length;
    const avgLong = units.reduce((accum, unit, index) => {
      if (unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.longitude) {
        return accum+=unit.lastEvent.event_data.longitude;
      } else {
        return accum;
      }
    }, 0) / units.length;
    */

    //37.2582146,-104.654745

    let minLat = 24.396308;
    let minLong = -124.848974;
    let maxLat = 49.384358;
    let maxLong = -66.885444;
    //let avgLat = (minLat + maxLat) / 2;
    //let avgLong = (minLong + maxLong) / 2;

    //console.log('Units After reduce:', units);

    /*
    this.setState({
      zoomToUnits: units
    });
    */

    if (units.length > 0) {
      /*
      if (units.length == 1) {
        const latitude = units[0].lastEvent.event_data.latitude;
        const longitude = units[0].lastEvent.event_data.longitude;

        units.push({
          lastEvent: {
            event_data: {
              latitude: latitude-0.00001,
              longitude: longitude-0.00001,
            }
          }
        });

        units.push({
          lastEvent: {
            event_data: {
              latitude: latitude+0.00001,
              longitude: longitude+0.00001,
            }
          }
        });
      }
      */

      minLat = units.reduce((accum, unit, index) => {
        if (unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.latitude) {
          return (unit.lastEvent.event_data.latitude < accum ? unit.lastEvent.event_data.latitude : accum)
        } else {
          return accum;
        }
      }, units[0].lastEvent.event_data.latitude);
      maxLat = units.reduce((accum, unit, index) => {
        if (unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.latitude) {
          return (unit.lastEvent.event_data.latitude > accum ? unit.lastEvent.event_data.latitude : accum)
        } else {
          return accum;
        }
      }, units[0].lastEvent.event_data.latitude);
      minLong = units.reduce((accum, unit, index) => {
        if (unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.longitude) {
          return (unit.lastEvent.event_data.longitude < accum ? unit.lastEvent.event_data.longitude : accum)
        } else {
          return accum;
        }
      }, units[0].lastEvent.event_data.longitude);
      maxLong = units.reduce((accum, unit, index) => {
        if (unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.longitude) {
          return (unit.lastEvent.event_data.longitude > accum ? unit.lastEvent.event_data.longitude : accum)
        } else {
          return accum;
        }
      }, units[0].lastEvent.event_data.longitude);

      //const latDist = maxLat - minLat;
      //const longDist = maxLong - minLong;

      //avgLat = (minLat + maxLat) / 2;
      //avgLong = (minLong + maxLong) / 2;
    }

    if (this.map && this.map.leafletElement) {
      this.map.leafletElement.fitBounds([[minLat, minLong], [maxLat, maxLong]]);
    } else {
      console.log('Map Element Does Not Exist!');
    }

    
    this.setState({
      //overlayPosition: [avgLat, avgLong],
      //mapZoom: 13-parseInt(latDist*1.1),
      mapBounds: [[minLat, minLong], [maxLat, maxLong]],
      inititalLoad: false
    });
  }

  swapOrder() {
    localStorage.setItem('gpsleaders-showlistfirst', !this.state.showListFirst ? 1 : 0)
    this.setState({
      showListFirst: !this.state.showListFirst
    });
  }

  onListViewClick() {
    localStorage.setItem('gpsleaders-showlistview', !this.state.showListView ? 1 : 0)
    this.setState({
      showListView: !this.state.showListView,
    }, () => {
      if (!this.state.showListView && !this.state.showMapView) {
        this.onMapViewClick();
      }
    });
  }

  onMapViewClick() {
    localStorage.setItem('gpsleaders-showmapview', !this.state.showMapView ? 1 : 0)
    this.setState({
      showMapView: !this.state.showMapView
    }, () => {
      if (!this.state.showListView && !this.state.showMapView) {
        this.onListViewClick();
      }
    });
  }

  onViewSplitClick() {
    localStorage.setItem('gpsleaders-viewsplithorizontal', !this.state.viewSplitHorizontal ? 1 : 0)
    this.setState({
      viewSplitHorizontal: !this.state.viewSplitHorizontal
    });
  }

  /*
  enableTrackingMode(unit_id) {
    this.setState({
      enableTrackingModeButtonDisabled: true,
      enableTrackingModeButtonText: 'Sending...'
    });

    const mutation = gql`
    mutation enableTrackingMode($unit_id: Int!) {
      enableTrackingMode(unit_id: $unit_id)
    }`;

    const variables = { unit_id };
    this.props.client.mutate({ mutation, variables }).then((res) => {
      this.props.groups.refetch();
      this.setState({
        enableTrackingModeButtonDisabled: false,
        enableTrackingModeButtonText: 'Enable Tracking Mode'
      });
      alert('Tracking mode enabled.');
    });
  }

  disableTrackingMode(unit_id) {
    this.setState({
      disableTrackingModeButtonDisabled: true,
      disableTrackingModeButtonText: 'Sending...'
    });
    const mutation = gql`
    mutation enableBatterySaveMode($unit_id: Int!) {
      enableBatterySaveMode(unit_id: $unit_id)
    }`;
    const variables = { unit_id };
    this.props.client.mutate({ mutation, variables }).then((res) => {
      this.props.groups.refetch();
      this.setState({
        disableTrackingModeButtonDisabled: false,
        disableTrackingModeButtonText: 'Disable Tracking Mode'
      });
      alert('Tracking mode disabled.');
    });
  }

  onLabelSaved() {
    this.props.groups.refetch();
  }
  */

  renderVehicleModal() {

    const { state: { configureModal: { display } } } = this;

    const header =(
      <div style={{fontWeight: 'bold', borderBottom: '3px solid #cccccc', padding: '5px', marginBottom: '10px', fontSize: '16px'}}>Edit Vehicle Details</div>
    );

    return (
      <Dialog
        visible={display}
        onHide={this.closeVehicleModal}
        modal={true}
        appendTo={document.getElementById('app-footer')}
        closable={false}
        header={header}
      >
      {/*<Modal isOpen={display} onRequestClose={this.closeVehicleModal} style={customStyles}>*/}
        {this.renderVehicleForm()}
        <Button style={{ float: 'right', marginTop: "10px" }} label='Submit' className="ui-button-primary"
          onClick={this.updateVehicle} />
        <Button style={{ float: 'right', marginTop: "10px" }} label='Cancel' className="ui-button-danger"
          onClick={this.closeVehicleModal} />
      {/*</Modal>*/}
      </Dialog>
    );
  }

  renderRepoModal() {
    const scriptUpdateOptions = [
      { label: "No Script Update", value: false },
      { label: "5 minutes", value: true }
    ]

    const timeFrameOptions = [
      { label: "1 day", value: 1 },
      { label: "2 day", value: 2 },
      { label: "3 day", value: 3 },
      { label: "4 day", value: 4 },
      { label: "5 day", value: 5 },
      { label: "6 day", value: 6 },
      { label: "7 day", value: 7 }
    ];

    const header =(
      <div style={{fontWeight: 'bold', borderBottom: '3px solid #cccccc', padding: '5px', marginBottom: '10px', fontSize: '16px'}}>Repo Mode</div>
    );

    return (
      <Dialog
        visible={this.state.displayRepoModal}
        onHide={this.closeRepoModal}
        modal={true}
        appendTo={document.getElementById('app-footer')}
        closable={false}
        header={header}
        contentStyle={{overflow: 'unset'}}
      >

        <div className="p-inputgroup">
          <label htmlFor="email">Email</label>
          <InputText style={inputStyles} id="email" type="email" value={this.state.repo.email}
            onChange={(e) => this.editRepo('email', e.target.value)} />
        </div>

        <div className="p-inputgroup">
          <label htmlFor="phone">Phone</label>
          <InputText style={inputStyles} id="phone" value={this.state.repo.phone}
            onChange={(e) => this.editRepo('phone', e.target.value)} />
        </div>

        {
          (this.state.repo.unittype_id !== 1) && <div className="p-inputgroup">
            <label htmlFor="scriptUpdate">Script Update</label>
            <Dropdown id="scriptUpdate" options={scriptUpdateOptions} style={inputStyles} value={this.state.repo.scriptUpdate}
              onChange={(e) => this.editRepo('scriptUpdate', e.value)} />
          </div>
        }

        <div className="p-inputgroup">
          <label htmlFor="timeframe">Timeframe</label>
          <Dropdown id="timeframe" options={timeFrameOptions} style={inputStyles} value={this.state.repo.timeframe}
            value={this.state.repo.timeframe} onChange={(e) => this.editRepo('timeframe', e.value)} />
        </div>

        <Button style={{ float: 'right', margin: "10px" }} label='Submit' className="ui-button-primary"
          disabled={(this.state.repo.canSubmit) ? false : true}
          onClick={this.submitRepo}
        />
        <Button style={{ float: 'right', margin: "10px" }} label='Cancel' className="ui-button-danger"
          onClick={this.closeRepoModal} />
      {/*</Modal>*/}
      </Dialog>
    );
  }

  renderTrackingModeModal() {
    // REMOVED SCRIPT UPDATE OPTIONS SINCE WE ONLY SUPPORT 5 MINUTE UPDATES.  WILL ADD BACK IN IF REQUIRED...
    /*
    const scriptUpdateOptions = [
      { label: "No Script Update", value: false },
      { label: "5 minutes", value: 300 }
    ]
    */

    const timeFrameOptions = [
      { label: "1 day", value: 1 },
      { label: "2 day", value: 2 },
      { label: "3 day", value: 3 },
      { label: "4 day", value: 4 },
      { label: "5 day", value: 5 },
      { label: "6 day", value: 6 },
      { label: "7 day", value: 7 }
    ];

    const header =(
      <div style={{fontWeight: 'bold', borderBottom: '3px solid #cccccc', padding: '5px', marginBottom: '10px', fontSize: '16px'}}>Tracking Mode</div>
    );

    return (
      <Dialog
        visible={this.state.displayTrackingModeModal}
        onHide={this.closeTrackingModeModal}
        modal={true}
        appendTo={document.getElementById('app-footer')}
        closable={false}
        header={header}
        contentStyle={{overflow: 'unset'}}
      >

        {
          // REMOVED SCRIPT UPDATE OPTIONS SINCE WE ONLY SUPPORT 5 MINUTE UPDATES.  WILL ADD BACK IN IF REQUIRED...
          /*
          (this.state.trackingMode.unittype_id !== 1) && <div class="p-inputgroup">
            <label htmlFor="interval_seconds">Script Update</label>
            <Dropdown id="interval_seconds" options={scriptUpdateOptions} style={inputStyles} value={this.state.trackingMode.interval_seconds}
              onChange={(e) => this.editTrackingMode('interval_seconds', e.value)} />
          </div>
          */
        }

        <div className="p-inputgroup">
          <label htmlFor="timeframe">Timeframe</label>
          <Dropdown id="timeframe" options={timeFrameOptions} style={inputStyles} value={this.state.trackingMode.timeframe}
            value={this.state.trackingMode.timeframe} onChange={(e) => this.editTrackingMode('timeframe', e.value)} 
          />
        </div>
        <Button style={{ float: 'right', margin: "10px" }} label='Submit' className="ui-button-primary"
          onClick={this.submitTrackingMode}
        />
        <Button style={{ float: 'right', margin: "10px" }} label='Cancel' className="ui-button-danger"
          onClick={this.closeTrackingModeModal} 
        />
      </Dialog>
    );
  }

  async updateVehicle() {

    try {
      let mutation = gql`
      mutation updateVehicle($vehicle_id: Int!, $label: String, $year: Int, $vehiclemake_id: Int, $model: String, $color: String, $vin: String, $odometer: Float, $stocknumber: String, $license: String, $address: String, $notes: String, $installed: Boolean, $icon: String) {
        updateVehicle(vehicle_id: $vehicle_id, label: $label, vehiclemake_id: $vehiclemake_id, model: $model, year: $year, color: $color, vin: $vin, odometer: $odometer, stocknumber: $stocknumber, license: $license, address: $address, notes: $notes, installed: $installed, icon: $icon)
      }
     `;
      const { icon, vehicle_id, label, model, year, color, vin, odometer, stocknumber, license, address, notes, installed } = this.state.vehicle;
      const vehiclemake_id = this.state.currentMake;
      
      const variables = {
        vehicle_id,
        label,
        vehiclemake_id,
        model,
        year,
        color,
        vin,
        odometer: parseFloat(odometer).toFixed(1),
        stocknumber,
        license,
        address,
        notes,
        installed,
        icon,
      };
      await this.props.client.mutate({ mutation, variables });


      if (this.state.unit.label !== this.state.label) {
        mutation = gql`
        mutation updateUnit($unit_id: Int!, $label: String!) {
          updateUnit(unit_id: $unit_id, label: $label)
        }`;
        await this.props.client.mutate({ mutation, variables: { unit_id: this.state.unit.unit_id, label: this.state.label } });
      }



      this.props.groups.refetch();

      this.closeVehicleModal();
    } catch (error) {
      console.info(`update error ${error}`);
      // display message to user
    }
  }

  async submitRepo() {
    try {
      const mutation = gql`
        mutation generateRepoToken($unit_id: Int!, $seconds: Int!, $email: String, $phone: String, $sendUpdateCommand: Boolean) {
          generateRepoToken(unit_id: $unit_id, seconds: $seconds, email: $email, phone: $phone, sendUpdateCommand: $sendUpdateCommand)
        }     
      `;

      const { unit_id, email = "", phone = "", timeframe, scriptUpdate: sendUpdateCommand = false } = this.state.repo;
      const seconds = timeframe * secondsInADay;

      const variables = { unit_id, email, phone, seconds, sendUpdateCommand };
      await this.props.client.mutate({ mutation, variables });
      await this.props.groups.refetch();
      this.closeRepoModal();
      window.alert("This device is now in repo mode and an email/text has been sent. To cancel repo mode before the specified expiration date, please click the repo mode button again.");
    } catch (error) {
      console.info(error);
      alert('there has been an issue please retry')
    }
  }

  async submitTrackingMode() {
    this.setState({
      enableTrackingModeButtonDisabled: true,
      enableTrackingModeButtonText: 'Sending...'
    });

    try {
      const mutation = gql`
      mutation enableTrackingMode($unit_id: Int!, $seconds: Int!, $interval_seconds: Int!) {
        enableTrackingMode(unit_id: $unit_id, seconds: $seconds, interval_seconds: $interval_seconds)
      }`;

      // REMOVED SCRIPT UPDATE OPTIONS SINCE WE ONLY SUPPORT 5 MINUTE UPDATES.  WILL ADD BACK IN IF REQUIRED...    
      //const { unit_id, timeframe, interval_seconds} = this.state.trackingMode;
      const { unit_id, timeframe} = this.state.trackingMode;
      const seconds = timeframe * secondsInADay;
      const interval_seconds = 300;
      const variables = { unit_id, seconds, interval_seconds };
      console.log('Variables: ', variables);
      await this.props.client.mutate({ mutation, variables });
      await this.props.groups.refetch();
      
      this.setState({
        enableTrackingModeButtonDisabled: true,
        enableTrackingModeButtonText: 'Disable Tracking Mode'
      });
      
      this.closeTrackingModeModal();
      
      alert('The command to update the device to enter tracking mode has been successfully submitted.  You will not see the update take effect until the device reports in again.  To cancel tracking mode before the specified expiration date, please click the tracking mode button again.');
    } catch (error) {
      console.info(error);
      alert('There has been an issue please retry');
      this.setState({
        enableTrackingModeButtonDisabled: false,
        enableTrackingModeButtonText: 'Enable Tracking Mode'
      });
    }
  }

  async cancelRepo(unit_id) {
    try {
      const mutation = gql`
        mutation cancelRepoToken($unit_id: Int!,) {
          cancelRepoToken(unit_id: $unit_id) 
        }      
      `;
      const variables = { unit_id };

      await this.props.client.mutate({ mutation, variables });
      await this.props.groups.refetch();
    } catch (error) {
      console.info(error);
    }
  };

  async cancelTrackingMode(unit_id) {
    this.setState({
      enableTrackingModeButtonDisabled: true,
      enableTrackingModeButtonText: 'Sending...'
    });
    
    try {
      const mutation = gql`
        mutation disableTrackingMode($unit_id: Int!) {
          disableTrackingMode(unit_id: $unit_id)
        }`;

      const variables = { unit_id };

      await this.props.client.mutate({ mutation, variables });
      await this.props.groups.refetch();

      this.setState({
        enableTrackingModeButtonDisabled: false,
        enableTrackingModeButtonText: 'Enable Tracking Mode'
      });
      alert('The command to update the device to exit tracking mode has been successfully submitted.  You will not see the update take effect until the device reports in again.  To put the device back into tracking mode, please click the tracking mode button again.');
    } catch (error) {
      console.info(error);
      alert('There has been an issue please retry');
      this.setState({
        enableTrackingModeButtonDisabled: true,
        enableTrackingModeButtonText: 'Disable Tracking Mode'
      });
    }
  };

  renderVehicleForm() {
    try {
      let { state: { vehicle }, props: { vehicleMake: { vehicleMakes } } } = this;
      let currentMake;
      try { currentMake = vehicle.vehicleMake.vehiclemake_id; } catch (error) { currentMake = 0; }

      const vehicleMakeItems = vehicleMakes.map((make) => ({ label: make.name, value: make.vehiclemake_id }));

      return (
        <React.Fragment>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="unitLabel">Unit Serial Number</label>
              <InputText id="unitLabel" style={inputStyles} value={this.state.unit.serial}/>
            </div>
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="unitLabel">Unit Label</label>
              <InputText id="unitLabel" style={inputStyles} value={this.state.label} onChange={(e) => this.editVehicle('unitLabel', e.target.value)} />
            </div>
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="label">Vehicle Label</label>
              <InputText id="label" style={inputStyles} value={this.state.vehicle.label} onChange={(e) => this.editVehicle('label', e.target.value)} />
            </div>
          </div>

          <div className="p-inputgroup">
            <label htmlFor="make">Make</label>
            <Dropdown id="make" value={this.state.currentMake} options={vehicleMakeItems} style={inputStyles}
              onChange={(e) => { this.setState({ currentMake: e.value }) }} placeholder="Select a Vehicle Make" />
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="model">Model</label>
              <InputText id="model" style={inputStyles} value={this.state.vehicle.model} onChange={(e) => this.editVehicle('model', e.target.value)} />
            </div>
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="year">Year</label>
              <InputText id="year" style={inputStyles} value={this.state.vehicle.year} onChange={(e) => this.editVehicle('year', e.target.value)} />
            </div>
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="vin">Vin</label>
              <InputText id="vin" style={inputStyles} value={this.state.vehicle.vin} onChange={(e) => this.editVehicle('vin', e.target.value)} />
            </div>
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="mileage">Mileage</label>              
              <InputText id="mileage" keyfilter="pnum" style={inputStyles} value={this.state.vehicle.odometer} onChange={(e) => this.editVehicle('odometer', e.target.value)} />
            </div>
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="notes">Notes</label>
              <InputText id="notes" style={inputStyles} value={this.state.vehicle.notes} onChange={(e) => this.editVehicle('notes', e.target.value)} />
            </div>
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="license">License Plate</label>
              <InputText id="license" style={inputStyles} value={this.state.vehicle.license} onChange={(e) => this.editVehicle('license', e.target.value)} />
            </div>
          </div>

          <div>
            <div className="p-inputgroup">
              <label htmlFor="color">Color</label>
              <InputText id="color" style={inputStyles} value={this.state.vehicle.color} onChange={(e) => this.editVehicle('color', e.target.value)} />
            </div>
          </div>

          <div className="p-inputgroup">

            <label htmlFor="icon">Icon</label>
            <br />
            <Button label="Select Icon" onClick={() => this.setState({ showTable: true })} />
            {
              this.state.showTable && (
                <table style={{
                  background: 'lightgray',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  marginRight: '-50%',
                  zIndex: 9999,
                  transform: 'translate(-50%, -50%)',
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto'
                }}>
                  {
                    Helpers.iconOptions.map((icon) => {
                      return <img alt={icon.value} src={require(`../libs/icons/${icon.value}`)}
                        onClick={() => this.editVehicle('icon', icon.value)}
                        style={{ display: 'inline-block', margin: '5px 0 0 5px', height: '50px', width: '50px', background: (this.state.vehicle.icon === icon.value) ? 'white' : 'lightgray' }} width="24" />
                    })
                  }
                  <tr>
                    <td>
                      <Button style={{ float: 'right', marginTop: "10px" }} label='Cancel' className="ui-button-danger" onClick={() => this.setState({ showTable: false })} />
                    </td>
                  </tr>
                </table>
              )
            }
          </div>

        </React.Fragment>
      );
    } catch (error) {
      return '';
    }
  }

  iconTemplate(option) {
    if (!option.value) {
      return option.label;
    }
    else {
      return (
        <div className="p-clearfix">
          <img alt={option.value} src={require(`../libs/icons/${option.value}`)}
            style={{ display: 'inline-block', margin: '5px 0 0 5px', height: '50px', width: '50px' }} width="24" />
          <span style={{ float: 'right', margin: '.5em .25em 0 0' }}>{option.label}</span>
        </div>
      );
    }
  }

  editVehicle(key, value) {

    if (key === 'unitLabel') {
      this.setState({ label: value })
    } else {

      if (key === 'icon') {
        this.setState({ showTable: false });
      }
      let vehicle = { ...this.state.vehicle };
      vehicle[key] = value;
      //vehicle[key] = key === 'odometer' ?  parseFloat(value).toFixed(1) : value;
      this.setState({ vehicle });
    }
  }

  validateEmail(email) {
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegEx.test(email);
  }

  validatePhone(phone) {
    if (phone) {
      const phoneRegEx = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
      var digits = phone.replace(/\D/g, "");
      return phoneRegEx.test(digits);
    } else {
      return false;
    }
  }

  editRepo(key, value) {
    let repo = { ...this.state.repo };
    repo[key] = value;

    this.setState({ repo })

    if (key === 'email' || key === 'phone') {
      repo.validEmail = (key === 'email') ? this.validateEmail(value) : this.validateEmail(this.state.repo.email);
      repo.validPhone = (key === 'phone') ? this.validatePhone(value) : this.validatePhone(this.state.repo.phone);
      repo.canSubmit = (repo.validEmail || repo.validPhone) ? true : false;
      this.setState({ repo })
    }
  }

  editTrackingMode(key, value) {
    let trackingMode = { ...this.state.trackingMode };
    trackingMode[key] = value;

    this.setState({ trackingMode })
  }
  

  renderVehicleList() {
    return <ScrollPanel style={{padding: 0, margin: 0, height: (this.state.showMapView && !this.state.viewSplitHorizontal) || !this.state.showMapView ? heightOffset : heightOffsetHalf}}>
      {this.props.groups.groups ? this.filterUnits(this.props.groups.groups).map((group) => {
        return <Card key={'vehicle_card_group_'+group.group_id} header={<div className="card-header1"><b>{group.name}</b> - {group.unitCount} Unit{group.unitCount != 1 ? 's' : ''}</div>} style={{padding: 0, margin: 0,}}>
          {this.renderVehicleModal()}
          {this.renderRepoModal()}
          {this.renderTrackingModeModal()}
          <div><InputText placeholder="Search" value={this.state.searchText} onChange={(e) => {console.log('value:', e.target.value); this.setState({searchText: e.target.value});}} /></div>
          <DataTable value={this.vehicleTableFactory(group)} style={{ overflowX: 'scroll', tableLayout: 'auto', overflowY: 'visible', fontSize: '12px', margin: 0, padding: 0,}}>
            <Column columnKey="label" field="label" header="Unit" sortable={true} style={{ width: '150px' }} />
            {
              Object.entries(Helpers.tableConfig).map((column, index) => {
                if (this.state.currentHeaders.includes(column[1].header)) {
                  return <Column key={index}
                    columnKey={column[1].columnKey}
                    field={column[1].columnKey}
                    header={column[1].header}
                    style={{ width: '200px' }}
                  />
                }
              })
            }
            <Column columnKey="actions" field="actions" header="" style={{ width: '150px' }} />
          </DataTable>
        </Card>;
      }) : ''}

    </ScrollPanel>;
  }

  timeSinceLastEvent(unit) {
    let outputValue = 'No Data';
    let currentTime = moment().valueOf()/1000;
    let diff = currentTime - unit.lastEvent.event_data.device_time;
    if (diff != 0) {
      outputValue = Helpers.secondsToTime(diff);
    }
    
    return outputValue;
    
  }

  buildRow(unit, key, formatValue) {
    let outputValue = 'NO DATA';
    //if (unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data[key] || unit.lastEvent.event_data[key] === 0) {
    if (
      (((unit || {}).lastEvent || {}).event_data || {})[key] ||
      (((unit || {}).lastEvent || {}).event_data || {})[key] === 0
    ) {
      switch (key) {
        case 'speed':
          outputValue = parseInt(unit.lastEvent.event_data[key]) + ' Mph';
          break;
        case 'battery_percentage':
          //console.log('Key: ', unit.lastEvent.event_data[key]);
          outputValue = unit.lastEvent.event_data[key] === 0 ? '0%' : unit.lastEvent.event_data[key] + '%';
          break;
        default:
          outputValue = (formatValue) ? formatValue : unit.lastEvent.event_data[key];
          break;
      }      
    }
    return outputValue;
  }

  buildUnitRow(unit, key) {
    let outputValue = 'N/A';
    let diff = 0;
    if (unit && unit[key]) {
      let currentTime = moment().valueOf()/1000;
      switch (key) {
        case 'lastmovetime':
          diff = currentTime - unit[key];
          outputValue = 'Moving for ' + Helpers.secondsToTime(diff);
          break;

        case 'laststoptime':
          diff = currentTime - unit[key];
          outputValue = 'Stopped for ' + Helpers.secondsToTime(diff);
          break;
      }
      
    }
    return outputValue
  }

  async locateOnDemand(unit_id) {
    try {
      const mutation = gql`
      mutation locateOnDemand($unit_id: Int!,) {
        locateOnDemand(unit_id: $unit_id) 
      }`;
      const variables = { unit_id };

      await this.props.client.mutate({ mutation, variables })
      .then(res => {
        console.log('RES: ', res);
        if (res && res.data && res.data.locateOnDemand) {
          if (res.data.locateOnDemand.success) {
            this.messages.show({severity: 'info', summary: 'Locate on Demand', detail: 'A request for device location has been dispatched.'});
          } else {
            this.messages.show({severity: 'error', summary: 'Locate on Demand', detail: res.data.locateOnDemand.data});
          }
        } else {
          this.messages.show({severity: 'error', summary: 'Locate on Demand', detail: 'Something went wrong. Please contact GPS Leaders.'});
        }
      });
      await this.props.groups.refetch();
    } catch (error) {
      console.info(error);
    }
  }

  vehicleTableFactory(group) {
    const userTimezone = this.props.user && this.props.user.user && this.props.user.user.timezone && this.props.user.user.timezone.length > 0
    ? this.props.user.user.timezone
    : 'America/Los_Angeles';
    
    let list = group.units ? group.units.map((unit) => {
      console.log('Unit: ', unit);
      const latLng = [(((unit || {}).lastEvent || {}).event_data || {}).latitude, (((unit || {}).lastEvent || {}).event_data || {}).longitude];

      return {
        // label: <VehicleLabelEditable unit={unit} onClick={this.showOverlay.bind(this)} onSave={this.onLabelSaved.bind(this)} />,
        label: ((((unit || {}).lastEvent || {}).event_data || {}).latitude && (((unit || {}).lastEvent || {}).event_data || {}).longitude) ?
          (unit.label) ?
            <span style={{cursor: 'pointer', color: '#2d5594', fontWeight: 'bold'}} onClick={() => {

              //Show Overlay
              if (this.map && this.map.leafletElement) {
                const map = this.map.leafletElement;                
                map.fitBounds([latLng, latLng]);
                map.closePopup();
                this.setState({
                  selectedUnitId: unit.unit_id == this.state.selectedUnitId ? null : unit.unit_id,
                });
              } else {
                console.log('Map Element Does Not Exist');
              }
            }}>{unit.label}</span>
            : 'N/A'
          : <span style={{color: '#659EC7', fontWeight: 'bold'}}>
              {unit.label}
            </span>,
        address: unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.address ? this.buildRow(unit, 'address') : 'NO GPS FIX',
        lastEventTime: unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.device_time ? this.buildRow(unit, 'device_time', moment.unix(unit.lastEvent.event_data.device_time).tz(userTimezone).format(fancyTime)) : 'NO DATA',
        timeReceived: unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.server_time ? this.buildRow(unit, 'server_time', moment.unix(unit.lastEvent.event_data.server_time).tz(userTimezone).format(fancyTime)) : 'NO DATA',
        lastEvent: unit.lastEvent && unit.lastEvent.event_data ? unit.lastEvent.event_data.conduit_event_description && unit.lastEvent.event_data.conduit_color_code
          ? <font color={'#' + unit.lastEvent.event_data.conduit_color_code}><b>{unit.lastEvent.event_data.conduit_event_description}</b></font>
          : Helpers.cleanEventType(unit.lastEvent.device_type, unit.lastEvent.event_data.event_type)
        : 'NO DATA',
        moveStatus: unit.lastmovetime && unit.lastmovetime > 0 ? this.buildUnitRow(unit, 'lastmovetime') : unit.laststoptime && unit.laststoptime > 0 ? this.buildUnitRow(unit, 'laststoptime') : 'NO DATA',
        timeSinceLastEvent: unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.device_time ? this.timeSinceLastEvent(unit) : 'NO DATA',
        productType:  unit.lastEvent && unit.lastEvent.device_type ? unit.lastEvent.device_type : 'N/A',
        expirationDate: unit.next_renew_date ? this.buildRow(unit, 'next_renew_date', moment.unix(unit.next_renew_date).tz(userTimezone).format(fancyTime)) : 'NO DATA',
        /* batteryLevel: unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.voltageLevel ? Helpers.getBatteryLifeFromVoltage(unit.lastEvent.event_data.voltageLevel) : unit.lastreportedvoltage ? Helpers.getBatteryLifeFromVoltage(unit.lastreportedvoltage) : 'N/A',// this isn't working */
        expirationDate: unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.trackingexpire ? this.buildRow(unit, 'trackingexpire', moment.unix(unit.lastEvent.event_data.trackingexpire).tz(userTimezone).format(fancyTime)) : 'NO DATA',  //this isn't working
        licensePlate: this.buildVehicleRow(unit, 'license'),
        notes: this.buildVehicleRow(unit, 'notes'),
        speedOfLastEvent: this.buildRow(unit, 'speed'),
        vehicle: this.buildVehicleRow(unit, 'label'),
        vin: this.buildVehicleRow(unit, 'vin'),
        color: this.buildVehicleRow(unit, 'color'),
        lot: (unit.lot) ? unit.lot.name : 'N/A',
        serial: (unit.serial) ? unit.serial : 'N/A',
        mileage:  parseFloat(this.buildVehicleRow(unit, 'odometer')).toFixed(1),
        vehicleBattery:this.buildRow(unit, 'battery_voltage'),
        unitBattery:this.buildRow(unit, 'battery_percentage'),
        // assignedGroups: (unit.group.name)?unit.groups.name:'N/A',
        assignedGroups: (group.name)?group.name:'N/A',
        icons: unit.vehicle && unit.vehicle && unit.vehicle.icon ? <img src={require(`../libs/icons/${unit.vehicle.icon}`)} alt={`${unit.vehicle.icon}`} /> : 'N/A',
        //actions: unit.lastEvent && unit.lastEvent.event_data && unit.lastEvent.event_data.latitude && unit.lastEvent.event_data.longitude && unit.label
        actions: unit.lastEvent && unit.lastEvent.event_data && unit.label
          ? 
          <React.Fragment>
            {
              // Create Geofence
              (unit.lastEvent.event_data.latitude && unit.lastEvent.event_data.longitude)
              ? 
                <React.Fragment>
                <i
                  className="material-icons ui-button-success"
                  style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 30 }}
                  onClick={() => { this.props.history.push('/geofences/' + unit.lastEvent.event_data.latitude + ',' + unit.lastEvent.event_data.longitude + '/' + unit.label); }}
                  id={'createGeofenceHere_'+unit.unit_id}
                >add_box</i><Tooltip for={'#createGeofenceHere_'+unit.unit_id} title="Create Geofence" tooltipPosition="top" />
              </React.Fragment>
              : <React.Fragment>
                <i
                  className="material-icons ui-button-success"
                  style={{ color: '#e6e6e6', cursor: 'not-allowed', fontSize: 30 }}
                  id={'createGeofenceHere_'+unit.unit_id}
                  disabled={true}
                >add_box</i><Tooltip for={'#createGeofenceHere_'+unit.unit_id} title="Create Geofence" tooltipPosition="top" />
              </React.Fragment>
            }
            {
              // TRACKING MODE
                unit.unittype_id === 5
                //? unit.trackingmode === false
                ? (unit.trackingexpire && ((moment().utc().valueOf()/1000) > (moment(unit.trackingexpire).utc().valueOf()/1000)))
                  ? <React.Fragment>
                    <i
                      className="material-icons ui-button-success"
                      style={{ color: '#cccccc', cursor: 'pointer', fontSize: 30 }}
                      //onClick={() => { this.enableTrackingMode(unit.unit_id); }}
                      onClick={() => this.openTrackingModeModal(unit)}
                      id={'trackingMode_'+unit.unit_id}
                    >gps_not_fixed</i>
                    <Tooltip for={'#trackingMode_'+unit.unit_id} title={this.state.enableTrackingModeButtonText} tooltipPosition="top" />
                  </React.Fragment>
                  : <React.Fragment>
                    <i
                      className="material-icons ui-button-success"
                      style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 30 }}
                      onClick={() => this.cancelTrackingMode(unit.unit_id)}
                      //onClick={() => { this.disableTrackingMode(unit.unit_id); }}
                      id={'trackingMode_'+unit.unit_id}
                    >gps_fixed</i>
                    <Tooltip for={'#trackingMode_'+unit.unit_id} title={this.state.disableTrackingModeButtonText} tooltipPosition="top" />
                  </React.Fragment>
                : ''
            }
            {
              // EDIT VEHICLE
              (unit.vehicle)
              ? <React.Fragment>
                <i
                  className="material-icons ui-button-success"
                  style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 30 }}
                  onClick={() => {
                    this.openVehicleModal(unit);
                  }}
                  id={'editVehicle_'+unit.unit_id}
                >edit</i>
                <Tooltip for={'#editVehicle_'+unit.unit_id} title="Edit Vehicle" tooltipPosition="top" />
              </React.Fragment>
              : <React.Fragment>
                <i
                  className="material-icons ui-button-success"
                  style={{ color: '#cccccc', cursor: 'not-allowed', fontSize: 30 }}
                  id={'editVehicle_'+unit.unit_id}
                >edit</i>
                <Tooltip for={'#editVehicle_'+unit.unit_id} title="Edit Vehicle" tooltipPosition="top" />
              </React.Fragment>
            }
            {
              // REPO
              (unit.repotokenexpire && unit.repotoken && ((moment().utc().valueOf()/1000) < unit.repotokenexpire))
              ? <React.Fragment>
                <i
                  className="material-icons ui-button-success"
                  style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 30 }}
                  onClick={() => this.cancelRepo(unit.unit_id)}
                  id={'repo_'+unit.unit_id}
                >cancel</i>
                <Tooltip for={'#repo_'+unit.unit_id} title="Cancel Repo" tooltipPosition="top" />
              </React.Fragment>
              : <React.Fragment>
                <i
                  className="material-icons ui-button-success"
                  style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 30 }}
                  onClick={() => this.openRepoModal(unit)}
                  id={'repo_'+unit.unit_id}
                >build</i>
                <Tooltip for={'#repo_'+unit.unit_id} title="Repo" tooltipPosition="top" />
              </React.Fragment>
            }
            {/*
              // Locate on Demand
              unit.simphone
              ? <React.Fragment>
                <i
                  className="material-icons ui-button-success"
                  style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 30 }}
                  onClick={() => this.locateOnDemand(unit.unit_id)}
                  id={'lod_'+unit.unit_id}
                >location_on</i>
                <Tooltip for={'#lod_'+unit.unit_id} title="Locate" tooltipPosition="top" />
              </React.Fragment>
              : ''
            */}
            {/*
            <Button style={{width: '200px', marginBottom: '3px'}} label="Create Geofence" className="ui-button-success" onClick={() => { this.props.history.push('/geofences/' + unit.lastEvent.event_data.latitude + ',' + unit.lastEvent.event_data.longitude + '/' + unit.label); }} />
            {unit.trackingmode === false
              ? <Button style={{width: '200px', marginBottom: '3px'}} label={this.state.enableTrackingModeButtonText} className="ui-button-danger" disabled={this.state.enableTrackingModeButtonDisabled} onClick={() => { this.enableTrackingMode(unit.unit_id); }} />
              : <Button style={{width: '200px', marginBottom: '3px'}} label={this.state.disableTrackingModeButtonText} className="ui-button-disabled" disabled={this.state.disableTrackingModeButtonDisabled} onClick={() => { this.disableTrackingMode(unit.unit_id); }} />}
            {(unit.vehicle.length) ?
              <Button style={{width: '200px', marginBottom: '3px'}} label="Edit Vehicle" className="ui-button-primary" onClick={() => this.openVehicleModal(unit)} /> :
              <Button style={{width: '200px', marginBottom: '3px'}} label="Edit Vehicle" className="ui-button-primary" disabled={true} />}
            {(unit.repotokenexpire && unit.repotoken) ?
              <Button style={{width: '200px'}} label="Cancel Repo" className="ui-button-primary" onClick={() => this.cancelRepo(unit.unit_id)} /> :
              <Button style={{width: '200px'}} label='Repo'
                className="ui-button-primary" onClick={() => this.openRepoModal(unit)} />}
            */}
          </React.Fragment>
          : 'N/A'
      }
    }) : [];

    return list;

    /*
    if (this.state.showFilters) {
      let filteredList = list;

      switch (this.state.currentFilter) {
        case 'Not Reported (30)':
          filteredList = list.filter((unit) => unit.lastEventTime <= moment().add(-30, 'days').format("MM/DD/YYYY"));
          break;
        case 'Not Reported (60)':
          filteredList = list.filter((unit) => unit.lastEventTime <= moment().add(-60, 'days').format("MM/DD/YYYY"));
          break;
        case 'Not Reported (90)':
          filteredList = list.filter((unit) => unit.lastEventTime <= moment().add(-90, 'days').format("MM/DD/YYYY"));
          break;
        case 'Lots':
          filteredList = list.filter((unit) => (unit.lot !== 'N/A'));
          break;
        case 'Low battery':
          filteredList = list.filter((unit) => {
            try {
              if (unit.batteryLevel.props.children[0] <= 25) {
                return true
              }
            } catch (error) {
              return false;
            }
          });
          break;
        default:
          break;
      }
      return filteredList;
    } else {
      return list;
    }
    */
  }

  buildVehicleRow(unit, key) {    
    return unit.vehicle && unit.vehicle[key] !== null ? unit.vehicle[key] : 'N/A';
  }

  /*
  handleMapChanged(bounds) {
    const mapBounds = [
      [bounds._northEast.lat, bounds._northEast.lng],
      [bounds._southWest.lat, bounds._southWest.lng],
    ];
  }
  */

  mapDidMount(map) {
    this.map = map;
  }

  /*
  onMapPopupOpen(unit_id) {
    this.setState({
      openPopupUnitId: unit_id

    });
  }
  */

  onClosePopup() {
    this.setState({
      selectedUnitId: null
    });
  }

  render() {

    const mapWidth = document.getElementById('map-container') ? document.getElementById('map-container').clientWidth - 30 : 0;
    const vehicles = this.props.groups && this.props.groups.groups && this.props.groups.groups.length > 0
      ? this.filterUnits(this.props.groups.groups).map((group) => {
        return group.units && group.units.length > 0
          ? group.units.map((unit) => {
            return unit.lastEvent && unit.lastEvent.event_data
              ? {
                info: unit,
                position: (
                  unit
                  && unit.lastEvent
                  && unit.lastEvent.event_data
                  && unit.lastEvent.event_data.latitude
                  && unit.lastEvent.event_data.longitude
                )
                  ? [unit.lastEvent.event_data.latitude, unit.lastEvent.event_data.longitude]
                  : null
              }
              : {
                info: unit,
                position: null
              }
          })
          : []
      })
      : [];

    const listContent = this.renderVehicleList();

    const mapContent = <VehicleMap
      onClosePopup={this.onClosePopup}
      style={{
        height: (this.state.showListView && !this.state.viewSplitHorizontal) || !this.state.showListView ? heightOffset : heightOffsetHalf
      }}
      vehicles={vehicles}
      mapId={this.state.mapId}
      bounds={this.state.mapBounds}
      scrollWheelZoom={false}
      didMount={this.mapDidMount.bind(this)}
      timeSinceLastEvent={this.timeSinceLastEvent.bind(this)}
      selectedUnitId={this.state.selectedUnitId}
      user={this.props.user}
      buildUnitRow={this.buildUnitRow.bind(this)}
      //showPopUp={this.state.showPopUp}
      //overlayInfo={this.state.overlayInfo}
      //enableTrackingMode={this.enableTrackingMode.bind(this)}
      //disableTrackingMode={this.disableTrackingMode.bind(this)}
      //onPopupOpen={this.onMapPopupOpen.bind(this)}
      //zoomToUnits={this.state.zoomToUnits}
      //onMapChanged={this.handleMapChanged.bind(this)}      
      //zoom={this.state.mapZoom}
      //center={this.state.overlayPosition}
    />

    let content = this.state.viewSplitHorizontal
      || (!this.state.viewSplitHorizontal && !this.state.showListView && this.state.showMapView)
      || (!this.state.viewSplitHorizontal && this.state.showListView && !this.state.showMapView)
      ? this.state.showListFirst
        ? <div className="card" id="map-container" style={{ padding: 0, height: heightOffset, }}>
          {this.state.showListView ? listContent : ''}
          {this.state.showMapView ? mapContent : ''}
        </div>
        : <div className="card" id="map-container" style={{ padding: 0, height: heightOffset, }}>
          {this.state.showMapView ? mapContent : ''}
          {this.state.showListView ? listContent : ''}
        </div>
      : <div className="card" id="map-container" style={{ padding: 0, height: heightOffset, }}>
        {
          this.state.showListFirst
            ? <div className="ui-g" style={{ padding: 0 }}>
              <div className="ui-g-6" style={{ padding: 0 }}>{this.state.showListView ? listContent : ''}</div>
              <div className="ui-g-6" style={{ padding: 0 }}>{this.state.showMapView ? mapContent : ''}</div>
            </div>
            : <div className="ui-g" style={{ padding: 0 }}>
              <div className="ui-g-6" style={{ padding: 0 }}>{this.state.showMapView ? mapContent : ''}</div>
              <div className="ui-g-6" style={{ padding: 0 }}>{this.state.showListView ? listContent : ''}</div>
            </div>
        }
      </div>;

    // TEST
    /*
    content = <div className="card clearfix" id="map-container" style={{ padding: 0 }}>
      <SplitPane split="horizontal">
        {this.state.showListView ? <div>{listContent}</div> : ''}
        {this.state.showMapView ? <div>{mapContent}</div> : ''}
      </SplitPane>
    </div>
    */

    return <div className="ui-g" style={{ padding: 0 }}>
      <Messages ref={(el) => this.messages = el}></Messages>
      <div className="ui-g-12" style={{ padding: 0 }}>
        <div className="card clearfix" style={{ padding: 0 }}>          
          <div className="ui-g-12 ui-md-6">            
            <i
              className="material-icons"
              style={{ color: this.state.showListView ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30 }}
              onClick={this.onListViewClick.bind(this)}
              id="toggleListView"
            >view_list</i><Tooltip for="#toggleListView" title="Toggle List View" tooltipPosition="top" />
            <i
              className="material-icons"
              style={{ color: this.state.showMapView ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30 }}
              onClick={this.onMapViewClick.bind(this)}
              id="toggleMapView"
            >map</i><Tooltip for="#toggleMapView" title="Toggle Map View" tooltipPosition="top" />
            <i
              className="material-icons"
              style={{ color: this.state.showListView && this.state.showMapView ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30 }}
              onClick={this.onViewSplitClick.bind(this)}
              id="splitOrientation"
            >{this.state.viewSplitHorizontal ? 'border_horizontal' : 'border_vertical'}</i><Tooltip for="#splitOrientation" title="Horizontal or Vertical Split" tooltipPosition="top" />
            <i
              className="material-icons"
              style={{ color: this.state.showListView && this.state.showMapView ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30 }}
              onClick={this.swapOrder.bind(this)}
              id="swapViews"
            >{this.state.viewSplitHorizontal ? 'swap_vert' : 'swap_horizontal'}</i><Tooltip for="#swapViews" title="Swap View Order" tooltipPosition="top" />
            <i
              className="material-icons"
              style={{ color: this.state.mapId == 'mapbox.streets-satellite' ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30 }}
              onClick={() => { this.setState({ mapId: this.state.mapId == 'mapbox.streets-satellite' ? 'mapbox.streets' : 'mapbox.streets-satellite' }) }}
              id="toggleSatelliteView"
            >satellite</i><Tooltip for="#toggleSatelliteView" title="Toggle Satellite View" tooltipPosition="top" />
            <div style={{float: 'left'}} id="counter">
              <ReactCountdownClock                
                seconds={this.state.countdownSeconds}
                color="#51ce9e"
                alpha={0.9}
                size={30}
                weight={3}
                fontSize="14px"
                showMilliseconds={false}
                onComplete={this.handleRefresh.bind(this)}
              />              
            </div>
            <Tooltip for="#counter" title="Seconds until map refresh" tooltipPosition="top" />
          </div>
          
          <div className="ui-g-12 ui-md-6" style={{ textAlign: 'right' }}>
            {/*
            <SelectButton
              value={this.state.mapId}
              options={[
                {
                  label: 'Street',
                  value: 'mapbox.streets'
                },{
                  label: 'Satellite',
                  value: 'mapbox.streets-satellite'
                }]
              }
              onChange={(e) => {
                this.setState({
                  mapId: e.value
                });
              }}
            />
            */}
            {/*
            <Button
              label="Street"
              style={{
                backgroundColor: this.state.mapId == 'mapbox.streets' ? '#51ce9e' : '#cccccc'
              }}
              onClick={() => { this.setState({ mapId: 'mapbox.streets' }) }}
            />
            <Button
              label="Satellite"
              style={{
                backgroundColor: this.state.mapId == 'mapbox.streets-satellite' ? '#51ce9e' : '#cccccc'
              }}
              onClick={() => { this.setState({ mapId: 'mapbox.streets-satellite' }) }}
            />
            */}
            <Button
              label="Show All"
              style={{
                backgroundColor: '#51ce9e',
              }}
              onClick={() => {
                this.setState({ currentFilter: '' });
                //localStorage.setItem('gpsleaders-currentFilter', '');

                if (this.map && this.map.leafletElement) {
                  this.map.leafletElement.closePopup();
                }
                
                if (this.props.groups.groups && this.props.groups.groups.length > 0) {
                  this.zoomToUnits(this.props.groups.groups);
                }
              }}
            />
            <Button
              label="Headers"
              style={{ backgroundColor: '#51ce9e' }}
              onClick={this.openHeaderModal}
            />
            {/*
            <MultiSelect
              placeholder="Headers"
              fixedPlaceholder={true}
              style={{
                position: 'relative',
                top: '4px',
                height: '32px',
                borderRadius: '3px',
                backgroundColor: '#51ce9e',
                color: 'white',
                filter: 'drop-shadow(0px 1px 1px #ccc)',
                padding: '5px',
                marginRight: '3px',
              }}
              filter={false}
              value={this.state.currentHeaders}
              options={Helpers.headerOptions}
              onChange={(e) => this.setState({ currentHeaders: e.value })}
              appendTo={document.getElementById('app-footer')}
            />
            */}
            {/*
            <Button
              label="Filters"
              style={{ backgroundColor: '#51ce9e' }}
              onClick={() => {  
                this.setState({showFilterModal: true})
                //this.openToggle()
              }}
            />
            */}
            <Dropdown
              style={{
                height: '33px',
                borderRadius: '3px',
                backgroundColor: '#51ce9e',
                color: 'white',
                filter: 'drop-shadow(0px 1px 1px #ccc)',
                padding: '5px',
              }}
              value={this.state.currentFilter}
              options={[
                {
                  label: 'No Filters',
                  value: ''
                },
                ...Helpers.filterOptions
              ]}
              onChange={(e) => {
                //console.log('E:', e)
                this.setState({
                  showFilters: e.value != 'all',
                  currentFilter: e.value
                }, () => {
                  setTimeout(() => {
                    this.zoomToUnits(this.filterUnits(this.props.groups.groups));
                  }, 100);
                });
              }}
              appendTo={document.getElementById('app-footer')}
            />
          </div>
        </div>
        {/*this.renderFilterModal()*/}
        {this.renderSetColumnsModal()}
        {content}
      </div>
    </div>
  }
}

export default compose(
  graphql(GROUPS_QUERY, {
    name: 'groups',
  }),
  graphql(VEHICLE_MAKE_QUERY, {
    name: 'vehicleMake',
  }),
  graphql(USER_QUERY, {
    name: 'user',
  }),
)(withRouter(withApollo(Vehicles)));