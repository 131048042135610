export const customStyles = {
  content: {
    position: 'absolute',
    height: '60%',
    width: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto'
  }
};

export const inputStyles = {
  width: '100%',
  padding: '5px',
  marginTop: "5px"
}