import React from 'react';

const Helpers = {
  secondsToTime(seconds) {
    let timeString = [];
    let secondsLeft = seconds;
    let includeMinutes = true;
    let includeSeconds = true;

    if (secondsLeft >= 60*60*24) {
      const days = Math.floor(secondsLeft/60/60/24);
      timeString.push(days + ' day' + (days > 1 ? 's' : ''));
      secondsLeft -= days*60*60*24;
      includeMinutes = false;
      includeSeconds = false;
    }
    if (secondsLeft >= 60*60) {
      const hours = Math.floor(secondsLeft/60/60);
      timeString.push(hours + ' hr' + (hours > 1 ? 's' : ''));
      secondsLeft -= hours*60*60;
      includeSeconds = false;
    }
    if (secondsLeft >= 60 && includeMinutes == true) {
      const minutes = Math.floor(secondsLeft/60);
      timeString.push(minutes + ' min' + (minutes > 1 ? 's' : ''));
      secondsLeft -= minutes*60;
    }
    if (secondsLeft > 0 && includeSeconds == true) {
      timeString.push(parseInt(secondsLeft) + ' sec' + (parseInt(secondsLeft) > 1 ? 's' : ''));
    }

    return timeString.join(', ');
  },

  getBatteryLifeFromVoltage: (voltage) => {
    const percentage = parseInt((voltage-2.6)/0.01);
    let color = '#388E3C';
    if (percentage < 85) color = '#689F38';
    if (percentage < 70) color = '#AFB42B';
    if (percentage < 55) color = '#FBC02D';
    if (percentage < 37) color = '#FFA000';
    if (percentage < 25) color = '#F57C00';
    if (percentage < 15) color = '#E64A19';

    return <span style={{ color }}>{percentage}%</span>;
  },
  cleanSpeed: (unittype, speed) => {
    switch (unittype) {
      case 'fj1000':
        return Helpers.cleanSVR1000Speed(speed) + ' Mph';
      case 'concoxat3':
        return Helpers.cleanConcoxAT3Speed(speed) + ' Mph';
      default:
        return speed.toFixed(0) + ' Mph';
    }
  },  
  cleanSVR1000Speed: (speed) => {
    return parseInt(speed);
  },  
  cleanConcoxAT3Speed: (speed) => {
    return parseInt(speed*0.621371);
  },
  cleanConduitEvent: (event) => {
    if (event.event_data.conduit_event_description && event.event_data.conduit_color_code) {
      return <font color={'#'+event.event_data.conduit_color_code}><b>{event.event_data.conduit_event_description}</b></font>
    } else {
      return Helpers.cleanEventType(event.device_type, event.event_data.event_type);
    }
  },
  cleanEventType: (unittype, eventType) => {
    switch (unittype) {
      case 'fj1000':
        return Helpers.cleanSVR1000EventType(eventType);
      case 'concoxat3':
        return Helpers.cleanConcoxAT3EventType(eventType);
      default:
        return 'Event: ' + eventType;
    }  
  },  
  cleanSVR1000EventType: (eventType) => {
    switch (parseInt(eventType)) {
      case 2: return 'Ignition OFF';
      case 3: return 'Heartbeat';
      case 4: return 'Ignition ON';
      case 5: return <font color="green"><b>Move Interval</b></font>;
      case 6: return 'In1 High';
      case 7: return 'In1 Low';
      case 8: return 'In2 High';
      case 9: return 'In2 Low';
      case 10: return 'In3 High';
      case 11: return 'In3 Low';
      case 12: return 'In4 High';
      case 13: return 'In4 Low';
      case 22: return 'Power Connected';
      case 23: return 'Power Disconnected';
      case 24: return 'Starter Disabled';
      case 25: return 'Starter Enabled';
      case 26: return 'Stop';
      case 27: return 'Minor Location';
      case 28: return 'Virtual Ignition ON';
      case 30: return 'GPS Acquired';
      case 33: return 'Hard Acceleration';
      case 34: return 'Hard Brake';
      case 35: return 'Hard Left';
      case 36: return 'Hard Right';
      case 39: return 'Cold Boot';
      case 42: return 'Warm Boot';
      case 44: return 'Periodic Boot';
      case 104: return 'Vibe Wake';
      case 105: return 'Volt Wake';
      case 106: return 'Ephem Wake';
      case 107: return 'Sleep';
      case 108: return 'Period Boot';
      case 110: return 'Comm Reset for Session Unavailable';
      case 111: return 'GPS Reset';
      case 112: return 'Comm Reset for No Ack';
      case 113: return 'Comm Reset for No AT response';
      case 114: return 'Comm Reset by Command';
      case 116: return 'Absolute G Wake';
      default: return 'Unknown';
    }
  },  
  cleanConcoxAT3EventType: (eventType) => {
    let eventString = 'Protocol '+eventType;
    let color = 'gray';

    switch (eventType) {
      case 1:
        eventString = 'Login';
        break;
      case 25:
        eventString = 'LBS Alarm';
        color = 'red';
        break;
      case 34:
        eventString ='GPS';
        color = 'green';
        break;
      case 35:
        eventString = 'Heartbeat';
        color = 'blue';
        break;
      case 38:
        eventString = 'Alarm';
        color = 'red';
        break;
      case 40:
        eventString = 'Ex. Info.';
        break;
      case 148:
        eventString = 'Information';
        break;
    }

    return <font color={color}><b>{eventString}</b></font>
  },  
  degreesToDirection: (degrees) => {
    const directions = [
      'N',
      'NE',
      'E',
      'SE',
      'S',
      'SW',
      'W',
      'NW'
    ];
    return directions[parseInt(degrees/(360/directions.length))];
  },
  cleanLatLong: (lat, long) => {
    return ''+parseFloat(lat).toFixed(5)+','+parseFloat(long).toFixed(5);
  },
  states: 
  [
    { label: 'Alabama',  value:         'AL' },
    { label: 'Alaska',  value:          'AK' },
    { label: 'Arizona',  value:         'AZ' },
    { label: 'Arkansas',  value:        'AR' },
    { label: 'California',  value:      'CA' },
    { label: 'Colorado',  value:        'CO' },
    { label: 'Connecticut',  value:     'CT' },
    { label: 'Delaware',  value:        'DE' },
    { label: 'Florida',  value:         'FL' },
    { label: 'Georgia',  value:         'GA' },
    { label: 'Hawaii',  value:          'HI' },
    { label: 'Idaho',  value:           'ID' },
    { label: 'Illinois',  value:        'IL' },
    { label: 'Indiana',  value:         'IN' },
    { label: 'Iowa',  value:            'IA' },
    { label: 'Kansas',  value:          'KS' },
    { label: 'Kentucky',  value:        'KY' },
    { label: 'Louisiana',  value:       'LA' },
    { label: 'Maine',  value:           'ME' },
    { label: 'Maryland',  value:        'MD' },
    { label: 'Massachusetts',  value:   'MA' },
    { label: 'Michigan',  value:        'MI' },
    { label: 'Minnesota',  value:       'MN' },
    { label: 'Mississippi',  value:     'MS' },
    { label: 'Missouri',  value:        'MO' },
    { label: 'Montana',  value:         'MT' },
    { label: 'Nebraska',  value:        'NE' },
    { label: 'Nevada',  value:          'NV' },
    { label: 'New Hampshire',   value:  'NH' },
    { label: 'New Jersey',   value:     'NJ' },
    { label: 'New Mexico',   value:     'NM' },
    { label: 'New York',   value:       'NY' },
    { label: 'North Carolina',   value: 'NC' },
    { label: 'North Dakota',   value:   'ND' },
    { label: 'Ohio',  value:            'OH' },
    { label: 'Oklahoma',  value:        'OK' },
    { label: 'Oregon',  value:          'OR' },
    { label: 'Pennsylvania',  value:    'PA' },
    { label: 'Rhode Island',   value:   'RI' },
    { label: 'South Carolina',   value: 'SC' },
    { label: 'South Dakota', value:     'SD' },
    { label: 'Tennessee',  value:       'TN' },
    { label: 'Texas',  value:           'TX' },
    { label: 'Utah',  value:            'UT' },
    { label: 'Vermont',  value:         'VT' },
    { label: 'Virginia',  value:        'VA' },
    { label: 'Washington',  value:      'WA' },
    { label: 'West Virginia', value:    'WV' },
    { label: 'Wisconsin',  value:       'WI' },
    { label: 'Wyoming',  value:         'WY' },
  ],
  countries: [

    {value:"AF", label: "Afghanistan"},
    {value:"AX", label: "Åland Islands"},
    {value:"AL", label: "Albania"},
    {value:"DZ", label: "Algeria"},
    {value:"AS", label: "American Samoa"},
    {value:"AD", label: "Andorra"},
    {value:"AO", label: "Angola"},
    {value:"AI", label: "Anguilla"},
    {value:"AQ", label: "Antarctica"},
    {value:"AG", label: "Antigua and Barbuda"},
    {value:"AR", label: "Argentina"},
    {value:"AM", label: "Armenia"},
    {value:"AW", label: "Aruba"},
    {value:"AU", label: "Australia"},
    {value:"AT", label: "Austria"},
    {value:"AZ", label: "Azerbaijan"},
    {value:"BS", label: "Bahamas"},
    {value:"BH", label: "Bahrain"},
    {value:"BD", label: "Bangladesh"},
    {value:"BB", label: "Barbados"},
    {value:"BY", label: "Belarus"},
    {value:"BE", label: "Belgium"},
    {value:"BZ", label: "Belize"},
    {value:"BJ", label: "Benin"},
    {value:"BM", label: "Bermuda"},
    {value:"BT", label: "Bhutan"},
    {value:"BO", label: "Bolivia, Plurinational State of"},
    {value:"BQ", label: "Bonaire, Sint Eustatius and Saba"},
    {value:"BA", label: "Bosnia and Herzegovina"},
    {value:"BW", label: "Botswana"},
    {value:"BV", label: "Bouvet Island"},
    {value:"BR", label: "Brazil"},
    {value:"IO", label: "British Indian Ocean Territory"},
    {value:"BN", label: "Brunei Darussalam"},
    {value:"BG", label: "Bulgaria"},
    {value:"BF", label: "Burkina Faso"},
    {value:"BI", label: "Burundi"},
    {value:"KH", label: "Cambodia"},
    {value:"CM", label: "Cameroon"},
    {value:"CA", label: "Canada"},
    {value:"CV", label: "Cape Verde"},
    {value:"KY", label: "Cayman Islands"},
    {value:"CF", label: "Central African Republic"},
    {value:"TD", label: "Chad"},
    {value:"CL", label: "Chile"},
    {value:"CN", label: "China"},
    {value:"CX", label: "Christmas Island"},
    {value:"CC", label: "Cocos (Keeling) Islands"},
    {value:"CO", label: "Colombia"},
    {value:"KM", label: "Comoros"},
    {value:"CG", label: "Congo"},
    {value:"CD", label: "Congo, the Democratic Republic of the"},
    {value:"CK", label: "Cook Islands"},
    {value:"CR", label: "Costa Rica"},
    {value:"CI", label: "Côte d'Ivoire"},
    {value:"HR", label: "Croatia"},
    {value:"CU", label: "Cuba"},
    {value:"CW", label: "Curaçao"},
    {value:"CY", label: "Cyprus"},
    {value:"CZ", label: "Czech Republic"},
    {value:"DK", label: "Denmark"},
    {value:"DJ", label: "Djibouti"},
    {value:"DM", label: "Dominica"},
    {value:"DO", label: "Dominican Republic"},
    {value:"EC", label: "Ecuador"},
    {value:"EG", label: "Egypt"},
    {value:"SV", label: "El Salvador"},
    {value:"GQ", label: "Equatorial Guinea"},
    {value:"ER", label: "Eritrea"},
    {value:"EE", label: "Estonia"},
    {value:"ET", label: "Ethiopia"},
    {value:"FK", label: "Falkland Islands (Malvinas)"},
    {value:"FO", label: "Faroe Islands"},
    {value:"FJ", label: "Fiji"},
    {value:"FI", label: "Finland"},
    {value:"FR", label: "France"},
    {value:"GF", label: "French Guiana"},
    {value:"PF", label: "French Polynesia"},
    {value:"TF", label: "French Southern Territories"},
    {value:"GA", label: "Gabon"},
    {value:"GM", label: "Gambia"},
    {value:"GE", label: "Georgia"},
    {value:"DE", label: "Germany"},
    {value:"GH", label: "Ghana"},
    {value:"GI", label: "Gibraltar"},
    {value:"GR", label: "Greece"},
    {value:"GL", label: "Greenland"},
    {value:"GD", label: "Grenada"},
    {value:"GP", label: "Guadeloupe"},
    {value:"GU", label: "Guam"},
    {value:"GT", label: "Guatemala"},
    {value:"GG", label: "Guernsey"},
    {value:"GN", label: "Guinea"},
    {value:"GW", label: "Guinea-Bissau"},
    {value:"GY", label: "Guyana"},
    {value:"HT", label: "Haiti"},
    {value:"HM", label: "Heard Island and McDonald Islands"},
    {value:"VA", label: "Holy See (Vatican City State)"},
    {value:"HN", label: "Honduras"},
    {value:"HK", label: "Hong Kong"},
    {value:"HU", label: "Hungary"},
    {value:"IS", label: "Iceland"},
    {value:"IN", label: "India"},
    {value:"ID", label: "Indonesia"},
    {value:"IR", label: "Iran, Islamic Republic of"},
    {value:"IQ", label: "Iraq"},
    {value:"IE", label: "Ireland"},
    {value:"IM", label: "Isle of Man"},
    {value:"IL", label: "Israel"},
    {value:"IT", label: "Italy"},
    {value:"JM", label: "Jamaica"},
    {value:"JP", label: "Japan"},
    {value:"JE", label: "Jersey"},
    {value:"JO", label: "Jordan"},
    {value:"KZ", label: "Kazakhstan"},
    {value:"KE", label: "Kenya"},
    {value:"KI", label: "Kiribati"},
    {value:"KP", label: "Korea, Democratic People's Republic of"},
    {value:"KR", label: "Korea, Republic of"},
    {value:"KW", label: "Kuwait"},
    {value:"KG", label: "Kyrgyzstan"},
    {value:"LA", label: "Lao People's Democratic Republic"},
    {value:"LV", label: "Latvia"},
    {value:"LB", label: "Lebanon"},
    {value:"LS", label: "Lesotho"},
    {value:"LR", label: "Liberia"},
    {value:"LY", label: "Libya"},
    {value:"LI", label: "Liechtenstein"},
    {value:"LT", label: "Lithuania"},
    {value:"LU", label: "Luxembourg"},
    {value:"MO", label: "Macao"},
    {value:"MK", label: "Macedonia, the former Yugoslav Republic of"},
    {value:"MG", label: "Madagascar"},
    {value:"MW", label: "Malawi"},
    {value:"MY", label: "Malaysia"},
    {value:"MV", label: "Maldives"},
    {value:"ML", label: "Mali"},
    {value:"MT", label: "Malta"},
    {value:"MH", label: "Marshall Islands"},
    {value:"MQ", label: "Martinique"},
    {value:"MR", label: "Mauritania"},
    {value:"MU", label: "Mauritius"},
    {value:"YT", label: "Mayotte"},
    {value:"MX", label: "Mexico"},
    {value:"FM", label: "Micronesia, Federated States of"},
    {value:"MD", label: "Moldova, Republic of"},
    {value:"MC", label: "Monaco"},
    {value:"MN", label: "Mongolia"},
    {value:"ME", label: "Montenegro"},
    {value:"MS", label: "Montserrat"},
    {value:"MA", label: "Morocco"},
    {value:"MZ", label: "Mozambique"},
    {value:"MM", label: "Myanmar"},
    {value:"NA", label: "Namibia"},
    {value:"NR", label: "Nauru"},
    {value:"NP", label: "Nepal"},
    {value:"NL", label: "Netherlands"},
    {value:"NC", label: "New Caledonia"},
    {value:"NZ", label: "New Zealand"},
    {value:"NI", label: "Nicaragua"},
    {value:"NE", label: "Niger"},
    {value:"NG", label: "Nigeria"},
    {value:"NU", label: "Niue"},
    {value:"NF", label: "Norfolk Island"},
    {value:"MP", label: "Northern Mariana Islands"},
    {value:"NO", label: "Norway"},
    {value:"OM", label: "Oman"},
    {value:"PK", label: "Pakistan"},
    {value:"PW", label: "Palau"},
    {value:"PS", label: "Palestinian Territory, Occupied"},
    {value:"PA", label: "Panama"},
    {value:"PG", label: "Papua New Guinea"},
    {value:"PY", label: "Paraguay"},
    {value:"PE", label: "Peru"},
    {value:"PH", label: "Philippines"},
    {value:"PN", label: "Pitcairn"},
    {value:"PL", label: "Poland"},
    {value:"PT", label: "Portugal"},
    {value:"PR", label: "Puerto Rico"},
    {value:"QA", label: "Qatar"},
    {value:"RE", label: "Réunion"},
    {value:"RO", label: "Romania"},
    {value:"RU", label: "Russian Federation"},
    {value:"RW", label: "Rwanda"},
    {value:"BL", label: "Saint Barthélemy"},
    {value:"SH", label: "Saint Helena, Ascension and Tristan da Cunha"},
    {value:"KN", label: "Saint Kitts and Nevis"},
    {value:"LC", label: "Saint Lucia"},
    {value:"MF", label: "Saint Martin (French part)"},
    {value:"PM", label: "Saint Pierre and Miquelon"},
    {value:"VC", label: "Saint Vincent and the Grenadines"},
    {value:"WS", label: "Samoa"},
    {value:"SM", label: "San Marino"},
    {value:"ST", label: "Sao Tome and Principe"},
    {value:"SA", label: "Saudi Arabia"},
    {value:"SN", label: "Senegal"},
    {value:"RS", label: "Serbia"},
    {value:"SC", label: "Seychelles"},
    {value:"SL", label: "Sierra Leone"},
    {value:"SG", label: "Singapore"},
    {value:"SX", label: "Sint Maarten (Dutch part)"},
    {value:"SK", label: "Slovakia"},
    {value:"SI", label: "Slovenia"},
    {value:"SB", label: "Solomon Islands"},
    {value:"SO", label: "Somalia"},
    {value:"ZA", label: "South Africa"},
    {value:"GS", label: "South Georgia and the South Sandwich Islands"},
    {value:"SS", label: "South Sudan"},
    {value:"ES", label: "Spain"},
    {value:"LK", label: "Sri Lanka"},
    {value:"SD", label: "Sudan"},
    {value:"SR", label: "Suriname"},
    {value:"SJ", label: "Svalbard and Jan Mayen"},
    {value:"SZ", label: "Swaziland"},
    {value:"SE", label: "Sweden"},
    {value:"CH", label: "Switzerland"},
    {value:"SY", label: "Syrian Arab Republic"},
    {value:"TW", label: "Taiwan, Province of China"},
    {value:"TJ", label: "Tajikistan"},
    {value:"TZ", label: "Tanzania, United Republic of"},
    {value:"TH", label: "Thailand"},
    {value:"TL", label: "Timor-Leste"},
    {value:"TG", label: "Togo"},
    {value:"TK", label: "Tokelau"},
    {value:"TO", label: "Tonga"},
    {value:"TT", label: "Trinidad and Tobago"},
    {value:"TN", label: "Tunisia"},
    {value:"TR", label: "Turkey"},
    {value:"TM", label: "Turkmenistan"},
    {value:"TC", label: "Turks and Caicos Islands"},
    {value:"TV", label: "Tuvalu"},
    {value:"UG", label: "Uganda"},
    {value:"UA", label: "Ukraine"},
    {value:"AE", label: "United Arab Emirates"},
    {value:"GB", label: "United Kingdom"},
    {value:"US", label: "United States"},
    {value:"UM", label: "United States Minor Outlying Islands"},
    {value:"UY", label: "Uruguay"},
    {value:"UZ", label: "Uzbekistan"},
    {value:"VU", label: "Vanuatu"},
    {value:"VE", label: "Venezuela, Bolivarian Republic of"},
    {value:"VN", label: "Viet Nam"},
    {value:"VG", label: "Virgin Islands, British"},
    {value:"VI", label: "Virgin Islands, U.S."},
    {value:"WF", label: "Wallis and Futuna"},
    {value:"EH", label: "Western Sahara"},
    {value:"YE", label: "Yemen"},
    {value:"ZM", label: "Zambia"},
    {value:"ZW", label: "Zimbabwe"},  
  ],
  iconOptions : [
    { value: 'Ambulance_Right_Black.png', label: 'Ambulance-Black' }, 
    { value: 'BackhoeLoader_Right_Black.png', label: 'BackhoeLoader-Black' }, 
    { value: 'BoxTrailer_Right_Black.png', label: 'BoxTrailer-Black' }, 
    { value: 'Bulldozer_Right_Black.png', label: 'Bulldozer-Black' }, 
    { value: 'Cabriolet_Right_Black.png', label: 'Cabriolet-Black' }, 
    { value: 'Cabriolet_Right_Blue.png', label: 'Cabriolet-Blue' }, 
    { value: 'Cabriolet_Right_Green.png', label: 'Cabriolet-Green' }, 
    { value: 'Cabriolet_Right_Grey.png', label: 'Cabriolet-Grey' }, 
    { value: 'Cabriolet_Right_Red.png', label: 'Cabriolet-Red' }, 
    { value: 'Cabriolet_Right_Yellow.png', label: 'Cabriolet-Yellow' }, 
    { value: 'CamperVan_Right_Black.png', label: 'CamperVan-Black' }, 
    { value: 'CamperVan_Right_Blue.png', label: 'CamperVan-Blue' }, 
    { value: 'CamperVan_Right_Green.png', label: 'CamperVan-Green' }, 
    { value: 'CamperVan_Right_Grey.png', label: 'CamperVan-Grey' }, 
    { value: 'CamperVan_Right_Red.png', label: 'CamperVan-Red' }, 
    { value: 'CamperVan_Right_Yellow.png', label: 'CamperVan-Yellow' }, 
    { value: 'Car_Right_Black.png', label: 'Car-Black' }, 
    { value: 'Car_Right_Blue.png', label: 'Car-Blue' }, 
    { value: 'Car_Right_Green.png', label: 'Car-Green' }, 
    { value: 'Car_Right_Grey.png', label: 'Car-Grey' }, 
    { value: 'Car_Right_Red.png', label: 'Car-Red' }, 
    { value: 'Car_Right_Yellow.png', label: 'Car-Yellow' }, 
    { value: 'Caravan_Right_Black.png', label: 'Caravan-Black' }, 
    { value: 'Coach_Right_Black.png', label: 'Coach-Black' }, 
    { value: 'Coach_Right_Blue.png', label: 'Coach-Blue' }, 
    { value: 'Coach_Right_Green.png', label: 'Coach-Green' }, 
    { value: 'Coach_Right_Grey.png', label: 'Coach-Grey' }, 
    { value: 'Coach_Right_Red.png', label: 'Coach-Red' }, 
    { value: 'Coach_Right_Yellow.png', label: 'Coach-Yellow' }, 
    { value: 'ConcretePump_Right_Black.png', label: 'ConcretePump-Black' }, 
    { value: 'ConcretePump_Right_Blue.png', label: 'ConcretePump-Blue' }, 
    { value: 'ConcretePump_Right_Green.png', label: 'ConcretePump-Green' }, 
    { value: 'ConcretePump_Right_Grey.png', label: 'ConcretePump-Grey' }, 
    { value: 'ConcretePump_Right_Red.png', label: 'ConcretePump-Red' }, 
    { value: 'ConcretePump_Right_Yellow.png', label: 'ConcretePump-Yellow' }, 
    { value: 'ContainerizedGeneratorTrailer_Right_Black.png', label: 'ContainerizedGeneratorTrailer-Black' }, 
    { value: 'Excavator_Right_Black.png', label: 'Excavator-Black' }, 
    { value: 'ExecutiveCar_Right_Black.png', label: 'ExecutiveCar-Black' }, 
    { value: 'ExecutiveCar_Right_Blue.png', label: 'ExecutiveCar-Blue' }, 
    { value: 'ExecutiveCar_Right_Green.png', label: 'ExecutiveCar-Green' }, 
    { value: 'ExecutiveCar_Right_Grey.png', label: 'ExecutiveCar-Grey' }, 
    { value: 'ExecutiveCar_Right_Red.png', label: 'ExecutiveCar-Red' }, 
    { value: 'ExecutiveCar_Right_Yellow.png', label: 'ExecutiveCar-Yellow' }, 
    { value: 'FarmTractor_Right_Black.png', label: 'FarmTractor-Black' }, 
    { value: 'FlatbedTrailer_Bulkhead_Right_Black.png', label: 'FlatbedTrailer_Bulkhead-Black' }, 
    { value: 'ForkliftTruck_Right_Black.png', label: 'ForkliftTruck-Black' }, 
    { value: 'FuelTankTruck_Right_Black.png', label: 'FuelTankTruck-Black' }, 
    { value: 'GullyEmptier_Right_Black.png', label: 'GullyEmptier-Black' }, 
    { value: 'Hatchback_Right_Black.png', label: 'Hatchback-Black' }, 
    { value: 'Hatchback_Right_Blue.png', label: 'Hatchback-Blue' }, 
    { value: 'Hatchback_Right_Green.png', label: 'Hatchback-Green' }, 
    { value: 'Hatchback_Right_Grey.png', label: 'Hatchback-Grey' }, 
    { value: 'Hatchback_Right_Red.png', label: 'Hatchback-Red' }, 
    { value: 'Hatchback_Right_Yellow.png', label: 'Hatchback-Yellow' }, 
    { value: 'LuxuryCar_Right_Black.png', label: 'LuxuryCar-Black' }, 
    { value: 'LuxuryCar_Right_Blue.png', label: 'LuxuryCar-Blue' }, 
    { value: 'LuxuryCar_Right_Green.png', label: 'LuxuryCar-Green' }, 
    { value: 'LuxuryCar_Right_Grey.png', label: 'LuxuryCar-Grey' }, 
    { value: 'LuxuryCar_Right_Red.png', label: 'LuxuryCar-Red' }, 
    { value: 'LuxuryCar_Right_Yellow.png', label: 'LuxuryCar-Yellow' }, 
    { value: 'MixerTruck_Right_Black.png', label: 'MixerTruck-Black' }, 
    { value: 'MixerTruck_Right_Blue.png', label: 'MixerTruck-Blue' }, 
    { value: 'MixerTruck_Right_Green.png', label: 'MixerTruck-Green' }, 
    { value: 'MixerTruck_Right_Grey.png', label: 'MixerTruck-Grey' }, 
    { value: 'MixerTruck_Right_Red.png', label: 'MixerTruck-Red' }, 
    { value: 'MixerTruck_Right_Yellow.png', label: 'MixerTruck-Yellow' }, 
    { value: 'Motorcycle_Right_Black.png', label: 'Motorcycle-Black' }, 
    { value: 'Motorcycle_Right_Blue.png', label: 'Motorcycle-Blue' }, 
    { value: 'Motorcycle_Right_Green.png', label: 'Motorcycle-Green' }, 
    { value: 'Motorcycle_Right_Grey.png', label: 'Motorcycle-Grey' }, 
    { value: 'Motorcycle_Right_Red.png', label: 'Motorcycle-Red' }, 
    { value: 'Motorcycle_Right_Yellow.png', label: 'Motorcycle-Yellow' }, 
    { value: 'RecoveryTruck_Right_Black.png', label: 'RecoveryTruck-Black' }, 
    { value: 'ReeferTrailer_Right_Black.png', label: 'ReeferTrailer-Black' }, 
    { value: 'RoadsideAssistanceCar_Right_Black.png', label: 'RoadsideAssistanceCar-Black' }, 
    { value: 'RoadsideAssistanceCar_Right_Blue.png', label: 'RoadsideAssistanceCar-Blue' }, 
    { value: 'RoadsideAssistanceCar_Right_Green.png', label: 'RoadsideAssistanceCar-Green' }, 
    { value: 'RoadsideAssistanceCar_Right_Grey.png', label: 'RoadsideAssistanceCar-Grey' }, 
    { value: 'RoadsideAssistanceCar_Right_Red.png', label: 'RoadsideAssistanceCar-Red' }, 
    { value: 'RoadsideAssistanceCar_Right_Yellow.png', label: 'RoadsideAssistanceCar-Yellow' }, 
    { value: 'ServiceVan_Right_Black.png', label: 'ServiceVan-Black' }, 
    { value: 'ServiceVan_Right_Blue.png', label: 'ServiceVan-Blue' }, 
    { value: 'ServiceVan_Right_Green.png', label: 'ServiceVan-Green' }, 
    { value: 'ServiceVan_Right_Grey.png', label: 'ServiceVan-Grey' }, 
    { value: 'ServiceVan_Right_Red.png', label: 'ServiceVan-Red' }, 
    { value: 'ServiceVan_Right_Yellow.png', label: 'ServiceVan-Yellow' }, 
    { value: 'SewerCleaningTruck_Right_Black.png', label: 'SewerCleaningTruck-Black' }, 
    { value: 'SewerCleaningTruck_Right_Blue.png', label: 'SewerCleaningTruck-Blue' }, 
    { value: 'SewerCleaningTruck_Right_Green.png', label: 'SewerCleaningTruck-Green' }, 
    { value: 'SewerCleaningTruck_Right_Grey.png', label: 'SewerCleaningTruck-Grey' }, 
    { value: 'SewerCleaningTruck_Right_Red.png', label: 'SewerCleaningTruck-Red' }, 
    { value: 'SewerCleaningTruck_Right_Yellow.png', label: 'SewerCleaningTruck-Yellow' }, 
    { value: 'SteamRoller_Right_Black.png', label: 'SteamRoller-Black' }, 
    { value: 'TankerTrailer_Right_Black.png', label: 'TankerTrailer-Black' }, 
    { value: 'Taxi_Right_Black.png', label: 'Taxi-Black' }, 
    { value: 'Taxi_Right_Blue.png', label: 'Taxi-Blue' }, 
    { value: 'Taxi_Right_Green.png', label: 'Taxi-Green' }, 
    { value: 'Taxi_Right_Grey.png', label: 'Taxi-Grey' }, 
    { value: 'Taxi_Right_Red.png', label: 'Taxi-Red' }, 
    { value: 'Taxi_Right_Yellow.png', label: 'Taxi-Yellow' }, 
    { value: 'TowTruck_Right_Black.png', label: 'TowTruck-Black' }, 
    { value: 'TowTruck_Right_Blue.png', label: 'TowTruck-Blue' }, 
    { value: 'TowTruck_Right_Green.png', label: 'TowTruck-Green' }, 
    { value: 'TowTruck_Right_Grey.png', label: 'TowTruck-Grey' }, 
    { value: 'TowTruck_Right_Red.png', label: 'TowTruck-Red' }, 
    { value: 'TowTruck_Right_Yellow.png', label: 'TowTruck-Yellow' }, 
    { value: 'TractorFlatbedTrailer_Right_Black.png', label: 'TractorFlatbedTrailer-Black' }, 
    { value: 'TractorFlatbedTrailer_Right_Blue.png', label: 'TractorFlatbedTrailer-Blue' }, 
    { value: 'TractorFlatbedTrailer_Right_Green.png', label: 'TractorFlatbedTrailer-Green' }, 
    { value: 'TractorFlatbedTrailer_Right_Grey.png', label: 'TractorFlatbedTrailer-Grey' }, 
    { value: 'TractorFlatbedTrailer_Right_Red.png', label: 'TractorFlatbedTrailer-Red' }, 
    { value: 'TractorFlatbedTrailer_Right_Yellow.png', label: 'TractorFlatbedTrailer-Yellow' }, 
    { value: 'TractorTrailer_Right_Black.png', label: 'TractorTrailer-Black' }, 
    { value: 'TractorTrailer_Right_Blue.png', label: 'TractorTrailer-Blue' }, 
    { value: 'TractorTrailer_Right_Green.png', label: 'TractorTrailer-Green' }, 
    { value: 'TractorTrailer_Right_Grey.png', label: 'TractorTrailer-Grey' }, 
    { value: 'TractorTrailer_Right_Red.png', label: 'TractorTrailer-Red' }, 
    { value: 'TractorTrailer_Right_Yellow.png', label: 'TractorTrailer-Yellow' }, 
    { value: 'TractorUnit_Right_Black.png', label: 'TractorUnit-Black' }, 
    { value: 'TractorUnit_Right_Blue.png', label: 'TractorUnit-Blue' }, 
    { value: 'TractorUnit_Right_Green.png', label: 'TractorUnit-Green' }, 
    { value: 'TractorUnit_Right_Grey.png', label: 'TractorUnit-Grey' }, 
    { value: 'TractorUnit_Right_Red.png', label: 'TractorUnit-Red' }, 
    { value: 'TractorUnit_Right_Yellow.png', label: 'TractorUnit-Yellow' }, 
    { value: 'Truck_Right_Black.png', label: 'Truck-Black' }, 
    { value: 'Truck_Right_Blue.png', label: 'Truck-Blue' }, 
    { value: 'Truck_Right_Green.png', label: 'Truck-Green' }, 
    { value: 'Truck_Right_Grey.png', label: 'Truck-Grey' }, 
    { value: 'Truck_Right_Red.png', label: 'Truck-Red' }, 
    { value: 'Truck_Right_Yellow.png', label: 'Truck-Yellow' }, 
    { value: 'UltraSilentGeneratorTrailer_Right_Black.png', label: 'UltraSilentGeneratorTrailer-Black' }, 
    { value: 'WasteTankerTrailer_Right_Black.png', label: 'WasteTankerTrailer-Black' }, 
    { value: 'WaterTankTruck_Right_Black.png', label: 'WaterTankTruck-Black' }, 
    { value: 'WaterTankTruck_Right_Blue.png', label: 'WaterTankTruck-Blue' }, 
    { value: 'WaterTankTruck_Right_Green.png', label: 'WaterTankTruck-Green' }, 
    { value: 'WaterTankTruck_Right_Grey.png', label: 'WaterTankTruck-Grey' }, 
    { value: 'WaterTankTruck_Right_Red.png', label: 'WaterTankTruck-Red' }, 
    { value: 'WaterTankTruck_Right_Yellow.png', label: 'WaterTankTruck-Yellow' }, 
  ], 
  headerOptions : [
    //
    //Removed headers that are not relevant yet.  Will add back in as features are added...
    //
    { label: 'Address', value: 'Address' },
    /* { label: 'Assigned Groups', value: 'Assigned Groups' }, */
    { label: 'Unit Battery %', value: 'Unit Battery %' }, 
    /* { label: 'Battery', value: 'Battery' }, */
    { label: 'Mileage', value: 'Mileage' },
    { label: 'Event', value: 'Event' },
    { label: 'Event Received', value: 'Event Received' },
    /* { label: 'Expiration Date', value: 'Expiration Date' }, */
    { label: 'Icons', value: 'Icons' },
    { label: 'License Plate', value: 'License Plate' },
    /* { label: 'Lot', value: 'Lot' }, */
    { label: 'Move Status', value: 'Move Status' },
    { label: 'Notes', value: 'Notes' },
    { label: 'Product Type', value: 'Product Type' },
    { label: 'Serial Number', value: 'Serial Number' },
    { label: 'Speed of Last Event', value: 'Speed of Last Event' },
    { label: 'Time of Event', value: 'Time of Event' },
    { label: 'Time Since Last Event', value: 'Time Since Last Event'},
    /* { label: 'Vehicle Battery %', value: 'Vehicle Battery %' }, */
    { label: 'Vehicle', value: 'Vehicle' },
    /* { label: 'Vin #', value: 'Vin #' }, */
  ],
  tableConfig: {
    "Address": { columnKey: "address", field: "address", header: "Address" },
    "Assigned Groups": { columnKey: "assignedGroups", field: "assignedGroups", header: "Assigned Groups" },
    "Unit Battery %": { columnKey: "unitBattery", field: "unitBattery", header: "Unit Battery %" },
    "Battery": { columnKey: "batteryLevel", field: "batteryLevel", header: "Battery" },
    "Mileage": { columnKey: "mileage", field: "mileage", header: "Mileage" },
    "Event": { columnKey: "lastEvent", field: "lastEvent", header: "Event" },
    "Event Received": { columnKey: "timeReceived", field: "timeReceived", header: "Event Received" },
    "Expiration Date": { columnKey: "expirationDate", field: "expirationDate", header: "Expiration Date" },
    "Icons": { columnKey: "icons", field: "icons", header: "Icons" },
    "License Plate": { columnKey: "licensePlate", field: "licensePlate", header: "License Plate" },
    "Move Status": { columnKey: "moveStatus", field: "moveStatus", header: 'Move Status'},
    "Notes": { columnKey: "notes", field: "notes", header: "Notes" },
    "Product Type": { columnKey: "productType", field: "productType", header: "Product Type" },
    "Serial Number": { columnKey: "serial", field: "serial", header: "Serial Number", style: {width: '200px'} },
    "Speed of Last Event": { columnKey: "speedOfLastEvent", field: "speedOfLastEvent", header: "Speed of Last Event" },
    "Time of Event": { columnKey: "lastEventTime", field: "lastEventTime", header: "Time of Event" },
    "Time Since Last Event": { columnKey: 'timeSinceLastEvent', field: "timeSinceLastEvent", header: "Time Since Last Event"},
    "Vehicle": { columnKey: "vehicle", field: "vehicle", header: "Vehicle" },
    "Vehicle Battery %": { columnKey: "vehicleBattery", field: "vehicleBattery", header: "Vehicle Battery %" },
    "Vin #": { columnKey: "vin", field: "vin", header: "Vin #" },
    'Lot': { columnKey: "lot", field: "lot", header: "Lot" },
  },
   filterOptions:[
    //
    //Removed filters that are not relevant yet.  Will add back in as features are added...
    //
    /* { label: 'Low battery', value: 'Low battery' },
    { label: 'Lots', value: 'Lots' }, */
    { label: 'Not Reported (30)', value: 'Not Reported (30)' },
    { label: 'Not Reported (60)', value: 'Not Reported (60)' },
    { label: 'Not Reported (90)', value: 'Not Reported (90)' }
  ]
  
}

export default Helpers;