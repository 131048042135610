import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

class QuickLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'Logging...',
    };
  }

  async componentDidMount() {
    if (this.props.match.params.token && this.props.match.params.token.length > 0) {
      const mutation = gql`
        mutation quickLogin($token: String!) {
          quickLogin (token: $token)
        }
      `;
      const variables = {
        token: this.props.match.params.token
      };

      localStorage.setItem('GPSLeadersTrackingAuthToken', null);

      console.log('quickLogin vars:', variables);

      this.props.client.mutate({
        mutation,
        variables
      }).then((res) => {
        console.log('SUCCESS:', res);
        localStorage.setItem('GPSLeadersTrackingAuthToken', res.data.quickLogin)
        this.props.onLogin();
      }).catch((err) => {
        console.error('ERROR:', err);
        this.setState({
          message: 'Error logging in',
        });
      });
    }
  }

  componentWillReceiveProps(props) {}

  render() {
    return <span>
      {this.state.message}
    </span>;
  }
}

export default compose()(withApollo(QuickLogin));