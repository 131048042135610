import React, { Component } from 'react';
import moment from 'moment';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { withRouter } from 'react-router-dom';

import { Button } from 'primereact/components/button/Button';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { Card } from 'primereact/components/card/Card';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';
import L from 'leaflet';

import Helpers from '../../libs/Helpers';
const DEVICE_TIME_FORMAT = 'UTC:YYYY/MM/DD HH:mm:ss.SSS';

class VehicleMap extends Component {

  constructor(props) {
    super(props);

    this.state = {
      //mapId: 'mapbox.streets',
      enableTrackingModeButtonDisabled: false,
      enableTrackingModeButtonText: 'Enable Tracking Mode',
      disableTrackingModeButtonDisabled: false,
      disableTrackingModeButtonText: 'Disable Tracking Mode',
      startDate: '',
      endDate: '',
      showQuickHistoryDialog: false,
      unit: [],
    }

    this.closeQuickHistoryDialog = this.closeQuickHistoryDialog.bind(this);
  }

  trackingMode(unit) {
    if (this.props.noTracking) {
      return ''
    } else {
      return unit.info.trackingmode === false
        ? <Button label={this.state.enableTrackingModeButtonText} className="ui-button-danger" disabled={this.state.enableTrackingModeButtonDisabled} onClick={() => { this.props.enableTrackingMode(unit.info.unit_id); } } />
        : <Button label={this.state.disableTrackingModeButtonText} className="ui-button-disabled" disabled={this.state.disableTrackingModeButtonDisabled} onClick={() => { this.props.disableTrackingMode(unit.info.unit_id); } } />;
    }
  }

  componentDidMount() {
    this.props.didMount(this.refs.map);
  }

  componentWillReceiveProps(props) {
    if (this.props != props) {
      if ((((this.refs || {}).map || {}).leafletElement || {}).invalidateSize) {
        //console.log('Recalculate map size');
        setTimeout(() => {
          this.refs.map.leafletElement.invalidateSize();
        }, 100);
      }
    }
  }

  /*
  componentDidUpdate() {
    if (this.activeMarkerRef) {
      console.log('activeMarkerRef:', this.activeMarkerRef);
      this.activeMarkerRef.openPopup();
    } else {
      console.log('No activeMarkerRef');
    }
  }
  */

  openPopup (marker) {
    //console.log('openPopup:', marker);

    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup()
      });
    }
  }

  onStartDateChange(e) {
    this.setState({ startDate: e.value });
  }

  onEndDateChange(e) {
    this.setState({ endDate: e.value });
  }

  openQuickHistoryDialog(unit) {
    this.setState({
      showQuickHistoryDialog: true,
      unit,
    })
  }

  closeQuickHistoryDialog() {
    this.setState({
      showQuickHistoryDialog: false,
      unit: [],
      startDate: '',
      endDate: '',
    })
  }

  renderQuickHistoryDialog() {
    return (
      <Dialog
        header={ 'Select Dates' }
        visible={this.state.showQuickHistoryDialog}
        onHide={this.closeQuickHistoryDialog}
        modal={true}
        appendTo={document.getElementById('app-footer')}
        closable={false}
        contentStyle={{overflow: 'unset'}}
      >
        <div>
          <Calendar
            dateFormat="mm/dd/y"
            placeholder="Start Date"
            value={this.state.startDate}
            onChange={this.onStartDateChange.bind(this)}
            style={{ width: '100%', zIndex: 1000, }}
            inputStyle={{ textAlign: 'center', width: '100%', paddding: '10px' }}
            hideOnDateTimeSelect={true}
          />
        </div>
        <div style={{ paddingLeft: '50%', paddingRight: '50%'}}>To</div>
        <div>
          <Calendar
            dateFormat="mm/dd/y"
            placeholder="End Date"
            value={this.state.endDate}
            onChange={this.onEndDateChange.bind(this)}
            style={{ width: '100%', zIndex: 999, }}
            inputStyle={{ textAlign: 'center', width: '100%', paddding: '10px' }}
            hideOnDateTimeSelect={true}
          />
        </div>
        <div>
          <Button 
            label="Run" 
            className="ui-button-success" 
            onClick={() => this.runQuickHistoryReport()}
            style={{float: 'right', margin: "10px"}}
          />
          <Button 
            label="Cancel" 
            className="ui-button-danger" 
            onClick={() => this.closeQuickHistoryDialog()}
            style={{float: 'right', margin: "10px"}}
          />
        </div>
      </Dialog>
    )
  }

  runQuickHistoryReport() {
    if (this.state.startDate && this.state.endDate && this.state.startDate <= this.state.endDate) {    
      const userTimezone = this.props.user && this.props.user.user && this.props.user.user.timezone && this.props.user.user.timezone.length > 0
      ? this.props.user.user.timezone
      : 'America/Los_Angeles';

      const startDate = moment.tz(this.state.startDate, userTimezone).startOf('day');
      const endDate = moment.tz(this.state.endDate, userTimezone).endOf('day');

      let report = [{
        reportName: 'History Report',
        reportTitle: 'History Report: ' + moment(startDate).format('ddd. MMM. Do YYYY') + ' to ' + moment(endDate).format('ddd. MMM. Do YYYY'),
        reportType: 'history',
        units: [{
          label: this.state.unit.info.label,
          serial: this.state.unit.info.serial,
          unit_id: this.state.unit.info.unit_id,
          value: this.state.unit.info.unit_id 
        }],
        selectedUnits: [],
        startDate: startDate,
        endDate: endDate,
        reportData: [],
        ranReport: false,
        firstLoad: true,
        csvData: [],
        configureModalMap: {
          coords: "",
          display: false
        },
        icons: this.state.icons,
        mapId: 'mapbox.streets',
        vehicleSuggestions: [],
        step: 3,
        show: true
      }];
      localStorage.setItem('gpsleadertracking-reports', JSON.stringify(report));    
      this.props.history.push('/reports');
    }
    else {
      alert('Please select a start and end date and ensure that the start date is not after the end date.');
    } 
  }
  
  render() {  
    //console.log('Selected unit id:', this.props.selectedUnitId);
    //console.log('Bounds:', this.state.bounds);
    return (
      <Map
        {...this.props}
        ref={'map'}
        dragging={ !L.Browser.mobile }
        onViewportChanged={() => {
          if (this.refs.map && this.refs.map.leafletElement) {
            //this.props.onMapChanged(this.refs.map.leafletElement.getBounds());
          }
        }}
      >
        <TileLayer
          attribution=""
          url={'https://api.tiles.mapbox.com/v4/' + this.props.mapId + '/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoiZ3BzbGVhZGVycyIsImEiOiJjamw4aXhmNXIwMHVmM3BydW04cmJ5cXE0In0.mLNavvJJLhaP6DANzEyatw'}
        />        
        {
          this.props.vehicles
          ? this.props.vehicles.map((group) => {
            return group.map((unit,index) => {
              if (unit && unit.position) {
                
                let customIcon = this.defineIcon(unit);
                const eventTime = moment.unix(unit.info.lastEvent.event_data.device_time).format('MMM DD, YYYY') + ' @ ' + moment.unix(unit.info.lastEvent.event_data.device_time).format('h:mm A');

                return (
                <Marker icon={customIcon} position={unit.position} key={index} ref={this.props.selectedUnitId == unit.info.unit_id ? this.openPopup : null}>
                  <Popup 
                    className="map-marker-popup" 
                    /* onOpen={() => { this.props.onPopupOpen(unit.unit_id) }} */ 
                    onClose={() => { this.props.onClosePopup() }}
                  >
                    <Card 
                      className="map-card"
                      style={{ border: 0 }}                      
                      header={<div className="card-alert-header"><b>{unit.info.label}</b></div>}
                    >
                      <div style={{
                        padding: 0,
                        cellPadding: '5px',
                        fontSize: '12px',
                        color: '#555555',
                        paddingTop: '4px',
                      }}>
                        <div style={{display: 'flex'}}>
                          <div style={{padding: '2px'}}><i className="material-icons" id="info">info</i><Tooltip for="#info" title="Event Information" tooltipPosition="top" /></div>
                          <div style={{padding: '2px'}} id="eventInfo">                            
                            <Tooltip for="#eventInfo" title={eventTime} tooltipPosition="top" />
                            {
                              unit.info.lastEvent.event_data.conduit_event_description && unit.info.lastEvent.event_data.conduit_color_code
                              ? <font color={'#' + unit.info.lastEvent.event_data.conduit_color_code}><b>{unit.info.lastEvent.event_data.conduit_event_description}</b></font>
                              : Helpers.cleanEventType(unit.info.lastEvent.device_type, unit.info.lastEvent.event_data.event_type)
                            } { unit.info.lastEvent && unit.info.lastEvent.event_data && unit.info.lastEvent.event_data.device_time ? this.props.timeSinceLastEvent(unit.info) + ' ago' : 'NO DATA' }
                          </div>
                        </div>
                        <div style={{display: 'flex'}}>
                          <div style={{padding: '2px'}}><i className="material-icons" id="move-status">flag</i><Tooltip for="#move-status" title="Move Status" tooltipPosition="top" /></div>
                          <div style={{padding: '2px'}}>
                            {
                              unit.info.lastmovetime && unit.info.lastmovetime > 0 ? this.props.buildUnitRow(unit.info, 'lastmovetime') : unit.info.laststoptime && unit.info.laststoptime > 0 ? this.props.buildUnitRow(unit.info, 'laststoptime') : 'NO DATA'
                            }
                          </div>
                        </div>
                        <div style={{display: 'flex'}}>
                          <div style={{padding: '2px'}}><i className="material-icons" id="address">home</i><Tooltip for="#address" title="Event Address" tooltipPosition="top" /></div>
                          <div style={{padding: '2px'}}>{unit.info.lastEvent.event_data.address ? unit.info.lastEvent.event_data.address : 'None'}</div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div style={{display: 'flex'}}>
                            <div style={{padding: '2px'}}><i className="material-icons" id="speed">av_timer</i><Tooltip for="#speed" title="Speed in MPH" tooltipPosition="top" /></div>
                            <div style={{padding: '2px', paddingRight: '4px'}}>{unit.info.lastEvent.event_data.speed ? Helpers.cleanSpeed(unit.info.lastEvent.device_type, unit.info.lastEvent.event_data.speed) : '--'}</div>
                            <div style={{padding: '2px'}}><i className="material-icons" id="heading">directions</i><Tooltip for="#heading" title="Heading" tooltipPosition="top" /></div>
                            <div style={{padding: '2px', paddingRight: '4px'}}>{unit.info.lastEvent.event_data.heading_degrees ? Helpers.degreesToDirection(unit.info.lastEvent.event_data.heading_degrees) : '--'}</div>
                            <div style={{padding: '2px'}}><i className="material-icons" id="satellites">settings_input_antenna</i><Tooltip for="#satellites" title="Satellite Count" tooltipPosition="top" /></div>
                            <div style={{padding: '2px'}}>{unit.info.lastEvent.event_data.satellite_count ? unit.info.lastEvent.event_data.satellite_count : '--'}</div>
                          </div>
                          <div style={{display: 'flex'}}>                            
                            <div style={{padding: '2px'}}>
                              <i
                                className="material-icons ui-button-success"
                                style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 18 }}
                                onClick={() => this.openQuickHistoryDialog(unit)}
                                id="quickHistoryReport"
                              >description</i>
                              <Tooltip for="#quickHistoryReport" title="Quick History Report" tooltipPosition="top" />
                            </div>
                            {
                              (!this.props.noGeofence) && 
                              <div style={{padding: '2px'}}>
                                <i
                                  className="material-icons ui-button-success"
                                  style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 18 }}
                                  onClick={() => this.props.history.push('/geofences/'+unit.info.lastEvent.event_data.latitude+','+unit.info.lastEvent.event_data.longitude+'/'+unit.info.label)}
                                  id="create_geofence"
                                >gps_fixed</i>
                                <Tooltip for="#create_geofence" title="Create Geofence Here" tooltipPosition="top" />
                              </div>
                            }
                            <div style={{padding: '2px'}}>
                              <i
                                className="material-icons ui-button-success"
                                style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 18 }}
                                onClick={() => window.open('https://www.google.com/maps/search/?api=1&query='+unit.info.lastEvent.event_data.latitude+','+unit.info.lastEvent.event_data.longitude, '_blank')}
                                id="map"
                              >map</i>
                              <Tooltip for="#map" title="Google Map View" tooltipPosition="top" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {this.renderQuickHistoryDialog()}
                      </div>
                    </Card>
                  </Popup>
                </Marker>
                )
              } else {
                return '';
              }
            })
          })
          : ''
        }        
      </Map>
    )
  }

  defineIcon(unit) {
    let customIcon = new L.Icon.Default();
    if (unit.info.vehicle && unit.info.vehicle.icon) {
      customIcon = new L.Icon({
        iconUrl: require(`../../libs/icons/${unit.info.vehicle.icon}`),
        iconRetinaUrl: require(`../../libs/icons/${unit.info.vehicle.icon}`),
        iconAnchor: [24, 24],
        popupAnchor: [0, -24],
      });
    }
    return customIcon;
  }
}

export default withRouter(VehicleMap);