import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

// Prime React Imports
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Password } from 'primereact/components/password/Password';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import sha1 from 'sha1';

import TimezonePicker from 'react-timezone';

const USER_QUERY = gql`
  query {
    user {
      user_id
      fullname
      firstname
      lastname
      email
      timezone
      account {
        title
      }
    }
  }
`;

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      title: '',
      email: '',
      timezone: 'America/Los_Angeles',
      initialLoad: true,
      password: '',
      confirm: '',
    };

    this.saveProfileInfo = this.saveProfileInfo.bind(this);
  }

  componentDidMount() {
    if (this.props.user && this.props.user.user) {
      this.mapUserInfo(this.props);
    }
  }

  componentWillReceiveProps(props) {
    if (props.user && props.user.user && this.state.initialLoad) {
      this.mapUserInfo(props);
    }
  }

  mapUserInfo(props) {
    this.setState({
      firstname: props.user.user.firstname,
      lastname: props.user.user.lastname,
      title: props.user.user.account.title,
      email: props.user.user.email,
      timezone: props.user.user.timezone,
      initialLoad: false,
      infoChanged: false,
    });
  }

  saveProfileInfo() {
    this.setState({
      initialLoad: true
    });
    let mutation = gql`
    mutation updateUserInfo($firstname: String!, $lastname: String!, $companyTitle: String!, $email: String!, $timezone: String!) {
      updateUserInfo(firstname: $firstname, lastname: $lastname, companyTitle: $companyTitle, email: $email, timezone: $timezone)
    }`;    
    let variables = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      companyTitle: this.state.title,
      email: this.state.email,
      timezone: this.state.timezone,
    };

    if (this.state.password.length > 0) {
      mutation = gql`
      mutation updateUserInfo($firstname: String!, $lastname: String!, $companyTitle: String!, $email: String!, $timezone: String!, $password: String!) {
        updateUserInfo(firstname: $firstname, lastname: $lastname, companyTitle: $companyTitle, email: $email, timezone: $timezone, password: $password)
      }`;
      variables = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        companyTitle: this.state.title,
        email: this.state.email,
        timezone: this.state.timezone,
        password: sha1(this.state.password),
      };
    }

    console.log('VARS:', variables);
    this.props.client.mutate({ mutation, variables })
    .then( (res) => {
      console.log('Save user info:', res);
      this.props.user.refetch();
      this.setState({
        initialLoad: false,
        password: '',
      });
    } );
  }

  render() {
    return (
    <div className="ui-g" style={{ padding: 0 }}>
      <div className="ui-g-12" style={{ padding: 0 }}>
        <div className="card clearfix" style={{ fontSize: 18, padding: '15px' }}><b>Profile</b></div>
        <div className="card clearfix ui-g-12" style={{fontSize: 14, padding: '10px'}}>
        {
          this.state.initialLoad
          ? <ProgressSpinner />
          : <React.Fragment>
            <div>First Name</div>
          <div style={{marginBottom: '15px'}}>
            <InputText 
              label="firstname" 
              placeholder="Firstname" 
              style={{fontSize: 18, width: '250px'}}
              value={this.state.firstname}
              onChange={e => this.setState({firstname: e.target.value, infoChanged: true})}
            />
          </div>
          <div>Last Name</div>
          <div style={{marginBottom: '15px'}}>
            <InputText 
              label="lastname" 
              placeholder="Lastname" 
              style={{fontSize: 18, width: '250px'}}
              value={this.state.lastname}
              onChange={e => this.setState({lastname: e.target.value, infoChanged: true})}
            />
          </div>
          <div style={{marginBottom: '15px'}}>
            <InputText 
              label="title" 
              placeholder="Company Name" 
              style={{fontSize: 18, width: '250px'}}
              value={this.state.title}
              onChange={e => this.setState({title: e.target.value, infoChanged: true})}
            />
          </div>
          <div>Email</div>
          <div style={{marginBottom: '15px'}}>
            <InputText
              label="email" 
              placeholder="Email" 
              style={{fontSize: 18, width: '250px'}}
              value={this.state.email}
              onChange={e => this.setState({email: e.target.value, infoChanged: true})}
            />
          </div>
          <div>Timezone</div>
          <div style={{marginBottom: '15px'}}>
            <TimezonePicker
              style={{width: '250px', zIndex: 9999, position: 'relative'}}
              value={this.state.timezone}
              onChange={timezone => this.setState({timezone, infoChanged: true})}
              inputProps={{
                placeholder: 'Select Timezone...',
                name: 'timezone',
              }}
            />
          </div>
          <div>New Password</div>
          <div style={{marginBottom: '15px'}}>
            <Password
              label="password" 
              placeholder="New Password" 
              style={{fontSize: 18, width: '250px'}}
              value={this.state.password}
              onChange={e => this.setState({password: e.target.value, infoChanged: true})}
            />
          </div>          
          <div>
            <Button className="ui-button-success" label="Save" disabled={!this.state.infoChanged} onClick={this.saveProfileInfo} />
            <Button className="ui-button-danger" label="Cancel" onClick={() => {this.props.history.goBack()}} />
          </div>
          </React.Fragment>
        }   
        </div>
      </div>
    </div>
    );
  }
}

export default compose(
  graphql(USER_QUERY, {
    name: 'user'
  })
)(withApollo(Profile));