import React, { Component } from 'react';

import gql from 'graphql-tag';
import { graphql, compose, withApollo } from 'react-apollo';

import Contact from './Contact';
import GeofenceMapSmall from './GeofenceMapSmall';

import { Button } from 'primereact/components/button/Button';
import { ListBox } from 'primereact/components/listbox/ListBox';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';

class GeofenceAlert extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showConfirmDelete: false,
      geofenceAlertChanged: false,
      editUnits: false,
      editContacts: false,
      geofenceAlert: null,
      reloadGeofenceAlert: true
    }
  }

  componentWillReceiveProps(props) {
    console.log('GA Incoming Props:', props);
    if (props.geofenceAlert && this.state.reloadGeofenceAlert) {
      this.mapGeofenceAlert(props.geofenceAlert);
    }
  }

  componentDidMount() {
    console.log('GA componentDidMount:', this.props.gefenceAlert);
    if (this.props.geofenceAlert && this.state.reloadGeofenceAlert) {
      this.mapGeofenceAlert(this.props.geofenceAlert);
    }
  }

  mapGeofenceAlert(geofenceAlert) {
    this.setState({
      geofenceAlert,
      reloadGeofenceAlert: false
    });
  }

  toggleConfirmDelete() {
    this.setState({
      showConfirmDelete: !this.state.showConfirmDelete
    });
  }

  deleteGeofenceAlert() {
    const mutation = gql`
    mutation deleteBoundaryAlert($boundaryalert_id: Int!) {
      deleteBoundaryAlert(boundaryalert_id: $boundaryalert_id)
    }`;
    const variables = {
      boundaryalert_id: this.props.geofenceAlert.boundaryalert_id
    };

    this.props.client.mutate({ mutation, variables }).then((res) => {
      this.props.onDelete(res);
    });
  }

  saveGeofenceAlert() {
    this.updateGeofenceAlert();
    this.overwriteGeofenceAlertUnits();
    this.overwriteGeofenceAlertContacts();
  }

  updateGeofenceAlert() {
    const mutation = gql`
    mutation updateBoundaryAlert($boundaryalert_id: Int!, $in: Boolean!, $out: Boolean!) {
      updateBoundaryAlert(boundaryalert_id: $boundaryalert_id, in: $in, out: $out)
    }`;
    const variables = {
      boundaryalert_id: this.state.geofenceAlert.boundaryalert_id,
      in: this.state.geofenceAlert.in,
      out: this.state.geofenceAlert.out
    };
    this.props.client.mutate({mutation, variables}).then((res) => {
      console.log('Update Geofence Alert:', res);
    })
  }

  overwriteGeofenceAlertUnits() {
    // Clear out old units
    const mutation = gql`
    mutation clearBoundaryAlertUnits($boundaryalert_id: Int!) {
      clearBoundaryAlertUnits(boundaryalert_id: $boundaryalert_id)
    }`;
    const variables = {
      boundaryalert_id: this.state.geofenceAlert.boundaryalert_id
    };
    this.props.client.mutate({mutation, variables}).then((res) => {
      // Add all units
      const mutation = gql`
      mutation addBoundaryAlertUnits($boundaryalert_id: Int!, $unit_ids: [Int!]) {
        addBoundaryAlertUnits(boundaryalert_id: $boundaryalert_id, unit_ids: $unit_ids)
      }`;
      const variables = {
        boundaryalert_id: this.state.geofenceAlert.boundaryalert_id,
        unit_ids: this.state.geofenceAlert.units.map(unit => unit.unit_id)
      };
      this.props.client.mutate({mutation, variables}).then((res) => {
        console.log('Done saving units');
        this.setState({
          editUnits: false
        });
      });
    });
  }

  overwriteGeofenceAlertContacts() {
    // Clear out old contacts
    const mutation = gql`
    mutation clearBoundaryAlertContacts($boundaryalert_id: Int!) {
      clearBoundaryAlertContacts(boundaryalert_id: $boundaryalert_id)
    }`;
    const variables = {
      boundaryalert_id: this.state.geofenceAlert.boundaryalert_id
    };
    this.props.client.mutate({mutation, variables}).then((res) => {
      // Add all contacts
      let promises = [];

      this.state.geofenceAlert.alertContacts.forEach((ac) => {
        const mutation = gql`
        mutation addBoundaryAlertContact($boundaryalert_id: Int!, $contact_id: Int!) {
          addBoundaryAlertContact(boundaryalert_id: $boundaryalert_id, contact_id: $contact_id)
        }`;
        const variables = {
          boundaryalert_id: this.state.geofenceAlert.boundaryalert_id,
          contact_id: ac.contact.contact_id
        };
        promises.push(this.props.client.mutate({mutation, variables}));        
      });

      Promise.all(promises).then((res) => {
        this.setState({
          editContacts: false
        });
      });
    });
  }

  onContactSelect(contact) {
    let geofenceAlert = Object.assign({}, this.state.geofenceAlert);
    if (this.state.geofenceAlert.alertContacts.reduce((accum, ac) => (ac.contact.contact_id == contact.contact_id ? true : accum), false)) {
      // remove
      if (geofenceAlert.alertContacts.length <= 1) {
        window.alert('Alert requires at least 1 contact.')
      } else {
        geofenceAlert.alertContacts = geofenceAlert.alertContacts.filter(ac => ac.contact.contact_id == contact.contact_id ? false : true)
        this.setState({
          geofenceAlert
        });
      }      
    } else {
      // add
      geofenceAlert.alertContacts = geofenceAlert.alertContacts.concat({
        email: true,
        phone: true,
        contact
      });      
      this.setState({
        geofenceAlert
      });
    }
  }

  toggleEmail(contact) {
    let geofenceAlert = Object.assign({}, this.state.geofenceAlert);
    geofenceAlert.alertContacts = geofenceAlert.alertContacts.map((ac) => {
      if (ac.contact.contact_id == contact.contact_id) {
        return {
          ...ac,
          email: !ac.email
        };
      } else {
        return ac;
      }
    });
    this.setState({
      geofenceAlert
    });
  }

  togglePhone(contact) {
    let geofenceAlert = Object.assign({}, this.state.geofenceAlert);
    geofenceAlert.alertContacts = geofenceAlert.alertContacts.map((ac) => {
      if (ac.contact.contact_id == contact.contact_id) {
        return {
          ...ac,
          phone: !ac.phone
        };
      } else {
        return ac;
      }
    });
    this.setState({
      geofenceAlert
    });
  }

  toggleIn() {
    let geofenceAlert = Object.assign({}, this.state.geofenceAlert);
    geofenceAlert.in = !geofenceAlert.in;
    this.setState({
      geofenceAlert
    }, () => {
      this.updateGeofenceAlert();
    });    
  }

  toggleOut() {
    let geofenceAlert = Object.assign({}, this.state.geofenceAlert);
    geofenceAlert.out = !geofenceAlert.out;
    this.setState({
      geofenceAlert
    }, () => {
      this.updateGeofenceAlert();
    });
  }

  render() {
    const geofenceAlert = this.state.geofenceAlert;

    if (!geofenceAlert) {
      return <ProgressSpinner />
    }

    return <div className="ui-g-12" style={{ ...this.props.style }}>
      <div className="ui-g-2 ui-md-2 ui-sm-12">
        <GeofenceMapSmall geofence={geofenceAlert.boundary} />
      </div>
      <div className="ui-g-2 ui-md-2 ui-sm-12">
        <div style={{ height: '50px', padding: '5px' }}>
          <Button
            label="Enter"
            icon={geofenceAlert.in ? 'fa fa-sign-in' : 'fa fa-ban'}
            className={geofenceAlert.in ? 'ui-button-success' : ''}
            onClick={this.toggleIn.bind(this)}
            style={{
              width: '100px',
              height: '40px',
              backgroundColor: geofenceAlert.in ? '#4385b4' : '#cccccc',
            }}
          />
        </div>
        <div style={{ height: '50px', padding: '5px' }}>
          <Button
            label="Exit"
            icon={geofenceAlert.out ? 'fa fa-sign-out' : 'fa fa-ban'}
            className={geofenceAlert.out ? 'ui-button-success' : ''}
            onClick={this.toggleOut.bind(this)}
            style={{ width: '100px', height: '40px', backgroundColor: geofenceAlert.out ? '#4385b4' : '#cccccc' }}
          />
        </div>
      </div>
      <div className="ui-g-3 ui-md-3 ui-sm-12" style={{ padding: '5px' }}>
      {
        this.state.editUnits
        ? <div>
          <ListBox
            //style={{ width: '100px', height: '100px'}}
            style={{ width: '100px'}}
            listStyle={{ height: '200px' }}
            filter={true}
            value={geofenceAlert.units.map(unit => unit.unit_id)}
            multiple={true}
            options={this.props.units.map((unit) => {
              return {
                label: unit.label,
                value: unit.unit_id
              }
            })}
            onChange={(e) => {
              if (e.value.length > 0) {
                let geofenceAlert = Object.assign({}, this.state.geofenceAlert);
                geofenceAlert.units = this.props.units.filter(unit => e.value.reduce((accum, unit_id) => (unit.unit_id == unit_id ? true : accum), false))
                this.setState({
                  geofenceAlert
                });
              } else {
                window.alert('You must have at least 1 unit selected.');
              }
            }}
          />
          <Button label="Save" className="ui-button-success" onClick={this.saveGeofenceAlert.bind(this)} />
          <Button label="Cancel" className="ui-button-danger" onClick={() => {this.setState({editUnits: false})}} />
        </div>
        : geofenceAlert.units.map((unit) => {
          return <div className="geofence-alert-tag" onClick={() => { this.setState({editUnits: true}) }} style={{ cursor: 'pointer' }}>
            <i className="material-icons" style={{ position: 'relative', top: '4px' }}>drive_eta</i>
            <span>{unit.label}</span>
          </div>;
        })
      }
      </div>
      {
        this.state.editContacts
        ? <div className="ui-g-3 ui-md-3 ui-sm-12" style={{ padding: '5px' }}>
          <React.Fragment>
            {
              this.props.contacts && this.props.contacts.contacts && this.props.contacts.contacts.length > 0
              ? this.props.contacts.contacts.map((contact) => {
                return <Contact
                  contact={contact}
                  selected={this.state.geofenceAlert.alertContacts.reduce((accum, ac) => (ac.contact.contact_id == contact.contact_id ? true : accum), false)}
                  selectedEmail={this.state.geofenceAlert.alertContacts.reduce((accum, ac) => (ac.contact.contact_id == contact.contact_id && ac.email ? true : accum), false)}
                  selectedPhone={this.state.geofenceAlert.alertContacts.reduce((accum, ac) => (ac.contact.contact_id == contact.contact_id && ac.phone ? true : accum), false)}
                  onSelect={this.onContactSelect.bind(this)}
                  onToggleEmail={this.toggleEmail.bind(this)}
                  onTogglePhone={this.togglePhone.bind(this)}
                />
              })
              : 'No Contacts'
            }
            <Button label="Save" className="ui-button-success" onClick={this.saveGeofenceAlert.bind(this)} />
            <Button 
              label="Cancel" 
              className="ui-button-danger" 
              onClick={() => {
                this.setState({
                  editContacts: false
                });
                this.mapGeofenceAlert(this.props.geofenceAlert);
              }}
            />
          </React.Fragment>
        </div>
        : <div className="ui-g-3 ui-md-3 ui-sm-12" style={{ padding: '5px' }} onClick={() => {this.setState({editContacts: true})}}>
        {
          geofenceAlert.alertContacts.map((ac) => {
            const name = ac.contact.name.includes(' ') ? ac.contact.name.split(' ') : [ac.contact.name];

            return <div className="ui-g-12 geofence-alert-tag">
              <div className="ui-g-7">
                <i className="material-icons" style={{ position: 'relative', top: '4px' }}>person</i>
                <span>{ (name[0].length > 5 ? name[0].substring(0, 5) + '.' : name[0]) + (name.length > 1 ? (' ' + name[1][0] + '.') : '')}</span>
              </div>
              <div className="ui-g-5" style={{ textAlign: 'right' }}>
              {
                ac.email
                ? <i className="material-icons" style={{ position: 'relative', top: '4px' }}>mail</i>
                : ''
              }
              {
                ac.phone
                ? <i className="material-icons" style={{ position: 'relative', top: '4px' }}>phone</i>
                : ''
              }
              </div>
            </div>
          })
        }
        </div>
      }
      <div className="ui-g-2 ui-md-2 ui-sm-12" style={{ padding: '5px' }}>
        {
          this.state.showConfirmDelete
          ? ''
          : <Button
            className="ui-button-danger"
            label="Delete"
            icon="fa fa-trash"
            disabled={this.state.showConfirmDelete}
            onClick={this.toggleConfirmDelete.bind(this)}
          />
        }        
        {
          this.state.showConfirmDelete
          ? <React.Fragment>
            <Button
              className="ui-button-danger"
              label="Cancel"
              icon="fa fa-ban"
              onClick={this.toggleConfirmDelete.bind(this)}
            />
            <Button
              className="ui-button-success"
              label="Sure?"
              icon="fa fa-check"
              onClick={this.deleteGeofenceAlert.bind(this)}
            />
          </React.Fragment>
          : ''
        }
      </div>
    </div>
  }
}

export default withApollo(GeofenceAlert);