import React, { Component } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Modal } from 'primereact/components/dialog/Dialog';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';
import TimezonePicker from 'react-timezone';
import App from './App';

const USER_QUERY = gql`
  query {
    user {
      user_id
      fullname
      firstname
      lastname
      email
      account {
        title
      }
    }
  }
`;

class AppInlineProfileClass extends Component {

  constructor() {
    super();
    this.state = {
      expanded: false,
      displayLogoutConfirm: false,
      displayProfileModal: false,
      firstname: '',
      lastname: '',
      email: '',
      title: '',
    };
    this.onClick = this.onClick.bind(this);
    this.saveProfileInfo = this.saveProfileInfo.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.user && this.props.user.user && !this.state.displayProfileModal) {
      this.mapProfileInfo(props);
    }
  }

  componentDidMount() {
    if (this.props.user && this.props.user.user) {
      this.mapProfileInfo(this.props);
    }
  }

  mapProfileInfo(props) {
    this.setState({
      firstname: props.user.user.firstname,
      lastname: props.user.user.lastname,
      email: props.user.user.email,
      title: props.user.user.account.title,
    });
  }

  saveProfileInfo() {
    const mutation = gql`
    mutation updateUserInfo($firstname: String!, $lastname: String!, $email: String!) {
      updateUserInfo(firstname: $firstname, lastname: $lastname, email: $email)
    }`;
    const variables = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
    };
    this.props.client.mutate({ mutation, variables })
    .then( (res) => {
      this.setState({
        displayProfileModal: false
      }, () => {
        this.props.user.refetch();
      });
    } );
  }

  onClick(event) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }

  doLogout() {
    localStorage.removeItem('GPSLeadersTrackingAuthToken');
    localStorage.removeItem('gpsleadertracking-reports');
    window.location = '/'
  }

  render() {
    return <div>
      <div className={classNames('profile', { 'profile-expanded': this.state.expanded })}>
        <a onClick={this.onClick}>
          {/*<img className="profile-image" src="assets/layout/images/avatar.png" alt="Profile" />*/}
          <span className="profile-name">{ (this.props.user && this.props.user.user && this.props.user.user.account && this.props.user.user.account.title) ? ( (this.props.user.user.account.title.length <= 18) ? this.props.user.user.account.title : this.props.user.user.account.title.substring(0, 18) + '...' ) : '...' }</span>
          <i className="material-icons">keyboard_arrow_down</i><br />
          <span className="profile-name" style={{ fontSize: '.7em', textAlign: 'left'}}>{ (this.props.user && this.props.user.user) ? this.props.user.user.fullname : '...' }</span>
        </a>
      </div>

      <ul className="ultima-menu profile-menu">
        <li role="menuitem" onClick={ () => {
          this.props.history.push('/profile');
          this.setState({
            expanded: false
          });
        } }>
          <a className="ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
            <i className="material-icons">person</i>
            <span>Profile</span>
          </a>
        </li>
        {/*
        <li role="menuitem">
          <a className="ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
            <i className="material-icons">security</i>
            <span>Privacy</span>
          </a>
        </li>
        <li role="menuitem">
          <a className="ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
            <i className="material-icons">settings_application</i>
            <span>Settings</span>
          </a>
        </li>
        */}
        <li role="menuitem" onClick={() => { this.doLogout() }}>
          <a className="ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
            <i className="material-icons">power_settings_new</i>
            <span>Logout</span>
          </a>
        </li>
      </ul>
      <Dialog
        header="Logout"
        visible={this.state.displayLogoutConfirm}
        modal={false}
        footer={
          <div>
            <Button label="Yes" onClick={() => { this.doLogout() }} />
            <Button label="No" onClick={() => { this.setState({ displayLogoutConfirm: false }) }} />
          </div>
        }
        onHide={() => this.setState({ display: false })}
      >
        <p>Are you sure you want to logout?</p>
      </Dialog>
      <Dialog
        header="Edit Profile"        
        visible={this.state.displayProfileModal}
        modal={true}        
        appendTo={document.getElementById('app-footer')}
        footer={
          <div>
            <Button className="ui-button-danger" label="Cancel" onClick={() => {this.setState({displayProfileModal: false})}} />
            <Button className="ui-button-success" label="Save" onClick={this.saveProfileInfo} />
          </div>
        }
        onHide={ () => { this.setState({displayProfileModal: false}) } }
      >
        <div>
          <div>
            <label htmlFor="profile-firstname">First Name:</label>
          </div>
          <div>
            <InputText id="profile-firstname" placeholder="First Name" style={{fontSize: 18}} value={this.state.firstname} onChange={ (e) => {this.setState({firstname: e.target.value})} } />            
          </div>
          <div>
            <label htmlFor="profile-lastname">Last Name:</label>
          </div>
          <div>
            <InputText id="profile-lastname" placeholder="Last Name" style={{fontSize: 18}} value={this.state.lastname} onChange={ (e) => {this.setState({lastname: e.target.value})} } />
          </div>
          <div>
            <label htmlFor="profile-email">Email:</label>
          </div>
          <div>
            <InputText id="profile-email" placeholder="Email" style={{fontSize: 18}} value={this.state.email} onChange={ (e) => {this.setState({email: e.target.value})} } />
          </div>
          <div>
            <label htmlFor="profile-timezone">Time Zone:</label>
          </div>
          <div>
          <TimezonePicker
            value={this.state.timezone}
            onChange={timezone => {
            }}
            inputProps={{
              placeholder: 'Select Timezone...',
              name: 'timezone',
            }}
          />
          </div>
        </div>
      </Dialog>
    </div>
  }
}

export default compose(
  graphql(USER_QUERY, {
    name: 'user'
  })
)(withApollo(AppInlineProfileClass));