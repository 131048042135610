import React, { Component } from 'react';

import { Card } from 'primereact/components/card/Card';
import { Steps } from 'primereact/components/steps/Steps';

import SelectGeofence from './SelectGeofence';
import SelectUnits from './SelectUnits';
import SelectContacts from './SelectContacts';
import GeofenceMapSmall from './GeofenceMapSmall';
import Unit from './Unit';
import Contact from './Contact';

import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';

import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

import GeofenceAlertList from './GeofenceAlertList';

const GEOFENCEALERTS_QUERY = gql`
query {
  boundaryAlerts {
    boundaryalert_id
    boundary_id
    boundary {
      name
      radius
      type
      latitude
      longitude
      points {
        type
        coordinates {
          latitude
          longitude
        }
      }
    }
    compid
    in
    out
    units {
      unit_id
      label
    }
    alertContacts {
      boundaryalert_id
      contact_id
      contact {
        contact_id
        compid
        name
        email
        phone
      }
      email
      phone
    }
  }
}`;

const steps = [{
    label: 'Select Geofence',
    command: (e) => {}
  }, {
    label: 'Select Units',
    command: (e) => {}
  }, {
    label: 'Select Contacts',
    command: (e) => {}
  }, {
    label: 'Done!',
    command: (e) => {}
  }
];

class GeofenceAlerts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showWizard: false,
      activeStep: 0,
      selectedGeofence: null,
      selectedIn: true,
      selectedOut: true,
      selectedUnits: [],
      selectedContacts: [],
      geofenceAlerts: [],
      filter: ''
    };
  }

  componentDidMount() {
    this.loadGeofenceAlerts();
  }

  loadGeofenceAlerts() {
    this.props.client.query({
      query: GEOFENCEALERTS_QUERY
    }).then((res) => {
      console.log('GF Alerts:', res);
      this.setState({
        geofenceAlerts: res.data.boundaryAlerts
      });
    });
  }

  onStepChange(e) {
    console.log('Step Change:', e);
    this.setState({
      activeStep: e.index
    });
  }

  onSelectGeofence(geofence) {
    this.setState({
      selectedGeofence: geofence,
      activeStep: 1
    });
  }

  isUnitInList(unit, units) {
    return units.reduce((accum, u) => {
      return unit.unit_id == u.unit_id ? true : accum;
    }, false);
  }

  isContactInList(contact, contacts) {
    return contacts.reduce((accum, c) => {
      return contact.contact_id == c.contact_id ? true : accum;
    }, false);
  }

  onSelectUnit(unit) {
    if (this.isUnitInList(unit, this.state.selectedUnits)) {
      // remove from list
      let selectedUnits = this.state.selectedUnits.concat();
      this.setState({
        selectedUnits: selectedUnits.filter((u) => {
          return unit.unit_id == u.unit_id ? false : true;
        })
      });
    } else {
      // Add to list
      let selectedUnits = this.state.selectedUnits.concat();
      selectedUnits.push(unit);
      this.setState({
        selectedUnits
      });
    }    
  }

  onDoneSelectingUnit() {
    this.setState({
      activeStep: 2
    });
  }

  onSelectContact(contact) {
    if (this.isContactInList(contact, this.state.selectedContacts)) {
      // remove from list
      let selectedContacts = this.state.selectedContacts.concat();
      this.setState({
        selectedContacts: selectedContacts.filter((c) => {
          return contact.contact_id == c.contact_id ? false : true
        })
      });
    } else {
      // Add to list
      let selectedContacts = this.state.selectedContacts.concat();
      selectedContacts.push(Object.assign({}, contact, {selectedEmail: true, selectedPhone: true}));
      this.setState({
        selectedContacts
      });
    }
  }

  onToggleContactEmail(contact) {
    this.setState({
      selectedContacts: this.state.selectedContacts.map((c) => {
        if (c.contact_id == contact.contact_id) {
          return Object.assign({}, c, {
            selectedEmail: !c.selectedEmail
          });
        } else {
          return c;
        }
      })
    });
  }

  onToggleContactPhone(contact) {
    this.setState({
      selectedContacts: this.state.selectedContacts.map((c) => {
        if (c.contact_id == contact.contact_id) {
          return Object.assign({}, c, {
            selectedPhone: !c.selectedPhone
          });
        } else {
          return c;
        }
      })
    });
  }

  onDoneSelectingContacts() {
    this.setState({
      activeStep: 3
    });

    // TODO: Save Geofence Alert
    const mutation = gql`
    mutation addBoundaryAlert($boundary_id: Int!, $in: Boolean!, $out: Boolean!) {
      addBoundaryAlert(boundary_id: $boundary_id, in: $in, out: $out)
    }`;
    const variables = {
      boundary_id: this.state.selectedGeofence.boundary_id,
      in: this.state.selectedIn,
      out: this.state.selectedOut
    };
    this.props.client.mutate({
      mutation,
      variables
    }).then((res) => {
      const boundaryalert_id = res.data.addBoundaryAlert;
      const mutation = gql`
      mutation addBoundaryAlertUnits($boundaryalert_id: Int!, $unit_ids: [Int!]) {
        addBoundaryAlertUnits(boundaryalert_id: $boundaryalert_id, unit_ids: $unit_ids)
      }`;
      const variables = {
        boundaryalert_id,
        unit_ids: this.state.selectedUnits.map(unit => unit.unit_id)
      };
      this.props.client.mutate({
        mutation,
        variables
      }).then((res) => {
        if (res) {
          console.log('Added units');
          this.state.selectedContacts.forEach((contact) => {
            const mutation = gql`
            mutation addBoundaryAlertContact($boundaryalert_id: Int!, $contact_id: Int!, $email: Boolean!, $phone: Boolean!) {
              addBoundaryAlertContact(boundaryalert_id: $boundaryalert_id, contact_id: $contact_id, email: $email, phone: $phone)
            }`;
            const variables = {
              boundaryalert_id,
              contact_id: contact.contact_id,
              email: contact.selectedEmail,
              phone: contact.selectedPhone
            };
            this.props.client.mutate({
              mutation,
              variables
            }).then((res) => {
              console.log('Add Contact:', res);
              this.resetAndHide()
              this.loadGeofenceAlerts();
            }).catch((err) => {
              console.log('Add contact err:', err);
            });
          });          
        } else {
          console.log('Could not add units');
        }
      })
    }).catch((err) => {
      console.log('Add Boundary Alert Error:', err);
    });
  }

  resetAndHide() {
    this.setState({
      activeStep: 0,
      selectedGeofence: null,
      selectedUnits: [],
      selectedContacts: [],
      showWizard: false
    });
  }

  render() {

    const geofenceAlerts = this.state.filter.length > 0
    ? this.state.geofenceAlerts.filter((gfa) => {
      if (
        gfa.boundary.name.toLowerCase().includes(this.state.filter.toLowerCase())
        || gfa.units.reduce((accum, unit) => (unit.label.toLowerCase().includes(this.state.filter.toLowerCase()) ? true : accum), false)
        || gfa.alertContacts.reduce((accum, ac) => (ac.contact.name.toLowerCase().includes(this.state.filter.toLowerCase()) ? true : accum), false)
      ) {
        return true;
      } else {
        return false;
      }
    })
    : this.state.geofenceAlerts;

    return <Card className="alert-card-unit clearfix" style={{ padding: '10px', margin: 0, borderRadius: 0, borderBottomRightRadius: 0 }}>
      {
        this.state.showWizard
        ? (
          <div>
            <div>
              <Button
                className="ui-button-danger"
                label="Cancel"
                icon="fa fa-ban"
                style={{
                  margin: '10px',
                  width: '100px'
                }}
                onClick={this.resetAndHide.bind(this)}
              />
              <span> * Use the wizard below to create a new Geofence alert.</span>
            </div>
            <Steps model={steps} activeIndex={this.state.activeStep} onSelect={this.onStepChange.bind(this)} readOnly={false} style={{ borderBottom: '1px solid #cccccc' }} />
            <div style={{ padding: '5px', height: '110px', }}>
              {
                this.state.activeStep == 0
                ? (
                  <SelectGeofence onSelectGeofence={this.onSelectGeofence.bind(this)} />
                ) : (
                <React.Fragment>
                  <GeofenceMapSmall geofence={this.state.selectedGeofence} onSelect={() => {this.setState({activeStep: 0, selectedGeofence: null})}} />
                  <div style={{ textAlign: 'center', verticalAlign: 'middle', width: '100px', height: '100px', float: 'left' }}>
                    <div style={{ height: '50px', padding: '5px' }}>
                      <Button
                        label="Enter"
                        icon={this.state.selectedIn ? 'fa fa-sign-in' : 'fa fa-ban'}
                        className={this.state.selectedIn ? 'ui-button-success' : ''}
                        onClick={() => {
                          this.setState({
                            selectedIn: !this.state.selectedIn
                          })
                        }}
                        style={{ width: '100px', height: '40px', backgroundColor: this.state.selectedIn ? '#4385b4' : '#cccccc' }}
                      />
                    </div>
                    <div style={{ height: '50px', padding: '5px' }}>
                      <Button
                        label="Exit"
                        icon={this.state.selectedOut ? 'fa fa-sign-out' : 'fa fa-ban'}
                        className={this.state.selectedOut ? 'ui-button-success' : ''}
                        onClick={() => {
                          this.setState({
                            selectedOut: !this.state.selectedOut
                          })
                        }}
                        style={{ width: '100px', height: '40px', backgroundColor: this.state.selectedOut ? '#4385b4' : '#cccccc' }}
                      />
                    </div>
                  </div>
                  <div className="vertical-divider" />
                  {
                    this.state.activeStep == 1
                    ? <SelectUnits selectedUnits={this.state.selectedUnits} units={this.props.units} onSelectUnit={this.onSelectUnit.bind(this)} onDone={this.onDoneSelectingUnit.bind(this)} showDoneButton={this.state.selectedUnits.length > 0 ? true : false} />  
                    : (
                      <React.Fragment>                    
                        {
                          this.state.selectedUnits.map(unit => <Unit unit={unit} readOnly={true} onSelect={() => {this.setState({activeStep: 1})}} />)
                        }
                        <div className="vertical-divider" />                
                        {
                          this.state.activeStep == 2
                          ? (
                          <SelectContacts
                            contacts={this.props.contacts}
                            onSelectContact={this.onSelectContact.bind(this)}
                            selectedContacts={this.state.selectedContacts}
                            showDoneButton={this.state.selectedContacts.length > 0 ? true : false}
                            onDone={this.onDoneSelectingContacts.bind(this)}
                            onToggleEmail={this.onToggleContactEmail.bind(this)}
                            onTogglePhone={this.onToggleContactPhone.bind(this)}
                          />
                          ) : (
                            <React.Fragment>
                            {
                              this.state.selectedContacts.map(contact => <Contact readOnly={true} contact={contact} selectedEmail={contact.selectedEmail} selectedPhone={contact.selectedPhone} selected={true} onSelect={() => {this.setState({activeStep: 2})}} />)
                            }
                            </React.Fragment>
                          )
                        }
                      </React.Fragment>
                    )
                  }
                    </React.Fragment>
                  )
                }
            </div> 
          </div>
        ) : (
          <div className="ui-g-12">
            <div className="ui-g-6">
            <Button
              className="ui-button-success"
              label="Create"
              icon="fa fa-plus"
              style={{
                margin: '10px',
                width: '100px'
              }}
              onClick={() => {
                this.setState({
                  showWizard: true
                });
              }}
            />
            </div>
            <div className="ui-g-6" style={{ textAlign: 'right', padding: '15px' }}>
              <InputText placeholder="Filter" value={this.state.filter} onChange={(e) => {this.setState({filter: e.target.value})}} />
            </div>
          </div>
        )
      }
      {
        this.state.showWizard
        ? ''
        : <GeofenceAlertList
          geofenceAlerts={geofenceAlerts}
          units={this.props.units}
          contacts={this.props.contacts}
          reloadGeofenceAlerts={() => {this.loadGeofenceAlerts()}}
        />
      }
    </Card>
  }
}

export default withApollo(GeofenceAlerts);