import React, { Component } from 'react';

import { Button } from 'primereact/components/button/Button';
import { Card } from 'primereact/components/card/Card';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { Fieldset } from 'primereact/components/fieldset/Fieldset';
import { InputMask } from 'primereact/components/inputmask/InputMask';
import { InputText } from 'primereact/components/inputtext/InputText';
import { InputSwitch } from 'primereact/components/inputswitch/InputSwitch';
import { OverlayPanel } from 'primereact/components/overlaypanel/OverlayPanel';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';

import { Circle, CircleMarker, Map, TileLayer, Marker, Rectangle, Polygon, Popup } from 'react-leaflet';

import AlertContacts from './Alerts/AlertContacts';
import AlertContent from './Alerts/AlertContent';
import AlertSettings from './Alerts/AlertSettings';

import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

const GROUPS_QUERY = gql`
query {
  groups {
    group_id
    compid
    name
    units {
      unit_id
      compid
      unittype_id
      imei
      serial
      simnumber
      simphone
      label
      createdAt
      lastEvent
    }
  }
}`;

const CONTACTS_QUERY = gql`
query {
  contacts {
    contact_id
    name
    email
    phone
  }
}
`;

class Alerts extends Component {

  constructor(props) {
    super(props);

    let showAccountAlerts = false;
    let showUnitAlerts = false;
    let showContacts = true;

    /*
    if (localStorage.getItem('gpsleaders-showAccountAlerts') == 1
    || localStorage.getItem('gpsleaders-showAccountAlerts') == 0) {
      showAccountAlerts = localStorage.getItem('gpsleaders-showAccountAlerts') == 1 ? true : false;
    }
    if (localStorage.getItem('gpsleaders-showUnitAlerts') == 1
    || localStorage.getItem('gpsleaders-showUnitAlerts') == 0) {
      showUnitAlerts = localStorage.getItem('gpsleaders-showUnitAlerts') == 1 ? true : false;
    }
    */
    if (localStorage.getItem('gpsleaders-showContacts') == 1
    || localStorage.getItem('gpsleaders-showContacts') == 0) {
      showContacts = localStorage.getItem('gpsleaders-showContacts') == 1 ? true : false;
    }

    this.state = {
      showAccountAlerts,
      showUnitAlerts,
      showContacts,
      newAlertName: '',
      newAlertEmail: '',
      newAlertPhone: '',
      /*
      hasAccountBatteryAlert: false,
      hasAccountTamperAlert: false,
      accountBatteryAlertId: 0,
      accountTamperAlertId: 0,
      accountBatteryAlertContacts: [],
      accountTamperAlertContacts: [],
      */
      selectedContact: null,
      showContactModal: false,
      contacts: [],
      contactsLoading: true,
      renewContacts: true
    }
  }

  componentWillReceiveProps(props) {
    //console.log('New Props:', props);
    this.setState({
      hasAccountBatteryAlert: false,
      accountBatteryAlertId: 0,
      accountBatteryAlertContacts: [],
      hasAccountTamperAlert: false,
      accountTamperAlertId: 0,
      accountTamperAlertContacts: []
    });
    if (props.accountAlerts && props.accountAlerts.alerts && props.accountAlerts.alerts.length > 0) {
      console.log('Account Alerts:', props.accountAlerts.alerts);      
      props.accountAlerts.alerts.forEach((alert) => {
        if (alert.alertType.name == 'lowbattery') {
          this.setState({
            hasAccountBatteryAlert: true,
            accountBatteryAlertId: alert.alert_id,
            accountBatteryAlertContacts: alert.alertContacts
          });
        } else if (alert.alertType.name == 'tamper') {
          this.setState({
            hasAccountTamperAlert: true,
            accountTamperAlertId: alert.alert_id,
            accountTamperAlertContacts: alert.alertContacts
          });
        }
      });
    }

    if (props.contacts && props.contacts.contacts && this.state.renewContacts) {
      this.setState({
        contacts: props.contacts.contacts.map((contact) => {
          return {
            ...contact,
            isSaved: true
          }
        }),
        renewContacts: false
      });
    }
  }

  componentDidMount() {
    let showAccountAlerts = true;
    let showUnitAlerts = true;
    let showContacts = true;

    if (localStorage.getItem('gpsleaders-showAccountAlerts') == 1
    || localStorage.getItem('gpsleaders-showAccountAlerts') == 0) {
      showAccountAlerts = localStorage.getItem('gpsleaders-showAccountAlerts') == 1 ? true : false;
    }
    if (localStorage.getItem('gpsleaders-showUnitAlerts') == 1
    || localStorage.getItem('gpsleaders-showUnitAlerts') == 0) {
      showUnitAlerts = localStorage.getItem('gpsleaders-showUnitAlerts') == 1 ? true : false;
    }
    if (localStorage.getItem('gpsleaders-showContacts') == 1
    || localStorage.getItem('gpsleaders-showContacts') == 0) {
      showContacts = localStorage.getItem('gpsleaders-showContacts') == 1 ? true : false;
    }

    this.setState({
      showAccountAlerts,
      showUnitAlerts,
      showContacts
    });

    this.loadAlertContacts();
  }

  loadAlertContacts() {
    this.props.client.query({ query: CONTACTS_QUERY }).then((res) => {
      this.setState({
        contacts: res.data.contacts && res.data.contacts.length > 0
        ? res.data.contacts.map((contact) => {
          return {
            ...contact,
            isSaved: true,
          }
        })
        : [],
        contactsLoading: false
      });
    });
  }

  updateContact(index) {
    const variables = this.state.contacts[index];
    console.log(index, variables);
    if (variables.contact_id && variables.name && variables.email && variables.phone) {
      const mutation = gql`
      mutation updateContact ($contact_id: Int!, $name: String!, $email: String!, $phone: String!) {
        updateContact (contact_id: $contact_id, name: $name, email: $email, phone: $phone)
      }`;
      this.props.client.mutate({ mutation, variables })
      .then((res) => {
        this.setState({
          renewContacts: true
        }, () => {
          //this.props.contacts.refetch();
          this.loadAlertContacts();
        });
      });
    } else {
      window.alert('Error: invalid contact id');
    }
  }

  deleteContact(contact_id) {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      const mutation = gql`
      mutation deleteContact ($contact_id: Int!) {
        deleteContact (contact_id: $contact_id)
      }
      `;
      const variables = {
        contact_id
      };
      this.props.client.mutate({
        mutation,
        variables
      }).then((res) => {
        this.setState({
          renewContacts: true
        }, () => {
          //this.props.contacts.refetch();
          this.loadAlertContacts();
        });        
      });
    }
  }

  addNewAlertContact() {
    console.log('Name:', this.state.newAlertName)
    if (this.state.newAlertName.length > 0
      && (this.state.newAlertEmail.length > 0 || this.state.newAlertPhone.length > 0)
    ) {
      const mutation = gql`
      mutation addContact ($name: String!, $email: String!, $phone: String!) {
        addContact (name: $name, email: $email, phone: $phone)
      }`;
      const variables = {
        name: this.state.newAlertName,
        email: this.state.newAlertEmail,
        phone: this.state.newAlertPhone
      };
      console.log('Vars:', variables);
      this.props.client.mutate({
        mutation,
        variables
      }).then((res) => {
        //console.log('Res:', res);
        this.setState({
          newAlertName: '',
          newAlertEmail: '',
          newAlertPhone: '',
          renewContacts: true
        }, () => {
          //this.props.contacts.refetch();
          this.loadAlertContacts();
        });        
      });
    } else {
      window.alert('Please enter valid email or phone');
    }
  }

  toggleShow(name) {
    let newState = {};
    newState[name] = !this.state[name];
    localStorage.setItem('gpsleaders-'+name, newState[name] ? 1 : 0);
    console.log(name + ': ' + localStorage.getItem('gpsleaders-'+name));
    this.setState(newState);
  }

  /*
  contactInList(contact, list) {
    if (list) {
      return list.reduce((accum, current, index) => {
        console.log('Contact:', contact);
        console.log('Current:', current);
        return contact.contact_id == current.contact_id ? true : accum
      }, false);
    } else {
      return false;
    }
  }
  */

  toggleAccountBatteryAlertContact(contact, callback) {
    if (this.contactInList(contact, this.state.accountBatteryAlertContacts)) {
      // Delete alert contact
      const mutation = gql`
      mutation deleteAlertContact ($alert_id: Int!, $contact_id: Int!) {
        deleteAlertContact (alert_id: $alert_id, contact_id: $contact_id)
      }`;
      const variables = {
        alert_id: this.state.accountBatteryAlertId,
        contact_id: contact.contact_id
      };
      this.props.client.mutate({ mutation, variables }).then((res) => {
        this.props.accountAlerts.refetch();
      });
    } else {
      this.createAlertContact(this.state.accountBatteryAlertId, contact.contact_id);
      if (callback) callback();
    }
  }

  toggleAccountTamperAlertContact(contact, callback) {
    if (this.contactInList(contact, this.state.accountTamperAlertContacts)) {
      // Delete alert contact
      const mutation = gql`
      mutation deleteAlertContact ($alert_id: Int!, $contact_id: Int!) {
        deleteAlertContact (alert_id: $alert_id, contact_id: $contact_id)
      }`;
      const variables = {
        alert_id: this.state.accountTamperAlertId,
        contact_id: contact.contact_id
      };
      this.props.client.mutate({ mutation, variables }).then((res) => {
        this.props.accountAlerts.refetch();
      });
    } else {
      this.createAlertContact(this.state.accountTamperAlertId, contact.contact_id);
      if (callback) callback();
    }
  }

  createAlertContact(alert_id, contact_id) {
    const mutation = gql`
    mutation addAlertContact ($alert_id: Int!, $contact_id: Int!) {
      addAlertContact (alert_id: $alert_id, contact_id: $contact_id)
    }
    `;
    const variables = { alert_id, contact_id };
    this.props.client.mutate({ mutation, variables })
    .then((res) => {
      this.props.accountAlerts.refetch();
    });
  }

  async getAlertContactEmailStatus(alert_id, contact_id) {
    const query = gql`
    query alertContact ($alert_id: Int!, $contact_id: Int!) {
      alertContact (alert_id: $alert_id, contact_id: $contact_id) {
        email
      }
    }`;
    const variables = { alert_id, contact_id };
    return await this.props.client.query({ query, variables })
    .then((res) => {
      //console.log('getAlertContactEmailStatus:', res.data.alertContact.email)
      if (res.data && res.data.alertContact && res.data.alertContact) {
        console.log('getAlertContactEmailStatus:', res.data.alertContact.email)
        return res.data.alertContact.email;
      } else {
        return false;
      }
    });
  }

  async getAlertContactPhoneStatus(alert_id, contact_id) {
    const query = gql`
    query alertContact ($alert_id: Int!, $contact_id: Int!) {
      alertContact (alert_id: $alert_id, contact_id: $contact_id) {
        phone
      }
    }`;
    const variables = { alert_id, contact_id };
    return await this.props.client.query({ query, variables })
    .then((res) => {
      //console.log('getAlertContactEmailStatus:', res.data.alertContact.email)
      if (res.data && res.data.alertContact && res.data.alertContact) {
        console.log('getAlertContactPhoneStatus:', res.data.alertContact.phone)
        return res.data.alertContact.phone;
      } else {
        return false;
      }
    });
  }

  async toggleAlertContactEmail(alert_id, contact_id) {
    const mutation = gql`
    mutation updateAlertContact ($alert_id: Int!, $contact_id: Int!, $email: Boolean!) {
      updateAlertContact (alert_id: $alert_id, contact_id: $contact_id, email: $email)
    }`;
    const variables = {
      alert_id,
      contact_id,
      email: ! await this.getAlertContactEmailStatus(alert_id, contact_id)
    };
    console.log('Vars:', variables);
    this.props.client.mutate({ mutation, variables })
    .then((res) => {
      console.log('toggleAlertContactEmail:', res);
      this.props.accountAlerts.refetch();
    });
  }

  async toggleAlertContactPhone(alert_id, contact_id) {
    const mutation = gql`
    mutation updateAlertContact ($alert_id: Int!, $contact_id: Int!, $phone: Boolean!) {
      updateAlertContact (alert_id: $alert_id, contact_id: $contact_id, phone: $phone)
    }`;
    const variables = {
      alert_id,
      contact_id,
      phone: ! await this.getAlertContactPhoneStatus(alert_id, contact_id)
    };
    console.log('Vars:', variables);
    this.props.client.mutate({ mutation, variables })
    .then((res) => {
      console.log('toggleAlertContactEmail:', res);
      this.props.accountAlerts.refetch();
    });
  }

  getAccountBatteryAlertContactEmailStatus(contact_id) {
    return this.state.accountBatteryAlertContacts.reduce((accum, ac, index) => {
      if (ac.contact_id == contact_id) {
        return ac.email;
      } else {
        return accum;
      }
    }, false);
  }

  getAccountBatteryAlertContactPhoneStatus(contact_id) {
    return this.state.accountBatteryAlertContacts.reduce((accum, ac, index) => {
      if (ac.contact_id == contact_id) {
        return ac.phone;
      } else {
        return accum;
      }
    }, false);
  }

  getAccountTamperAlertContactEmailStatus(contact_id) {
    return this.state.accountTamperAlertContacts.reduce((accum, ac, index) => {
      if (ac.contact_id == contact_id) {
        return ac.email;
      } else {
        return accum;
      }
    }, false);
  }

  getAccountTamperAlertContactPhoneStatus(contact_id) {
    return this.state.accountTamperAlertContacts.reduce((accum, ac, index) => {
      if (ac.contact_id == contact_id) {
        return ac.phone;
      } else {
        return accum;
      }
    }, false);
  }

  renderAccountBatteryAlertContacts() {
    return <span>
      {
        this.props.contacts.contacts && this.props.contacts.contacts.length > 0
        ? this.props.contacts.contacts.map((contact) => {
          const enabled = this.contactInList(contact, this.state.accountBatteryAlertContacts);
          let panel;
          return <span style={{ padding: '2px' }}>
            <i 
              className="material-icons"
              id={'accountAlertContact-'+contact.contact_id}
              style={{ color: enabled ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 40 }}
              onClick={(e) => {                
                this.toggleAccountBatteryAlertContact(contact, () => {
                  panel.toggle(e);
                });
              }}
            >contact_mail</i>
            <Tooltip for={'#accountAlertContact-'+contact.contact_id} title={contact.name} tooltipPosition="top" />
            <OverlayPanel ref={(el) => panel = el} appendTo={document.getElementById('last')}>
              <i
                className="material-icons"
                id={'accountAlertContactEmail-'+contact.contact_id}
                style={{ color: this.getAccountBatteryAlertContactEmailStatus(contact.contact_id) ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 40 }}
                onClick={() => {this.toggleAlertContactEmail(this.state.accountBatteryAlertId, contact.contact_id, )}}
              >mail</i>
              <Tooltip for={'#accountAlertContactEmail-'+contact.contact_id} title={contact.email.length > 0 ? contact.email : 'No Email' } tooltipPosition="top" />
              <i
                className="material-icons"
                id={'accountAlertContactPhone-'+contact.contact_id}
                style={{ color: this.getAccountBatteryAlertContactPhoneStatus(contact.contact_id) ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 40 }}
                onClick={() => {this.toggleAlertContactPhone(this.state.accountBatteryAlertId, contact.contact_id, )}}
              >phone</i>
              <Tooltip for={'#accountAlertContactPhone-'+contact.contact_id} title={contact.phone.length > 0 ? contact.phone : 'No Phone' } tooltipPosition="top" />
            </OverlayPanel>
          </span>;
        })
        : 'No Contacts.'
      }
    </span>
  }

  renderAccountTamperAlertContacts() {
    return <span>
      {
        this.props.contacts.contacts && this.props.contacts.contacts.length > 0
        ? this.props.contacts.contacts.map((contact) => {
          const enabled = this.contactInList(contact, this.state.accountTamperAlertContacts);
          let panel;
          return <span style={{ padding: '2px' }}>
            <i 
              className="material-icons"
              id={'accountAlertContact-'+contact.contact_id}
              style={{ color: enabled ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 40 }}
              onClick={(e) => {                
                this.toggleAccountTamperAlertContact(contact, () => {
                  panel.toggle(e);
                });
              }}
            >contact_mail</i>
            <Tooltip for={'#accountAlertContact-'+contact.contact_id} title={contact.name} tooltipPosition="top" />
            <OverlayPanel ref={(el) => panel = el} appendTo={document.getElementById('last')}>
              <i
                className="material-icons"
                id={'accountAlertContactEmail-'+contact.contact_id}
                style={{ color: this.getAccountTamperAlertContactEmailStatus(contact.contact_id) ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 40 }}
                onClick={() => {this.toggleAlertContactEmail(this.state.accountTamperAlertId, contact.contact_id, )}}
              >mail</i>
              <Tooltip for={'#accountAlertContactEmail-'+contact.contact_id} title={contact.email.length > 0 ? contact.email : 'No Email' } tooltipPosition="top" />
              <i
                className="material-icons"
                id={'accountAlertContactPhone-'+contact.contact_id}
                style={{ color: this.getAccountTamperAlertContactPhoneStatus(contact.contact_id) ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 40 }}
                onClick={() => {this.toggleAlertContactPhone(this.state.accountTamperAlertId, contact.contact_id, )}}
              >phone</i>
              <Tooltip for={'#accountAlertContactPhone-'+contact.contact_id} title={contact.phone.length > 0 ? contact.phone : 'No Phone' } tooltipPosition="top" />
            </OverlayPanel>
          </span>;
        })
        : 'No Contacts.'
      }
    </span>
  }

  toggleAccountBatteryAlert() {
    if (this.state.hasAccountBatteryAlert && this.state.accountBatteryAlertId) {
      // Delete Attached Alert Contacts first
      const mutation = gql`
      mutation deleteAlertContact ($alert_id: Int!) {
        deleteAlertContact (alert_id: $alert_id)
      }`;
      const variables = {
        alert_id: this.state.accountBatteryAlertId
      };
      this.props.client.mutate({ mutation, variables })
      .then((res) => {
        // Delete alert
        const mutation = gql`
        mutation deleteAlert ($alert_id: Int!) {
          deleteAlert (alert_id: $alert_id)
        }`;
        this.props.client.mutate({ mutation, variables })
        .then((res) => {
          this.props.accountAlerts.refetch();
        });
      });
    } else {
      // Create new alert
      const mutation = gql`
      mutation addAlert ($alerttype_id: Int!) {
        addAlert (alerttype_id: $alerttype_id, unit_id: 0)
      }`;
      const variables = { alerttype_id: 1 };
      this.props.client.mutate({ mutation, variables })
      .then((res) => {
        //console.log('AddAlert:', res);
        this.props.accountAlerts.refetch();
      })
      .catch((err) => {
        console.log('AddAlertError:', err);
      });
    }
  }

  toggleAccountTamperAlert() {
    if (this.state.hasAccountTamperAlert && this.state.accountTamperAlertId) {
      // Delete Attached Alert Contacts first
      const mutation = gql`
      mutation deleteAlertContact ($alert_id: Int!) {
        deleteAlertContact (alert_id: $alert_id)
      }`;
      const variables = {
        alert_id: this.state.accountTamperAlertId
      };
      this.props.client.mutate({ mutation, variables })
      .then((res) => {
        // Delete alert
        const mutation = gql`
        mutation deleteAlert ($alert_id: Int!) {
          deleteAlert (alert_id: $alert_id)
        }`;
        this.props.client.mutate({ mutation, variables })
        .then((res) => {
          this.props.accountAlerts.refetch();
        });
      });
    } else {
      // Create new alert
      const mutation = gql`
      mutation addAlert ($alerttype_id: Int!) {
        addAlert (alerttype_id: $alerttype_id, unit_id: 0)
      }`;
      const variables = { alerttype_id: 2 };
      this.props.client.mutate({ mutation, variables })
      .then((res) => {
        //console.log('AddAlert:', res);
        this.props.accountAlerts.refetch();
      })
      .catch((err) => {
        console.log('AddAlertError:', err);
      });
    }
  }

  contactFieldChange(field, index, value) {
    let contacts = this.state.contacts.concat();
    let contact = Object.assign({}, contacts[index]);

    console.log(field, index, value);

    contact[field] = value;
    contact.isSaved = false;
    contacts.splice(index, 1, contact);
    this.setState({
      contacts
    });
  }

  onAddContact() {
    this.loadAlertContacts();
  }

  render () {
    return (
    <div className="ui-g" style={{ padding: 0 }}>
      <div className="ui-g-12" style={{ padding: 0 }}>
        <div className="card clearfix" style={{ padding: 0 }}>
          <div className="ui-g-6">
            <i
              className="material-icons"
              style={{color: this.state.showContacts ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30}}
              id="alertContacts"
              onClick={() => {this.toggleShow('showContacts')}}
            >contact_mail</i><Tooltip for="#alertContacts" title="Alert Contacts" tooltipPosition="top" />
          </div>
        </div>
        <div className="card clearfix ui-g-12" style={{ borderRadius: 0, border: 0, padding: 0 }}>
          {
            this.state.showContacts
            ? <div className="ui-g-2 ui-lg-3 ui-md-4 ui-sm-12 clearfix" style={{ padding: 0 }}>
              <AlertContacts contacts={ this.props.contacts } onAddContact={ this.onAddContact.bind(this) } />
            </div>
            : ''
          }
          <div className={(this.state.showContacts ? 'ui-g-10 ui-lg-9 ui-md-8' : 'ui-g-12') + ' ui-sm-12 clearfix'} style={{ padding: 0 }}>
            <AlertContent contacts={ this.props.contacts } />
          </div>
        </div>        
        {/* Account Alerts */}
        {/*
          this.state.showAccountAlerts
          ? <Card className="alert-card" header={<div className="card-header1"> Account Alerts</div>}>            
            <div className="subtitle">* Alerts here are triggered for all units in this account.</div>
            <AlertSettings unit_id={0} />
          </Card>
          : ''
        */}        
        {/* Unit Alerts */}
        {/*
          this.state.showUnitAlerts
          ? <Card className="alert-card clearfix" header={<div className="card-header1">Unit Alerts</div>}>
          <div className="subtitle">* Alerts here are triggered per unit.</div>
          {
            this.props.groups.groups
            ? this.props.groups.groups.map((group) => {
            return <div>
              <div className="card-group-header">{group.name} Group</div>
              <div className="card-group-content clearfix">
                {
                  group.units.length > 0
                  ? group.units.map((unit) => {
                    return <div style={{ float: 'left' }}>
                      <AlertSettings unit_id={unit.unit_id} />
                    </div>;
                  })
                  : 'No Units'
                }
              </div>
            </div>;
            })
            : 'No Unit Groups'
          }
          </Card>
          : ''
        */}        
        {/* Alerts Contacts */}
        {/*
          this.state.showContacts
          ? <Card className="alert-card" header={<div className="card-header1">Alerts Contacts</div>}>
            {
              (this.state.contactsLoading)
              ? <ProgressSpinner />
              : <table>
                <tbody>
                  {
                    this.state.contacts.map((contact, index) => (
                      <tr>
                        <td><i className="material-icons">contact_mail</i></td>
                        <td><InputText value={contact.name} onChange={(e) => { this.contactFieldChange('name', index, e.target.value) }} /></td>
                        <td><i className="material-icons">email</i></td>
                        <td><InputText value={contact.email} onChange={(e) => { this.contactFieldChange('email', index, e.target.value) }} style={{ width: '200px' }} /></td>
                        <td><i className="material-icons">phone</i></td>
                        <td><InputText value={contact.phone} onChange={(e) => { this.contactFieldChange('phone', index, e.target.value) }} /></td>
                        <td>
                          <Button 
                            label={ contact.isSaved ? 'Delete' : 'Save' } 
                            className={ contact.isSaved ? 'ui-button-danger' : 'ui-button-success' } 
                            onClick={() => {
                              if (contact.isSaved) {
                                this.deleteContact(contact.contact_id);
                              } else {
                                this.updateContact(index);
                              }
                            }}
                          /></td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td><i className="material-icons">add</i></td>
                    <td><InputText placeholder="Name" value={this.state.newAlertName} onChange={(e) => {this.setState({newAlertName: e.target.value})}} /></td>
                    <td><i className="material-icons">email</i></td>
                    <td><InputText placeholder="Email" value={this.state.newAlertEmail} onChange={(e) => {this.setState({newAlertEmail: e.target.value})}} style={{ width: '200px' }} /></td>
                    <td><i className="material-icons">phone</i></td>
                    <td><InputText placeholder="Phone" value={this.state.newAlertPhone} onChange={(e) => { this.setState({newAlertPhone: e.target.value})}} /></td>
                    <td><Button label="Add" onClick={this.addNewAlertContact.bind(this)} className="ui-button-success" /></td>
                  </tr>
                </tbody>
              </table>
            }            
          </Card>
          : ''
          */}        
      </div>
      <Dialog
        header={ this.state.selectedContact ? this.state.selectedContact.contact.name : 'Alert Contact' }
        visible={this.state.showContactModal}
        modal={true}
        onHide={() => { this.setState({showContactModal: false}) }}
        dismissableMask={true}
        footer={<div>
          <Button label="Assign" className="ui-button-success" />
        </div>}
      ></Dialog>
      <div id="last" />
    </div>
    );
  }
}

export default compose(
  graphql(GROUPS_QUERY, {
    name: 'groups'
  }),
  graphql(CONTACTS_QUERY, {
    name: 'contacts'
  })
)(withApollo(Alerts));