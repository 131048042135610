import React from 'react';

import { Card } from 'primereact/components/card/Card';
import { Column } from 'primereact/components/column/Column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';

import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

import moment from 'moment-timezone';
import Helpers from '../../libs/Helpers';

class TrailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heartBeatOnly: false,
      trailEvents: [
        {device_type: 'fj1000', event_type: 3},
        {device_type: 'concoxat3', event_type: 35}
      ]
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {
    this.setState({
      heartBeatOnly: props.heartBeatOnly
    });
  }

  eventFilter(device_type, event_type) {
    return ((device_type === this.state.trailEvents[0].device_type && parseInt(event_type) === this.state.trailEvents[0].event_type) ||
      (device_type === this.state.trailEvents[1].device_type && parseInt(event_type) === this.state.trailEvents[1].event_type));
  }

  trailFactory(event, index) {
    const userTimezone = this.props.userTimezone && this.props.userTimezone.length > 0
    ? this.props.userTimezone
    : 'America/Los_Angeles';

    return {
      index: <span style={{ cursor: 'pointer', color: '#2d5594', fontWeight: 'bold' }} onClick={() => { this.props.onSelectEvent(event); } }>{index + 1}</span>,
      time: moment.unix(event.event_data.device_time).tz(userTimezone).format('MM/DD/YYYY h:mm:ss A'),
      event_type: Helpers.cleanConduitEvent(event),//Helpers.cleanEventType(event.device_type, event.event_data.event_type),
      coords: Helpers.cleanLatLong(event.event_data.latitude, event.event_data.longitude),
      speed: event.event_data.speed,
      heading: event.event_data.heading,
      satellites: event.event_data.satellites
    };
  }
  
  renderTable(trail) {
    const trailList = trail.trail.map((event, index) => (event.event_data) ? this.trailFactory(event, index) : null);
    const eventTrailList = trail.trail
            .filter((event) => (this.eventFilter(event.device_type, event.event_data.event_type)))
            .map((event, index) => (event.event_data) ? this.trailFactory(event, index) : null);
    return (
    <React.Fragment>
      <div>{trail.label}</div>
      <DataTable value={(this.state.heartBeatOnly) ? eventTrailList: trailList} paginator={true} rows={5} resizableColumns={true}>
        <Column columnKey="index" field="index" header="#" sortable={true} style={{ textAlign: 'center', width: '60px' }} />
        <Column columnKey="event_type" field="event_type" header="Event" style={{ textAlign: 'center', width: '100px' }} />
        <Column columnKey="time" field="time" header="Time" sortable={true} />
      </DataTable>
    </React.Fragment>
    );
  }  

  render() {
    return this.props.trails && this.props.trails.length > 0
    ? <div style={{ zIndex: 99 }}>
      <div className="card-group-header geofence-list-header" style={{ padding: '10px', borderRadius: 0 }}>List</div>
      <div className="card-group-content" style={{ padding: '5px', margin: 0,  backgroundColor: '#efefef', backgroundImage: 'none' }}>
        <Card className="alert-card-unit clearfix" style={{ marginBottom: '10px', zIndex: 99 }}>            
          <div>
            { this.props.trails.map( (trail ) => this.renderTable(trail)) }
          </div>
        </Card>
      </div>
    </div>
    : null;
  }
}

export default compose()(TrailList);

