/* React */
import React, { Component } from 'react';

/* Prime React */
import { AutoComplete } from 'primereact/components/autocomplete/AutoComplete';
import { Button } from 'primereact/components/button/Button';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { Card } from 'primereact/components/card/Card';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { ListBox } from 'primereact/components/listbox/ListBox';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import {SplitButton} from 'primereact/components/splitbutton/SplitButton';
import { Steps } from 'primereact/components/steps/Steps';

/* GraphQL */
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

/* Misc */
import moment from 'moment';
import Helpers from '../../libs/Helpers';
import ReportTable from './ReportTable'
import { CSVLink } from "react-csv";
import L from 'leaflet';
import ReactExport from "react-data-export";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import Modal from 'react-modal';
import ReactSelect from 'react-select';

import { customStyles } from '../../libs/AppStyle';
import { topTable, REPORTTYPES } from './eventTable';

/* Globals */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DEVICE_TIME_FORMAT = 'UTC:YYYY/MM/DD HH:mm:ss.SSS';
const fancyTime = 'MMM. Do. YYYY h:mm A';
const NOREPORT = 'No Report Data';
const USER_QUERY = gql`
  query {
    user {
      timezone
    }
  }
`;
const USER_HISTORY_QUERY = gql`
  query loginLog ($loginlog_id: Int){
    loginLog(loginlog_id: $loginlog_id) {
      origin
      remote
      createdAt
      user {
        username
        fullname
      }      
    }
  }
`;

const GROUPS_QUERY = gql`
  query {
    groups {
      group_id
      compid
      name
      units {
        unit_id
        label
        serial
        vehicle {
          icon
        }
      }
    }
  }
`;

const reportSteps = [
  { label: 'Report' },
  { label: 'Units' },
  { label: 'Date Range' },
];

const reportTypes = [
  { label: 'History', value: 'history' },
  { label: 'Alert History', value: 'alert' },
  // { label: 'Location', value: 'location' },
  { label: 'User Login History', value: 'user' },
  { label: 'Top Stops', value: 'top' },
  { label: 'Stops', value: 'stop' },
];

class Report extends Component {

  constructor(props) {
    super(props);

    if (props.report && props.report.step && props.report.step == 3) {
      props.report.mapId = 'mapbox.streets'
      this.state = props.report;
      setTimeout(() => { this.runReport(props); }, 500);
    } else {
      this.state = {
        step: 0,
        reportName: 'New Report',
        reportTitle: 'New Report',
        reportType: props.reportType ? props.reportType : '',
        units: props.units ? props.units : [],
        selectedUnits: props.selectedUnits ? props.selectedUnits : [],
        //dates: props.dates ? props.dates : [],
        startDate: new Date(moment().startOf('day').valueOf()),
        endDate: new Date(moment().startOf('day').valueOf()),
        reportData: [],
        ranReport: false,
        firstLoad: true,
        csvData: [],
        configureModalMap: {
          coords: "",
          display: false
        },
        mapId: 'mapbox.streets',
        vehicleSuggestions: [],
        icons: [],
      };
    }
    this.closeModal = this.closeModal.bind(this);

    this.calendarPrefillOptions = [
      { 
        label: 'Today', 
        value: 'today',
        command: (e) => {
          this.setState({
            startDate: new Date(moment().startOf('day').valueOf()),
            endDate: new Date(moment().startOf('day').valueOf()),
          });
        }
      },
      { 
        label: 'Last 7', 
        value: 'last7',
        command: (e) => {
          this.setState({
            startDate: new Date(moment().add(-7, 'd').startOf('day').valueOf()),
            endDate: new Date(moment().startOf('day').valueOf()),
          });
        }
      },
      { 
        label: 'Last 30', 
        value: 'last30',
        command: (e) => {
          this.setState({
            startDate: new Date(moment().add(-30, 'd').startOf('day').valueOf()),
            endDate: new Date(moment().startOf('day').valueOf()),
          });
        }
      },
      { 
        label: 'This month', 
        value: 'month',
        command: (e) => {
          this.setState({
            startDate: new Date(moment().startOf('month').startOf('day').valueOf()),
            endDate: new Date(moment().startOf('day').valueOf()),
          });
        }
      },
    ];
  }

  closeModal() {
    this.setState({ 
      configureModalMap: { display: false },
      mapId: 'mapbox.streets'
    })
  }

  onReportTypeChange(e) {
    //console.log('onReportTypeChange:', e.value);
    this.setState({
      reportType: e.value,
      step: e.value == 'user' ? 2 : 1,
      reportName: reportTypes.reduce((accum, rt) => (rt.value == e.value ? rt.label : accum), 'Unknown') + ' Report',
    }, () => {
      this.props.onSave(this.props.index, this.state);
    });
  }

  onUnitsChange(e) {
    this.setState({ units: e.value, }, () => { this.props.onSave(this.props.index, this.state); });
  }

  onSelectedUnitsChange(e) {
    //console.log('E:', e);
    this.setState({ units: e });
  }

  /*
  onDateRangeChange(e) {
    this.setState({ dates: e.value }, () => { this.props.onSave(this.props.index, this.state); });
  }
  */

  onStartDateChange(e) {
    this.setState({ startDate: e.value }, () => { this.props.onSave(this.props.index, this.state); });
  }

  onEndDateChange(e) {
    this.setState({ endDate: e.value }, () => { this.props.onSave(this.props.index, this.state); });
  }

  saveReport() {
    const reportTitle = reportTypes.reduce((accum, rt) => (rt.value == this.state.reportType ? rt.label : accum), 'Unknown Report')
      + ' Report : '
      + moment(this.state.startDate).format('ddd. MMM. Do YYYY')
      + ' to '
      + moment(this.state.endDate).format('ddd. MMM. Do YYYY');
 
    this.setState({
      step: 3,
      firstLoad: true,
      reportTitle,
      reportData: new Array(this.state.units.length)
    }, () => {
      this.props.onSave(this.props.index, this.state);
    });
  }

  componentDidMount() {
    if ( ((((this.props || {}).groups || {}).groups || [])[0] || {}).units ) {
      //console.log('Calling mapIcons in componentDidMount');
      this.mapIcons(this.props);
    }
  }

  componentWillReceiveProps(props) {
    if ( ((((props || {}).groups || {}).groups || [])[0] || {}).units ) {
      //console.log('Calling mapIcons in componentWillReceiveProps');
      this.mapIcons(props);
    }
  }

  mapIcons(props) {
    //console.log('Reports.js - mapIcons:', props)
    try {
      let icons = [];

      const unitIcons = props.groups.groups[0].units.reduce((accum, unit) => {
        let newObject = Object.assign({}, accum);
        newObject[unit.unit_id] = unit.vehicle && unit.vehicle.icon ? unit.vehicle.icon : 'default';
        return newObject;
      }, {});

      icons = props.groups.groups[0].units.map((unit) => {
        try {
          return {
            unit_id: unit.unit_id,
            icon: (unit.vehicle) ? (unit.vehicle.icon) ? unit.vehicle.icon : 'default' : 'default',            
          }
        } catch (error) { console.log('Icons Error: ', error) }
      });
      //console.log('State Icons: ', icons)
      if (props.report && props.report.step && props.report.step == 3 && !this.state.ranReport && this.state.firstLoad) {
        this.setState({
          ...props.report,
          firstLoad: false,
          icons,
          unitIcons,
        }, () => {
          this.runReport(props);
        });
      }

      if (props.report && props.report.step == 0) {
        this.setState({
          ...props.report,
          firstLoad: false,
          icons,
          unitIcons,
        });
      }
    } catch (error) {
      // console.info(error);
    }
  }

  runReport(props) {
    this.setState({
      ranReport: true,
      step: 3,
    });

    switch (this.state.reportType) {
      case 'history':
        this.createHistoryReport(props);
        break;
      case 'user':
        this.createUserLoginReport(props);
        break;
      case 'location':
        this.createLocationReport(props);
        break;
      case 'top':
        this.createTopStopsReport(props);
        break;
      case 'alert':
        this.createAlertHistoryReport(props);
        break;
      case 'stop':
        this.createStopEventsReport(props);
        break;
      default:
        return ''
    }
  }

  createAlertHistoryReport(props) {
    const userTimezone = this.props.user && this.props.user.user && this.props.user.user.timezone && this.props.user.user.timezone.length > 0
    ? this.props.user.user.timezone
    : 'America/Los_Angeles';

    this.state.units.forEach(async (unit, index) => {
      try {
        const { unit_id } = unit;
        const query = gql`
         query alertlogs($unit_id: Int!, $start: String!, $end: String!) {
           alertlogs(unit_id: $unit_id, start_date: $start, end_date: $end) {
             alertlog_id
             compid
             unit_id
             type
             alert_id
             contact_id
             details
             createdAt
             unit {
               label
             }             
           }
         }`;

        const variables = {
          unit_id,
          start: moment.tz(props.report.startDate, userTimezone).startOf('day').utc().format(DEVICE_TIME_FORMAT),
          end: moment.tz(props.report.endDate, userTimezone).endOf('day').utc().format(DEVICE_TIME_FORMAT)
        };
        const alertHistory = await props.client.query({ query, variables });
        const { data: { alertlogs } } = alertHistory;

        if (alertlogs.length) {
          this.setState({ csvData: this.state.reportData.concat() });
          let reportData = this.state.reportData.concat();
          let newData = alertlogs.map((alertLog, index) => {
            const {
              // alertlog_id,
              // unit: { label }, 
              compid,
              type,
              contact_id, // display contact? 
              details,
              createdAt
            } = alertLog;

            const time = moment(createdAt).format('MM/DD/YYYY h:mm:ss A')
            let label = unit.label
            return {
              index,
              label,
              time,
              compid,
              type,
              contact_id,
              details
            }

          }).filter(element => (typeof (element) != "undefined"));

          newData.sort((A, B) => B.time - A.time);
          reportData.splice(index, 1, newData);
          const filteredReportData = reportData.filter((el) => JSON.stringify(el) !== "[{}]");
          let csvData = this.generateCsv(filteredReportData);
          this.setState({ reportData, csvData }, () => {
            if (this.props.onRun) {
              this.props.onRun({
                index: this.props.index,
                ...this.state
              });
            }
          });
        } else {
          let reportData = this.state.reportData.concat();
          const filteredReportData = reportData.filter((el) => JSON.stringify(el) !== "[{}]");
          let csvData = this.generateCsv(filteredReportData);
          reportData.splice(index, 1, [{}]);
          this.setState({ reportData, csvData });
          if (this.props.onRun) {
            this.props.onRun({
              index: this.props.index,
              ...this.state
            });
          }
        }

      } catch (error) {
        console.info(error);
      }
    });

    // this.setState({ reportData, csvData: reportData }, () => {
    //   if (this.props.onRun) {
    //     this.props.onRun({
    //       ...this.state
    //     });
    //   }
  }

  // TODO: refactor all event based reports to one method that passes in query
  createStopEventsReport(props) {
    const userTimezone = this.props.user && this.props.user.user && this.props.user.user.timezone && this.props.user.user.timezone.length > 0
    ? this.props.user.user.timezone
    : 'America/Los_Angeles';

    this.state.units.forEach((unit, index) => {
      const { unit_id } = unit;
      const query = gql`
      query stopEvents ($unit_id: Int!, $start: String!, $end: String!) {
        stopEvents(unit_id: $unit_id, start_date: $start, end_date: $end)
      }`;
      const variables = {
        unit_id,
        start: moment.tz(props.report.startDate, userTimezone).startOf('day').utc().format(DEVICE_TIME_FORMAT),
        end: moment.tz(props.report.endDate, userTimezone).endOf('day').utc().format(DEVICE_TIME_FORMAT)
      };
      props.client.query({ query, variables })
        .then((res) => {
          this.eventBasedReport(res, index, 'stopEvents', unit_id);
        });
    });
  }

  createTopStopsReport(props) {
    this.state.units.forEach((unit, index) => {
      const { unit_id } = unit;
      const query = gql`
      query topStops ($unit_id: Int!, $start: String!, $end: String!) {
        topStops(unit_id: $unit_id, start_date: $start, end_date: $end)
      }`;
      const variables = {
        unit_id,
        start: moment(props.report.startDate).startOf('day').utc().format(DEVICE_TIME_FORMAT),
        end: moment(props.report.endDate).endOf('day').utc().format(DEVICE_TIME_FORMAT)
      };
      props.client.query({ query, variables })
        .then((res) => {
          this.eventBasedReport(res, index, 'topStops', unit_id);
        });
    });
  }

  createHistoryReport(props) {
    const userTimezone = this.props.user && this.props.user.user && this.props.user.user.timezone && this.props.user.user.timezone.length > 0
    ? this.props.user.user.timezone
    : 'America/Los_Angeles';
    
    try {
      this.state.units.forEach(async (unit, index) => {
        const { unit_id } = unit;

        const query = gql`
        query lastEvents ($unit_id: Int!, $start: String!, $end: String!) {
          lastEvents(unit_id: $unit_id, start_date: $start, end_date: $end)
        }`;

        const variables = {
          unit_id,
          start: moment.tz(props.report.startDate, userTimezone).startOf('day').utc().format(DEVICE_TIME_FORMAT),
          end: moment.tz(props.report.endDate, userTimezone).endOf('day').utc().format(DEVICE_TIME_FORMAT)
        };

        const res = await props.client.query({ query, variables });
        this.eventBasedReport(res, index, 'lastEvents', unit_id);
      });
    } catch (error) {
      console.info(error);
    }

  }

  // TODO: make code more DRY for this report, and location report
  eventBasedReport(res, index, key, unit_id) {
    if (res.data[key].length > 0) {
      this.setState({ csvData: this.state.reportData.concat() });
    }
    if (res.data && res.data[key] && res.data[key].length > 0) {
      let reportData = this.state.reportData.concat();
      let newData = res.data[key].map((event, index) => {

        let coords;
        if (key !== 'topStops') {
          coords = Helpers.cleanLatLong(event.event_data.latitude, event.event_data.longitude)
        } else {
          coords = `${res.data[key][index].event_data.latitude}, ${res.data[key][index].event_data.longitude}`;
        }

        //console.log('CLEANED COORDS:', coords);

        if (event && event.event_data) {
          return {
            index,
            //seq: event.event_data.sequence_number,
            device_time: event.event_data.device_time,
            time: moment.unix(event.event_data.device_time).format(fancyTime),
            event_type: Helpers.cleanConduitEvent(event),
            coords,
            address: event.event_data.address ? event.event_data.address : 'None',
            speed: Helpers.cleanSpeed(event.device_type, event.event_data.speed ? event.event_data.speed : 0),
            heading: Helpers.degreesToDirection(event.event_data.heading_degrees),
            satellites: event.event_data.satellite_count,
            stop_count: (key === 'topStops') ? res.data[key][index].stop_count : '',
            actions: this.setupInteractiveReport(coords, unit_id)
          };
        }
      });
      newData.sort((A, B) => B.device_time - A.device_time);
      
      if(this.state.reportName==='Top Stops Report'){
        newData.sort((A, B) => B.stop_count - A.stop_count);
      }

      reportData.splice(index, 1, newData);

      const filteredReportData = reportData.filter((el) => JSON.stringify(el) !== "[{}]");
      let csvData = this.generateCsv(filteredReportData);
      this.setState({ reportData, csvData }, () => {
        if (this.props.onRun) {
          this.props.onRun({
            index: this.props.index,
            ...this.state
          });
        }
      });
    }
    else {
      let reportData = this.state.reportData.concat();
      const filteredReportData = reportData.filter((el) => JSON.stringify(el) !== "[{}]");
      let csvData = this.generateCsv(filteredReportData);
      reportData.splice(index, 1, [{}]);
      this.setState({ reportData, csvData });
      if (this.props.onRun) {
        this.props.onRun({
          index: this.props.index,
          ...this.state
        });
      }
    }
  }

  createLocationReport(props) {

    this.state.units.forEach(async (unit_id, index) => {
      try {
        const query = gql`
          query lastEvent($unit_id: Int!) {
            lastEvent(unit_id: $unit_id)
          }
        `;

        const variables = { unit_id };

        const res = await props.client.query({ query, variables });

        //console.log('RES:', res);

        const { lastEvent: { event_data, device_type, event_data: { latitude, longitude, device_time, event_type, conduit_event_description, address, speed, heading_degrees, satellite_count } }, lastEvent } = res.data;
        const coords = Helpers.cleanLatLong(latitude, longitude);
        if (lastEvent && event_data) {

          let reportData = this.state.reportData.concat();

          this.setState({ csvData: reportData });

          let newData = {
            device_time,
            time: moment.unix(device_time).format(fancyTime),
            event_type: conduit_event_description ? conduit_event_description : Helpers.cleanEventType(device_type, event_type),
            coords,
            address: address ? address : 'None',
            speed: Helpers.cleanSpeed(device_type, speed ? speed : 0),
            heading: Helpers.degreesToDirection(heading_degrees),
            satellites: satellite_count,
            actions: this.setupInteractiveReport(coords, unit_id)
          };

          reportData.splice(index, 1, [newData]);

          const filteredReportData = reportData.filter(el => { if (el) return el });

          let csvData = this.generateCsv(filteredReportData);

          this.setState({ reportData, csvData }, () => {
            if (this.props.onRun) {
              this.props.onRun({
                index: this.props.index,
                ...this.state
              });
            }
          });


        }


      } catch (error) {
        console.info(error);
      }
    });

  }

  generateCsv(filteredReportData) {
    return [].concat.apply([], filteredReportData).map(item => {
      try {
        if (item && this.state.reportType === 'alert') {
          const { type, label, details, time } = item;
          return { type, details, time, label };
        } else if (item && this.state.reportType === 'top') {
          const { address = "", stop_count = 0 } = item;
          return { address, stop_count };
        } else {

          const { device_time = "", time = "", event_type = "", coords = "", address = "", speed = "", heading = "", satellites = "" } = item;
          return { device_time, time, event_type, coords, address, speed, heading, satellites };
        }
      } catch (error) {
        console.info(error);
      }
    });
  }

  setupInteractiveReport(coords, unit_id) {
    let display = coords == 'NaN,NaN' ? '"disabled"' : '';
    return <React.Fragment>
      <Button label="Map" disabled={ display } className="ui-button-success" onClick={() => this.setState({ configureModalMap: { coords, display: true, unit_id } }, () => console.log('configureModalMap: ', this.state.configureModalMap))} />
    </React.Fragment>;
  }

  async createUserLoginReport() {
    try {
      const userLog = await this.props.loginLog.refetch();
      const { data: { loginLog } } = userLog;
      const reportData = loginLog.map((logInEntry, index) => {
        const { origin, remote, createdAt, user: { username, fullname } } = logInEntry;
        const time = moment(createdAt).format('MM/DD/YYYY h:mm:ss A')
        const startDate = moment(this.state.startDate).format('MM/DD/YYYY h:mm:ss A')
        const endDate = moment(this.state.endDate).format('MM/DD/YYYY h:mm:ss A')
        if (time >= startDate && time <= endDate) {
          return {
            index,
            time,
            origin,
            remote,
            username,
            fullname
          }
        }
      }).filter(element => (typeof (element) != "undefined"));

      this.setState({ reportData, csvData: reportData }, () => {
        if (this.props.onRun) {
          this.props.onRun({
            ...this.state
          });
        }
      });
    } catch (error) {
      console.info(error);
    }
  }

  async defineIcon(unit_id) {
    //console.log('defineIcon - state:', this.state);
    /*
    const selectedIcon = await new Promise((resolve, reject) => {
      for(let i=0; i<this.state.icons.length; i++) {
        const set = this.state.icons[i];
        if (set.unit_id === unit_id) {
          resolve(set.icon);
        }

        if (i >= this.state.icons.length) {
          resolve('default');
        }
      }
    });
    */

    /*
    this.state.icons.forEach((set) => {
      if (set.unit_id === unit_id) {
        selectedIcon = set.icon;
      }
    });
    */
    const selectedIcon = this.state.unitIcons && this.state.unitIcons[unit_id] ? this.state.unitIcons[unit_id] : 'default';

    //console.log('Selected Icon:', selectedIcon);

    let customIcon = new L.Icon.Default();
    if (selectedIcon !== 'default') {      
      customIcon = new L.Icon({
        iconUrl: require(`../../libs/icons/${selectedIcon}`),
        iconRetinaUrl: require(`../../libs/icons/${selectedIcon}`),
      });
    }
    //console.log('Custom Icon:', customIcon);
    return customIcon;
  }

  renderModal() {
    const { state: { configureModalMap: { display, coords, unit_id } } } = this;
    const position = (() => {
      try {
        return coords.split(',');
      } catch (error) {
        console.log('renderModal coords ERROR:', error);
        return [41.850033, -87.6500523];
      }
    })();
    /*
    const customIcon = (() => {
      try {
        return this.defineIcon(unit_id);
      } catch (error) {
        console.log('renderModal icon ERROR:', error);
        return new L.Icon.Default();
      }
    })();
    */
   const customIcon = this.state.unitIcons && this.state.unitIcons[unit_id]
   ? new L.Icon({
      iconUrl: require(`../../libs/icons/${this.state.unitIcons[unit_id]}`),
      iconRetinaUrl: require(`../../libs/icons/${this.state.unitIcons[unit_id]}`),
    })
   : new L.Icon.Default();

    //console.log('Coords:', coords);
    //console.log('Position:', position);
    //console.log('Icon:', customIcon);

    /*
    let position, customIcon;    
    try {
      position = coords.split(',');
      customIcon = this.defineIcon(unit_id);
    } catch (error) {
      //console.log('renderModal ERROR:', error);
      // set overlay to handle leaflet null issue
      position = [41.850033, -87.6500523];
      customIcon = new L.Icon.Default();
    }
    */

    return (
      /*
      <Dialog
          visible={display}
          onHide={this.closeModal}
          modal={true}
          appendTo={document.getElementById('app-footer')}
          closable={false}
        >
      */
      <Modal
        isOpen={display}
        onRequestClose={this.closeModal}
        style={customStyles} contentLabel={`coords: ${coords}`} >
      
        <div style={{float: 'right', marginBottom: '10px'}}>
          <Button
            label="Street"
            style={{
              backgroundColor: this.state.mapId == 'mapbox.streets' ? '#51ce9e' : '#cccccc'
            }}
            onClick={() => {this.setState({mapId: 'mapbox.streets'})}}
          />
          <Button
            label="Satellite"
            style={{
              backgroundColor: this.state.mapId == 'mapbox.streets-satellite' ? '#51ce9e' : '#cccccc'
            }}
            onClick={() => {this.setState({mapId: 'mapbox.streets-satellite'})}}
          />
        </div>
        
        <Map
            center={position}
            zoom={15}
            style={{ width: '100%', height: '85%' }}
            scrollWheelZoom={false}
            dragging={ !L.Browser.mobile }
          >
            
            <TileLayer
              //url={'https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoiZ3BzbGVhZGVycyIsImEiOiJjamw4aXhmNXIwMHVmM3BydW04cmJ5cXE0In0.mLNavvJJLhaP6DANzEyatw'}
              url={'https://api.tiles.mapbox.com/v4/' + this.state.mapId + '/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoiZ3BzbGVhZGVycyIsImEiOiJjamw4aXhmNXIwMHVmM3BydW04cmJ5cXE0In0.mLNavvJJLhaP6DANzEyatw'}
              attribution=""
            />
            {
              customIcon && position
              ? <Marker icon={customIcon} position={position}>
                <Popup>{`${coords}`}</Popup>
              </Marker>
              : ''
            }            
          </Map>
        
        <Button style={{ float: 'right', marginTop: '10px'}} label='Close' className="ui-button-success" onClick={this.closeModal} />
      { /* </Dialog> */ }
      </Modal>
    );
  }

  getExtensibleObject(obj) {
    return Object.assign({}, obj);
  }

  suggestAllVehicles() {
    setTimeout(() => {
      this.setState({
        vehicleSuggestions: []
      }, () => {
        this.setState({
          vehicleSuggestions: this.props.groups && this.props.groups.groups && this.props.groups.groups.length > 0
            ? this.props.groups.groups
              .reduce((accum, group) => (accum.concat(group.units)), [])
              .map((unit) => ({ label: unit.label, unit_id: unit.unit_id, serial: unit.serial }))
              //.filter((unit) => (unit.label.toLowerCase().includes(e.query.toLowerCase()) || unit.serial.toLowerCase().includes(e.query.toLowerCase())))
              .map((unit) => (this.getExtensibleObject(unit)))
            : []
        }, () => {
          //console.log('Suggestions:', this.state.vehicleSuggestions);
        });
      });      
    }, 100);    
  }

  suggestVehicles(e) {
    if (e.query.length > 0) {
      this.setState({
        vehicleSuggestions: this.props.groups && this.props.groups.groups && this.props.groups.groups.length > 0
          ? this.props.groups.groups
            .reduce((accum, group) => (accum.concat(group.units)), [])
            .map((unit) => ({ label: unit.label, unit_id: unit.unit_id, serial: unit.serial }))
            .filter((unit) => (unit.label.toLowerCase().includes(e.query.toLowerCase()) || unit.serial.toLowerCase().includes(e.query.toLowerCase())))
            .map((unit) => (this.getExtensibleObject(unit)))
          : []
      }, () => {
        //console.log('Suggestions:', this.state.vehicleSuggestions);
      });
    } else {
      //console.log('HERE');
    }
  }

  renderUnitIfNeeded(disabled) {
    if (this.state.reportType !== 'user') {
      return (
        <React.Fragment>
          <div
            className="ui-g-3 clearfix" 
            onClick={() => {
              if (disabled) {
                this.setState({
                  step: 1
                });
              }
            }}
          >
            <ReactSelect
              ref={this.reactSelect}
              isMulti={true}
              isSearchable={true}
              value={this.state.units}
              options={
                this.props.groups && this.props.groups.groups && this.props.groups.groups.length > 0
                ? this.props.groups.groups
                  .reduce((accum, group) => (accum.concat(group.units)), [])
                  .map((unit) => ({ label: unit.label, value: unit.unit_id, unit_id: unit.unit_id, serial: unit.serial }))                
                : []
              }
              placeholder="Choose units"
              onChange={this.onSelectedUnitsChange.bind(this)}
              closeMenuOnSelect={false}
              isDisabled={disabled}            
            />
            {/*
            <AutoComplete
              field="label"
              multiple={true}
              placeholder="Select Vehicles"
              suggestions={this.state.vehicleSuggestions}
              minLength={0}
              value={this.state.units}
              onChange={this.onUnitsChange.bind(this)}
              completeMethod={this.suggestVehicles.bind(this)}
              dropdown={true}            
              style={{ width: '100%' }}
              inputStyle={{ width: '100%' }}            
            />
            */}
          </div>
          {
            this.state.step == 1
            ? <div className="ui-g-3 clearfix">
              <Button
                label="Next"
                className="ui-button-success"
                style={{ width: '50%' }}
                onClick={() => {
                  this.setState({ step: 2 }, () => {
                    this.props.onSave(this.props.index, this.state);
                  });
                }}
                disabled={this.state.units.length < 1}
              />
            </div>
            : ''
          }
        </React.Fragment>
      );
    } else {
      //this.setState({ step: 2 }); // This will cause an infinite loop
    }
  }

  renderReportBasedOnType() {
    // TODO: change displayHistoryReport to displayMultipleUnitReport
    switch (this.state.reportType) {
      case 'user':
        return this.displayUserLoginReport();
      default:
        return this.displayHistoryReport();
    }

  }

  displayUserLoginReport() {
    return (this.state.reportData.length > 0) ?
      <ReportTable data={this.state.reportData} reportType={this.state.reportName} /> :
      <ProgressSpinner />
  }

  displayHistoryReport() {
    if (this.state.reportData.length > 0) {
      return this.state.reportData.map((data, index) => {
        return data && data.length > 0
          ? <div style={{ marginBottom: '15px' }}>
            <div className="card-group-header" style={{ fontSize: '16px', fontWeight: 'bold', }}>
              {
                this.props.groups && this.props.groups.groups
                  ? this.props.groups.groups
                    .reduce((accum, group) => (accum.concat(group.units)), [])
                    .reduce((accum, unit) => (unit.unit_id == this.state.units[index].unit_id ? unit.label : accum), 'Unknown Unit')
                  : 'Unknown Unit'
              }
            </div>
            {data.length > 0 ? <ReportTable data={data} reportType={this.state.reportName} /> : 'No Data'}
          </div> : <ProgressSpinner />
      })
    }
  }

  exportToExcel(fileName) {
    const { reportType = '' } = this.state;
    const reportNameCaps = reportType.toUpperCase();
    const reportToDisplay = REPORTTYPES.reduce((accum, report) => (reportNameCaps.includes(report.name) ? report : accum));

    const columns = (reportNameCaps === 'TOP STOPS REPORT' || reportNameCaps === 'TOP' ) ? topTable.columns : reportToDisplay.columns;
    let tempColumns = [...columns];
    tempColumns.pop();
  

    return (
      <ExcelFile filename={fileName} element={
        <button
          style={{ backgroundColor: '#51ce9e', cursor: 'pointer', borderRadius: '4px', color: 'white', border: 'none', float: "right", marginRight: '10px'  }}>
          <img style={{ height: '20px', width: '20px' }} srcSet="assets/layout/images/materialExcel.png" />
          <b>Export to .xlsx</b>
        </button>             
      }>
        <ExcelSheet data={ this.state.csvData } name={fileName}>
          {tempColumns.map((column, index) => <ExcelColumn key={index} label={column.header} value={column.columnKey} />)}
        </ExcelSheet>
      </ExcelFile>
    )
  }


  render() {
    //console.log('Report Step:', this.state.step);

    let content = <ProgressSpinner />;

    if (this.state.step == 0) {

      content = (
        <div className="ui-g-3 clearfix">
          <Dropdown
            placeholder="Choose Report"
            options={reportTypes}
            value={this.state.reportType}
            onChange={this.onReportTypeChange.bind(this)}
            style={{ width: '200px' }}
          />
        </div>
      );

    } else if (this.state.step == 1) {

      content = (
        <React.Fragment>
          <div className="ui-g-3 clearfix" onClick={() => { this.setState({ step: 0, reportType: '' }) }}>
            <Dropdown
              placeholder="Choose Report"
              options={reportTypes}
              value={this.state.reportType}
              onChange={this.onReportTypeChange.bind(this)}
              style={{ width: '100%' }}
              disabled={true}
            />
          </div>
          {this.renderUnitIfNeeded(false)}
        </React.Fragment>
      );

    } else if (this.state.step == 2) {

      content = (
        <React.Fragment>
          <div className="ui-g-3 clearfix" onClick={() => { this.setState({ step: 0, reportType: '' }) }}>
            <Dropdown
              placeholder="Choose Report"
              options={reportTypes}
              value={this.state.reportType}
              onChange={this.onReportTypeChange.bind(this)}
              style={{ width: '100%' }}
              disabled={true}
            />
          </div>
          {this.renderUnitIfNeeded(true)}
          {
            /*
            this.state.reportType !== 'user' && (
              <div className="ui-g-3 clearfix" onClick={() => { this.setState({ step: 1 }) }}>
                <ListBox
                  multiple={true}
                  options={
                    this.props.groups && this.props.groups.groups && this.props.groups.groups.length > 0
                      ? this.props.groups.groups
                        .reduce((accum, group) => (accum.concat(group.units)), [])
                        .map((unit) => ({ label: unit.label, value: unit.unit_id }))
                      : [{ label: 'No Units', value: '' }]
                  }
                  value={this.state.units}
                  onChange={this.onUnitsChange.bind(this)}
                  style={{ width: '100%', maxHeight: '200px' }}
                  disabled={true}
                />
              </div>
            )
            */
          }
          { /*<div className="ui-g-6 clearfix" style={{ width: '200px'}}>*/}
            {/* TODO: refactor here remove report names and set up something that says date not needed, and unit not needed */}
            {
              (this.state.reportType !== 'location') ? (
                <React.Fragment>
                    <div className="ui-g-3 clearfix">
                      <div>
                        <Calendar
                          dateFormat="mm/dd/y"
                          placeholder="Start Date"
                          value={this.state.startDate}
                          onChange={this.onStartDateChange.bind(this)}
                          style={{ width: '100%', zIndex: 999, }}
                          inputStyle={{ textAlign: 'center', width: '100%', paddding: '10px' }}
                          hideOnDateTimeSelect={true}
                        />
                      </div>
                      <div style={{ paddingLeft: '50%', paddingRight: '50%'}}>To</div>
                      <div>
                        <Calendar
                          dateFormat="mm/dd/y"
                          placeholder="End Date"
                          value={this.state.endDate}
                          onChange={this.onEndDateChange.bind(this)}
                          style={{ width: '100%', zIndex: 999, }}
                          inputStyle={{ textAlign: 'center', width: '100%', paddding: '10px' }}
                          hideOnDateTimeSelect={true}
                        />
                      </div>
                    </div>
                    <div className="ui-g-3 clearfix">
                      <div>
                        <SplitButton 
                          className="ui-button-success"
                          label="Run"
                          model={this.calendarPrefillOptions}
                          onClick={this.saveReport.bind(this)} 
                        />
                      </div>
                      {/*
                      <div>
                        <Button label="Run" style={{ width: '50%', marginTop: '5px' }} className="ui-button-success"
                          onClick={this.saveReport.bind(this)} disabled={!(
                            this.state.startDate &&
                            this.state.endDate &&
                            this.state.startDate <= this.state.endDate &&
                            this.state.startDate.toString().length > 1 &&
                            this.state.endDate.toString().length > 1
                          )}
                        />
                      </div>
                      */}
                    </div>
                </React.Fragment>
              ) : <Button label="Run" style={{ width: '25%' }} className="ui-button-success" onClick={this.saveReport.bind(this)} />
            }
          {/*</div>*/}
        </React.Fragment>
      );

    } else if (this.state.step == 3) {
      content = this.renderReportBasedOnType();
    }

    let csvData = false;
    if (this.state.csvData !== undefined && this.state.csvData[0] !== undefined) {
      if (this.state.csvData.every((currentValue) => currentValue !== null)) {
        csvData = this.state.csvData;
        // transform event types from object to strings for csv
        csvData.forEach((data) => (typeof (data.event_type) == 'object') ? data.event_type = data.event_type.props.children.props.children : data.event_type);
      }
    }

    return (
      <Card
        className="alert-card clearfix"
        header={
          <React.Fragment>
            <div className="card-header1 clearfix" style={{ padding: '5px' }}>
                <span style={{ verticalAlign: 'middle', lineHeight: '2em' }}><b>{this.state.reportTitle}</b></span>
                {
                  (csvData !== false && this.state.step === 3) ?
                    <CSVLink
                      style={{ color: 'white', cursor: 'pointer'}}
                      data={this.state.csvData}
                      filename={`${this.state.reportTitle}.csv`}>
                      <Button 
                        style={{ float: 'right', fontSize: '.8em'}} 
                        label='Export'
                        className="ui-button-success" 
                      />
                      { /*
                      <button
                        style={{ backgroundColor: '#5cb85c', cursor: 'pointer', borderRadius: '4px', color: 'white', border: 'none', float: "right" }}>
                        <img style={{ height: '20px', width: '20px' }} srcSet="assets/layout/images/materialExcel.png" />
                        <span style={{ verticalAlign: 'middle' }}><b>Export to .csv</b></span>
                      </button>
                      */ }
                    </CSVLink>
                  : ''
                }
            </div>
          </React.Fragment>
        }>
        {this.state.steps < 3 ? <Steps model={reportSteps} activeIndex={this.state.step} /> : ''}
        {(this.state.configureModalMap) ? this.renderModal() : ''}
        {content}
      </Card>
    )
  }
}

export default compose(
  graphql(USER_QUERY, {
    name: 'user'
  }),
  graphql(GROUPS_QUERY, {
    name: 'groups'
  }),
  graphql(USER_HISTORY_QUERY, {
    name: 'loginLog',
    options: (props) => ({
      variables: props
    })
  }),
)(withApollo(Report));