import React, { Component } from 'react';
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Password } from 'primereact/components/password/Password';
import { Messages } from 'primereact/components/messages/Messages';
import { Message } from 'primereact/components/message/Message';
import sha1 from 'sha1';
import gql from 'graphql-tag';

export default class AppLogin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    }
  }

  onUsernameChange(e) {
    this.setState({
      username: e.target.value
    });
  }

  onPasswordChange(e) {
    this.setState({
      password: e.target.value
    })
  }

  doLogin() {
    const mutation = gql`
      mutation login($username: String!, $password: String!) {
        login (username: $username, password: $password)
      }
    `;
    const variables = {
      username: this.state.username,
      password: sha1(this.state.password)
    };

    this.props.client.mutate({
      mutation,
      variables
    }).then((res) => {
      //console.log(res);
      localStorage.setItem('GPSLeadersTrackingAuthToken', res.data.login)
      this.props.onLogin();
    }).catch((err) => {
      //alert('Error: ', err);
      if (this.messages) {
        this.messages.show({
          severity: 'error',
          summary: 'Error logging in.'
        });
      }
    });
  }

  render() {

    return <div>
      {/*<div style={{ backgroundColor: '#4385b4', padding: '10px', height: '50px' }} />*/}
      <div className="card login-panel ui-fluid" style={{background: '#FCFEFF', width: '400px'}}>
        <div className="ui-g">
          <div className="ui-g-12">
            <img srcSet="assets/layout/images/gpsl-login-logo.jpg 1x, assets/layout/images/gpsl-login-logo@2x.jpg 2x" />
          </div>
          <div className="ui-g-12">
            <span className="md-inputfield">
              <InputText
                value={this.state.username}
                onChange={this.onUsernameChange.bind(this)}
                style={{ fontSize: '18px', padding: '10px', color: '#555555' }}
              />
              <label>Username</label>
            </span>
          </div>
          <div className="ui-g-12">
            <span className="md-inputfield">
              <Password
                value={this.state.password}
                onChange={this.onPasswordChange.bind(this)}
                style={{ fontSize: '18px', padding: '10px', color: '#555555' }}
              />
              <label>Password</label>
            </span>
          </div>
          <div className="ui-g-12">
            <Button label={<span style={{ fontSize: '20px', fontWeight: 'bold' }}>Login</span>} className="green-btn" onClick={this.doLogin.bind(this)} />
            {/*<Button label="Forgot Password" />*/}
          </div>
          <div className="ui-g-12">
            <Messages ref={el => this.messages = el}></Messages>
          </div>
        </div>
      </div>
      {/*<div className="login-footer">GPS Leaders, LLC</div>*/}
    </div>;    
  }
}