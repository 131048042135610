
import React, { Component } from 'react';
import { CarService } from '../service/CarService';
import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { InputText } from 'primereact/components/inputtext/InputText';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Schedule } from 'primereact/components/schedule/Schedule';
import { Chart } from 'primereact/components/chart/Chart';

export default class Dashboard extends Component {

  constructor() {
    super();
    this.state = {
      tasks: [],
      city: null,
      chartData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: '#4bc0c0'
          },
          {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            borderColor: '#565656'
          }
        ]
      }
    };
    this.onTaskChange = this.onTaskChange.bind(this);
    this.onCityChange = this.onCityChange.bind(this);
    this.carservice = new CarService();
  }

  onTaskChange(e) {
    let selectedTasks = [...this.state.tasks];
    if (e.checked)
      selectedTasks.push(e.value);
    else
      selectedTasks.splice(selectedTasks.indexOf(e.value), 1);

    this.setState({ tasks: selectedTasks });
  }

  onCityChange(e) {
    this.setState({ city: e.value });
  }

  componentDidMount() {
    this.carservice.getCarsSmall().then(data => this.setState({ cars: data }));
  }

  render()  {
    let cities = [
      { label: 'New York', value: { id: 1, name: 'New York', code: 'NY' } },
      { label: 'Rome', value: { id: 2, name: 'Rome', code: 'RM' } },
      { label: 'London', value: { id: 3, name: 'London', code: 'LDN' } },
      { label: 'Istanbul', value: { id: 4, name: 'Istanbul', code: 'IST' } },
      { label: 'Paris', value: { id: 5, name: 'Paris', code: 'PRS' } }
    ];

    let scheduleHeader = {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay'
    };

    let events = [
      {
        "id": 1,
        "title": "All Day Event",
        "start": "2017-02-01"
      },
      {
        "id": 2,
        "title": "Long Event",
        "start": "2017-02-07",
        "end": "2017-02-10"
      },
      {
        "id": 3,
        "title": "Repeating Event",
        "start": "2017-02-09T16:00:00"
      },
      {
        "id": 4,
        "title": "Repeating Event",
        "start": "2017-02-16T16:00:00"
      },
      {
        "id": 5,
        "title": "Conference",
        "start": "2017-02-11",
        "end": "2017-02-13"
      },
      {
        "id": 6,
        "title": "Meeting",
        "start": "2017-02-12T10:30:00",
        "end": "2017-02-12T12:30:00"
      },
      {
        "id": 7,
        "title": "Lunch",
        "start": "2017-02-12T12:00:00"
      },
      {
        "id": 8,
        "title": "Meeting",
        "start": "2017-02-12T14:30:00"
      },
      {
        "id": 9,
        "title": "Happy Hour",
        "start": "2017-02-12T17:30:00"
      },
      {
        "id": 10,
        "title": "Dinner",
        "start": "2017-02-12T20:00:00"
      },
      {
        "id": 11,
        "title": "Birthday Party",
        "start": "2017-02-13T07:00:00"
      },
      {
        "id": 12,
        "title": "Click for Google",
        "url": "http://google.com/",
        "start": "2017-02-28"
      }
    ];

    return <div className="ui-g dashboard">
      <div className="ui-g-12 ui-md-6">
        <div className="card overview">
          <div className="overview-content clearfix">
            <span className="overview-title">Alerts</span>
            <span className="overview-badge">+%25</span>
            <span className="overview-detail">50 / hour</span>
          </div>
          <div className="overview-footer">
            <img src="assets/layout/images/dashboard/sales.svg" style={{ width: '100%' }} alt="Sales" />
          </div>
        </div>
      </div>
      <div className="ui-g-12 ui-md-6">
        <div className="card overview">
          <div className="overview-content clearfix">
            <span className="overview-title">Events</span>
            <span className="overview-badge">+%50</span>
            <span className="overview-detail">70 / hour</span>
          </div>
          <div className="overview-footer">
            <img src="assets/layout/images/dashboard/views.svg" style={{ width: '100%' }} alt="Views" />
          </div>
        </div>
      </div>
      {/*
      <div className="ui-g-12 ui-md-4">
        <div className="card overview">
          <div className="overview-content clearfix">
            <span className="overview-title">Locates</span>
            <span className="overview-badge">50%</span>
            <span className="overview-detail">50/100 used</span>
          </div>
          <div className="overview-footer">
            <img src="assets/layout/images/dashboard/progress.svg" style={{ width: '100%' }} alt="Progress" />
          </div>
        </div>
      </div>
      */}
      <div className="ui-g-12 ui-md-6 ui-lg-3">
        <div className="ui-g card colorbox colorbox-1">
          <div className="ui-g-4">
            <i className="material-icons">directions_car</i>
          </div>
          <div className="ui-g-8">
            <span className="colorbox-name">Vehicles</span>
            <span className="colorbox-count">2</span>
          </div>
        </div>
      </div>
      <div className="ui-g-12 ui-md-6 ui-lg-3">
        <div className="ui-g card colorbox colorbox-2">
          <div className="ui-g-4">
            <i className="material-icons">shopping_card</i>
          </div>
          <div className="ui-g-8">
            <span className="colorbox-name">Purchases</span>
            <span className="colorbox-count">1200</span>
          </div>
        </div>
      </div>
      <div className="ui-g-12 ui-md-6 ui-lg-3">
        <div className="ui-g card colorbox colorbox-3">
          <div className="ui-g-4">
            <i className="material-icons">report</i>
          </div>
          <div className="ui-g-8">
            <span className="colorbox-name">Issues</span>
            <span className="colorbox-count">22</span>
          </div>
        </div>
      </div>
      <div className="ui-g-12 ui-md-6 ui-lg-3">
        <div className="ui-g card colorbox colorbox-4">
          <div className="ui-g-4">
            <i className="material-icons">email</i>
          </div>
          <div className="ui-g-8">
            <span className="colorbox-name">Messages</span>
            <span className="colorbox-count">10</span>
          </div>
        </div>
      </div>      

      <div className="ui-g-12 ui-md-4">
        <div className="card timeline ui-fluid">
          <div className="ui-g">
            <div className="ui-g-3">
              <span className="event-time">just now</span>
              <i className="material-icons" style={{ color: '#009688' }}>&#xE1BC;</i>
            </div>
            <div className="ui-g-9">
              <span className="event-owner" style={{ color: '#009688' }}>Katherine May</span>
              <span className="event-text">Lorem ipsun dolor amet</span>
              <div className="event-content">
                <img src="assets/layout/images/dashboard/md.png" alt="md" />
              </div>
            </div>

            <div className="ui-g-3">
              <span className="event-time">12h ago</span>
              <i className="material-icons" style={{ color: '#E91E63' }}>&#xE885;</i>
            </div>
            <div className="ui-g-9">
              <span className="event-owner" style={{ color: '#E91E63' }}>Brandon Santos</span>
              <span className="event-text">Ab nobis, magnam sunt eum. Laudantium, repudiandae, similique!.</span>
            </div>

            <div className="ui-g-3">
              <span className="event-time">15h ago</span>
              <i className="material-icons" style={{ color: '#9c27b0' }}>&#xE0C9;</i>
            </div>
            <div className="ui-g-9">
              <span className="event-owner" style={{ color: '#9c27b0' }}>Stephan Ward</span>
              <span className="event-text">Omnis veniam quibusdam ratione est repellat qui nam quisquam ab mollitia dolores ullam voluptates, similique, dignissimos.</span>
            </div>

            <div className="ui-g-3">
              <span className="event-time">2d ago</span>
              <i className="material-icons" style={{ color: '#ff9800' }}>&#xE0C8;</i>
            </div>
            <div className="ui-g-9">
              <span className="event-owner" style={{ color: '#ff9800' }}>Jason Smith</span>
              <span className="event-text">Laudantium, repudiandae, similique!</span>
              <div className="event-content">
                <img src="assets/layout/images/dashboard/map.png" alt="map" />
              </div>
            </div>

            <div className="ui-g-3">
              <span className="event-time">1w ago</span>
              <i className="material-icons" style={{ color: '#607d8b' }}>&#xE91B;</i>
            </div>
            <div className="ui-g-9">
              <span className="event-owner">Kevin Cox</span>
              <span className="event-text">Quibusdam ratione est repellat qui nam quisquam veniam quibusdam ratione.</span>
            </div>

            <div className="ui-g-3">
              <span className="event-time">2w ago</span>
              <i className="material-icons" style={{ color: '#FFC107' }}>&#xE23A;</i>
            </div>
            <div className="ui-g-9">
              <span className="event-owner" style={{ color: '#FFC107' }}>Walter White</span>
              <span className="event-text">I am the one who knocks!</span>
            </div>

            <div className="ui-g-12">
              <Button type="button" label="Refresh" icon="ui-icon-refresh"></Button>
            </div>
          </div>
        </div>
      </div>

      <div className="ui-g-12 ui-md-8">
        <div className="card">
          <DataTable value={this.state.cars} header="Recent Sales" style={{ marginBottom: '20px' }}>
            <Column field="vin" header="Vin" />
            <Column field="year" header="Year" />
            <Column field="brand" header="Brand" />
            <Column field="color" header="Color" />
          </DataTable>

          <Panel header="Sales Graph">
            <Chart type="line" data={this.state.chartData} />
          </Panel>
        </div>
      </div>
    </div>
  }
}