import React, { Component } from 'react';

export class AppFooter extends Component {

  render() {
    return <div id="app-footer">
      {/*
      <div className="card clearfix">
        <span className="footer-text-left">GPS Leaders, LLC</span>
        <span className="footer-text-right"><span className="ui-icon ui-icon-copyright"></span></span>
      </div>
      */}
    </div>
  }
}