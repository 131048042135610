import React, { Component } from 'react';

import { Button } from 'primereact/components/button/Button';
import { Card } from 'primereact/components/card/Card';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { Fieldset } from 'primereact/components/fieldset/Fieldset';
import { InputMask } from 'primereact/components/inputmask/InputMask';
import { InputText } from 'primereact/components/inputtext/InputText';
import { InputSwitch } from 'primereact/components/inputswitch/InputSwitch';
import { OverlayPanel } from 'primereact/components/overlaypanel/OverlayPanel';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';

import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

class AlertContacts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      contacts: [],
      reloadContacts: true
    }
  }

  componentWillReceiveProps(props) {
    if (props.contacts && props.contacts.contacts && props.contacts.contacts.length > 0 && this.state.reloadContacts) {
      this.mapContacts(props.contacts.contacts);
    }
  }

  componentDidMount() {
    if (this.props.contacts && this.props.contacts.contacts && this.props.contacts.contacts.length > 0) {
      this.mapContacts(this.props.contacts.contacts);
    }
  }

  mapContacts(contacts) {
    this.setState({
      reloadContacts: false,
      contacts: contacts.map((contact) => {
        return Object.assign({}, contact, {
          changed: false,
          showConfirmDelete: false
        });
      })
    });
  }

  onNameChange(e) {
    this.setState({
      name: e.target.value
    })
  }

  onEmailChange(e) {
    this.setState({
      email: e.target.value
    })
  }

  onPhoneChange(e) {
    this.setState({
      phone: e.target.value
    })
  }

  addContact() {
    const mutation = gql`
    mutation addContact($name: String!, $email: String!, $phone: String!) {
      addContact(name: $name, email: $email, phone: $phone)
    }`;
    const variables = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone
    };
    this.props.client.mutate({
      mutation,
      variables
    }).then((res) => {
      this.setState({
        reloadContacts: true,
        name: '',
        email: '',
        phone: ''
      }, () => {
        if (this.props.onAddContact) {
          this.props.onAddContact();
        }
      });      
    });
  }

  contactFieldChanged(e, index, field) {
    //console.log('E:', e);
    let contacts = this.state.contacts.concat();
    let contact = contacts[index];
    contact[field] = e.target.value;
    contact['changed'] = true;
    contacts.splice(index, 1, contact);
    this.setState({
      contacts
    });
  }

  saveContact(index) {
    let contacts = this.state.contacts.concat();
    let contact = contacts[index];
    contact['changed'] = false;
    contacts.splice(index, 1, contact);
    this.setState({
      contacts
    });

    const mutation = gql`
    mutation updateContact($contact_id: Int!, $name: String!, $email: String!, $phone: String!) {
      updateContact(contact_id: $contact_id, name: $name, email: $email, phone: $phone)
    }`;
    this.props.client.mutate({
      mutation,
      variables: contact
    }).then((res) => {
      if (this.props.onAddContact) {
        this.props.onAddContact();
      }
    });
  }

  showConfirmDelete(index) {
    let contacts = this.state.contacts.concat();
    let contact = contacts[index];
    contact['showConfirmDelete'] = true;
    contacts.splice(index, 1, contact);
    this.setState({
      contacts
    });
  }

  cancelDelete(index) {
    let contacts = this.state.contacts.concat();
    let contact = contacts[index];
    contact['showConfirmDelete'] = false;
    contacts.splice(index, 1, contact);
    this.setState({
      contacts
    });
  }

  deleteContact(contact_id) {
    const mutation = gql`
    mutation deleteContact($contact_id: Int!) {
      deleteContact(contact_id: $contact_id)
    }`;
    this.props.client.mutate({
      mutation,
      variables: {
        contact_id
      }
    }).then((res) => {
      this.setState({
        reloadContacts: true
      }, () => {
        if (this.props.onAddContact) {
          this.props.onAddContact();
        }
      });        
    })
  }

  render() {
    return <div className="clearfix">
      <div className="card-group-header geofence-list-header" style={{ padding: '10px', borderRadius: 0 }}>Contacts</div>
      <div className="card-group-content" style={{ padding: '5px', paddingRight: '10px', backgroundColor: '#efefef', backgroundImage: 'none' }}>
        <Card className="alert-card-unit clearfix" style={{ marginBottom: '10px' }}>
          <div className="ui-g-12 contact-form">
            <div className="ui-g-1"><i className="material-icons">contact_mail</i></div>
            <div className="ui-g-11"><InputText value={this.state.name} style={{ width: '100%' }} placeholder="Name" onChange={this.onNameChange.bind(this)} /></div>
          </div>
          <div className="ui-g-12 contact-form">
            <div className="ui-g-1"><i className="material-icons">email</i></div>
            <div className="ui-g-11"><InputText value={this.state.email} style={{ width: '100%' }} placeholder="Email" onChange={this.onEmailChange.bind(this)} /></div>
          </div>
          <div className="ui-g-12 contact-form">
            <div className="ui-g-1"><i className="material-icons">phone</i></div>
            <div className="ui-g-11"><InputText value={this.state.phone} style={{ width: '100%' }} placeholder="Phone" onChange={this.onPhoneChange.bind(this)} /></div>
          </div>
          <div className="ui-g-12 contact-form">
            <div className="ui-g-1"><i className="material-icons">touch_app</i></div>
            <div className="ui-g-11">
              <Button
                className="ui-button-success"
                label="Add"
                icon="fa fa-plus"
                disabled={ this.state.name.length > 0 && (this.state.email.length > 0 || this.state.phone.length > 0) ? false : true } onClick={this.addContact.bind(this)}
              />
            </div>
          </div>          
        </Card>
        {
          this.state.contacts.length > 0
          ? this.state.contacts.map((contact, index) => {
            return <Card className="alert-card-unit clearfix" style={{ marginBottom: '10px' }}>
              <div className="ui-g-12 contact-form">
                <div className="ui-g-1"><i className="material-icons">contact_mail</i></div>
                <div className="ui-g-11"><InputText value={contact.name} style={{ width: '100%' }} onChange={(e) => {this.contactFieldChanged(e, index, 'name')}} /></div>
              </div>
              <div className="ui-g-12 contact-form">
                <div className="ui-g-1"><i className="material-icons">email</i></div>
                <div className="ui-g-11"><InputText value={contact.email} style={{ width: '100%' }} onChange={(e) => {this.contactFieldChanged(e, index, 'email')}} /></div>
              </div>
              <div className="ui-g-12 contact-form">
                <div className="ui-g-1"><i className="material-icons">phone</i></div>
                <div className="ui-g-11"><InputText value={contact.phone} style={{ width: '100%' }} onChange={(e) => {this.contactFieldChanged(e, index, 'phone')}} /></div>
              </div>
              <div className="ui-g-12 contact-form">
                <div className="ui-g-1"><i className="material-icons">touch_app</i></div>
                <div className="ui-g-11">
                  <Button
                    className={contact.changed ? 'ui-button-success' : 'ui-button-danger'}
                    label={contact.changed ? 'Save' : 'Delete'}
                    icon={contact.changed ? 'fa fa-save' : 'fa fa-trash'}
                    onClick={() => {
                      if (contact.changed) {
                        this.saveContact(index);
                      } else {
                        this.showConfirmDelete(index);
                      }
                    }}
                    disabled={contact.showConfirmDelete ? true : false}
                  />
                  {
                    contact.showConfirmDelete
                    ? <React.Fragment>
                      <Button
                        className="ui-button-success"
                        icon="fa fa-check"
                        label="Are you sure?"
                        onClick={() => {this.deleteContact(contact.contact_id)}}
                      />
                      <Button
                        className="ui-button-warning"
                        icon="fa fa-times"
                        label="No"
                        onClick={() => {this.cancelDelete(index)}}
                      />
                    </React.Fragment>
                    : ''
                  }
                </div>
              </div>
            </Card>;
            })
          : 'No Contacts'
        }
      </div>
    </div>
  }
}

export default withApollo(AlertContacts);