import React, { Component } from 'react';

import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';

import GeofenceMapSmall from './GeofenceMapSmall';

const GEOFENCES_QUERY = gql`query {
  boundaries {
    boundary_id
    type
    name
    latitude
    longitude
    radius    
    points {
      type
      coordinates {
        latitude
        longitude
      }
    }  }
}`;

class SelectGeofence extends Component {
  render() {
    return <ScrollPanel style={{ padding: 0, border: 0 }}>
      {
        this.props.geofences && this.props.geofences.boundaries && this.props.geofences.boundaries.length > 0
        ? this.props.geofences.boundaries.map((geofence) => {
          return <GeofenceMapSmall geofence={ geofence } onSelect={this.props.onSelectGeofence} />
        })
        : 'No Geofences'
      }
    </ScrollPanel>
  }
}

export default compose(
  graphql(GEOFENCES_QUERY, {
    name: 'geofences'
  })
)(withApollo(SelectGeofence));