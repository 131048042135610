import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';
import moment from 'moment-timezone';

import { Button } from 'primereact/components/button/Button';
import { Card } from 'primereact/components/card/Card';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';

import TrailMap from './Trails/TrailMap';
import TrailSetup from './Trails/TrailSetup';
import TrailList from './Trails/TrailList';

const USER_QUERY = gql`
  query {
    user {
      timezone
    }
  }
`;

const heightOffset = 'calc(100vh - 175px)';

class Trails extends React.Component {
  constructor(props) {
    super(props);

    let showTrailSetUp = true;

    if (localStorage.getItem('gpsleaders-showTrailSetUp') == 1
    || localStorage.getItem('gpsleaders-showTrailSetUp') == 0) {
      showTrailSetUp = localStorage.getItem('gpsleaders-showTrailSetUp') == 1 ? true : false;
    }

    this.state = {
      trails: [],
      selectedEvent: null,
      heartbeat: false,
      mapId: 'mapbox.streets',
      showTrailSetUp
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {}

  updateTrails(startDate, endDate, vehicles) {
    //console.log('Dates:', dates);
    // console.log('Vehicles:', vehicles);

    const query = gql`
    query lastEvents($unit_id: Int!, $start_date: String!, $end_date: String!) {
      lastEvents(unit_id: $unit_id, start_date: $start_date, end_date: $end_date, sortAsc: true)
    }`;

    this.setState({
      trails: []
    }, () => {
      const userTimezone = this.props.user && this.props.user.user && this.props.user.user.timezone && this.props.user.user.timezone.length > 0
      ? this.props.user.user.timezone
      : 'America/Los_Angeles';

      console.log('RAW Start Date:', startDate);
      console.log('RAW End Date:', endDate);

      vehicles.forEach( (vehicle) => {
        const icon = (vehicle) ? (vehicle.icon) ? vehicle.icon : 'default':'default';
        const start_date = 'UTC:' + moment.tz(startDate, userTimezone).utc().format('YYYY/MM/DD HH:00:00.000');
        const end_date = 'UTC:' + moment.tz(endDate, userTimezone).utc().add(1, 'd').format('YYYY/MM/DD HH:00:00.000');
        console.log('Start Date:', start_date);
        console.log('End Date:', end_date);
        const variables = {
          unit_id: vehicle.unit_id,
          start_date,
          end_date,
        };

        this.props.client.query({ query, variables }).then( (res) => {
          //console.log('Res:', res);
          let trails = this.state.trails.concat();
          trails.push({
            label: vehicle.label,
            trail: res.data.lastEvents,
            icon
          });
          // this.setState({ trails, selectedEvent: null });
          this.setState({ trails });
        } );
      } );
    });


  }

  toggleHeartbeat() {
    this.setState({heartbeat: !this.state.heartbeat});
  }
  
  toggleShow(name) {
    let newState = {};
    newState[name] = !this.state[name];
    localStorage.setItem('gpsleaders-'+name, newState[name] ? 1 : 0);
    console.log(name + ': ' + localStorage.getItem('gpsleaders-'+name));
    this.setState(newState);
  }

  onSelectEvent(event) {
    if (this.state.selectedEvent && event.event_data.latitude == this.state.selectedEvent.event_data.latitude && event.event_data.longitude == this.state.selectedEvent.event_data.longitude) {
      this.setState({
        selectedEvent: null
      });
    } else {
      this.setState({
        selectedEvent: event
      });
    }
  }

  render() {
    const userTimezone = this.props.user && this.props.user.user && this.props.user.user.timezone && this.props.user.user.timezone.length > 0
    ? this.props.user.user.timezone
    : 'America/Los_Angeles';

    return (  
      <div className="ui-g" style={{ padding: 0 }}>
        <div className="ui-g-12" style={{ padding: 0 }}>
          <div className="card clearfix" style={{ padding: 0 }}>
            <div className="ui-g-6">
              <i
                className="material-icons"
                style={{color: this.state.showTrailSetUp ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30}}
                id="trailSetUp"
                onClick={() => {this.toggleShow('showTrailSetUp')}}
              >timeline</i><Tooltip for="#trailSetUp" title="Trail Setup" tooltipPosition="top" />
              <i
                className="material-icons"
                style={{ color: this.state.mapId == 'mapbox.streets-satellite' ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30 }}
                onClick={() => { this.setState({ mapId: this.state.mapId == 'mapbox.streets-satellite' ? 'mapbox.streets' : 'mapbox.streets-satellite' }) }}
                id="toggleSatelliteView"
              >satellite</i><Tooltip for="#toggleSatelliteView" title="Toggle Satellite View" tooltipPosition="top" />
            </div>
            {/*
            <div className="ui-g-6" style={{textAlign: 'right'}}>
              <Button
                label="Street"
                style={{
                  backgroundColor: this.state.mapId == 'mapbox.streets' ? '#51ce9e' : '#cccccc'
                }}
                onClick={() => {this.setState({mapId: 'mapbox.streets'})}}
              />
              <Button
                label="Satellite"
                style={{
                  backgroundColor: this.state.mapId == 'mapbox.streets-satellite' ? '#51ce9e' : '#cccccc'
                }}
                onClick={() => {this.setState({mapId: 'mapbox.streets-satellite'})}}
              />
            </div>
            */}
          </div>
          <div className="card clearfix ui-g-12" style={{ borderRadius: 0, border: 0, padding: 0, backgroundColor: '#f4f4f4' }}>
            {
              this.state.showTrailSetUp
              ? <div className="ui-g-2 ui-lg-3 ui-md-4 ui-sm-12 clearfix" style={{ padding: 0 }}>
                <TrailSetup userTimezone={userTimezone} updateTrails={this.updateTrails.bind(this)} toggleHeartbeat={this.toggleHeartbeat.bind(this)} heartbeat={this.state.heartbeat} />
              </div>
              : ''
            }
            <div className={(this.state.showTrailSetUp ? 'ui-g-10 ui-lg-9 ui-md-8' : 'ui-g-12') + ' ui-sm-12 clearfix'} style={{ padding: 0}}>
              <TrailMap userTimezone={userTimezone} trails={this.state.trails} mapId={this.state.mapId} style={{ width: '100%', height: heightOffset }} selectedEvent={this.state.selectedEvent} onSelectEvent={this.onSelectEvent.bind(this)} heartBeatOnly={this.state.heartbeat}/>
              <TrailList userTimezone={userTimezone} trails={this.state.trails} heartBeatOnly={this.state.heartbeat} onSelectEvent={this.onSelectEvent.bind(this)} />
            </div>
          </div>
        </div>        
      </div>     
    );
  }
}

export default compose(
  graphql(USER_QUERY, {
    name: 'user'
  })
)(withApollo(Trails));