import React, { Component } from 'react';
import L from 'leaflet';
import { Circle, CircleMarker, Map, TileLayer, Marker, Rectangle, Polygon, Popup } from 'react-leaflet';

export default class GeofenceMapSmall extends Component {
  getRectBounds(pos, radius) {
    //console.log('Radius:', radius);
    const R = 6378137;
    const lat = pos[0];
    const lon = pos[1];

    const latDelta = radius/R;
    const lonDelta = radius/(R*Math.cos(Math.PI*lat/180));

    const latA = lat-latDelta*180/Math.PI;
    const latB = lat+latDelta*180/Math.PI;
    const lonA = lon-lonDelta*180/Math.PI;
    const lonB = lon+lonDelta*180/Math.PI;

    return [
      [latA, lonA],
      [latB, lonB]
    ];
  }

  zoomToUnits(geofences) {
    // Map center and zoom
    const avgLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return accum+=gf.latitude;
      } else {
        return accum;
      }
    }, 0) / geofences.length;
    const avgLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return accum+=gf.longitude;
      } else {
        return accum;
      }
    }, 0) / geofences.length;
    const minLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return (gf.latitude < accum ? gf.latitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].latitude);
    const maxLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return (gf.latitude > accum ? gf.latitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].latitude);
    const minLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return (gf.longitude < accum ? gf.longitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].longitude);
    const maxLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return (gf.longitude > accum ? gf.longitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].longitude);
    const latDist = maxLat-minLat;
    const longDist = maxLong-minLong;

    return (13-parseInt(latDist*10));
  } 

  render() {
    const pos = this.props.geofence ? [this.props.geofence.latitude, this.props.geofence.longitude] : [0, 0];

    return (
      <div className="clearfix select-geofence" onClick={() => {
        if (this.props.onSelect) {
          this.props.onSelect(this.props.geofence)
        }
      }}>        
        {
          this.props.geofence && this.props.geofence.type && ((this.props.geofence.points && this.props.geofence.points.coordinates) || this.props.geofence.radius)
          ? <React.Fragment>
          <div style={{ textAlign: 'center' }}>{ this.props.geofence.name.length > 12 ? this.props.geofence.name.substring(0, 12) + '..' : this.props.geofence.name }</div>
          <Map 
            style={{
              width: '100%',
              height: '75px',
              borderRadius: '5px',
              border: '1px solid #cccccc',
              textAlign: 'center'
            }} 
            center={pos}
            zoom={this.props.geofence.type == 'polygon' ? this.zoomToUnits(this.props.geofence.points.coordinates) : 14-(this.props.geofence.radius/1000) }
            zoomControl={false}
            attributionControl={false}
            dragging={false}
            scrollWheelZoom={false}
            dragging={ !L.Browser.mobile }
          >
            <TileLayer
              attribution=""
              url="https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoiZ3BzbGVhZGVycyIsImEiOiJjamw4aXhmNXIwMHVmM3BydW04cmJ5cXE0In0.mLNavvJJLhaP6DANzEyatw"
            />
            {
              this.props.geofence.type == 'polygon'
              ? <Polygon positions={this.props.geofence.points.coordinates.map((pos) => ([pos.latitude, pos.longitude]))} />                                  
              : <React.Fragment>
                {
                  this.props.geofence.type == 'circle'
                  ? <Circle center={pos} radius={this.props.geofence.radius/3.28084} />
                  : <Rectangle bounds={this.getRectBounds(pos, this.props.geofence.radius/3.28084)} />
                }
              </React.Fragment>
            }  
          </Map>
          </React.Fragment>
          : <React.Fragment>
            <div style={{ textAlign: 'center' }}>ERROR</div>
            <div style={{width: '100%', height: '75px', borderRadius: '5px', border: '1px solid #cccccc', textAlign: 'center'}}></div>
          </React.Fragment>
        }
      </div>
    );
  }
}