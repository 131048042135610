import React, { Component } from 'react';

import { Button } from 'primereact/components/button/Button';
import { Card } from 'primereact/components/card/Card';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { Fieldset } from 'primereact/components/fieldset/Fieldset';
import { InputMask } from 'primereact/components/inputmask/InputMask';
import { InputText } from 'primereact/components/inputtext/InputText';
import { InputSwitch } from 'primereact/components/inputswitch/InputSwitch';
import { OverlayPanel } from 'primereact/components/overlaypanel/OverlayPanel';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';

import AccountAlerts from './AccountAlerts';
import AlertSettings from './AlertSettings';
import GeofenceAlerts from './GeofenceAlerts';

import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

const GROUPS_QUERY = gql`
query {
  groups {
    group_id
    compid
    name
    units {
      unit_id
      compid
      unittype_id
      imei
      serial
      simnumber
      simphone
      label
      createdAt
      lastEvent
    }
  }
}`;

class AlertContent extends Component {

  render() {

    const units = this.props.groups && this.props.groups.groups && this.props.groups.groups.length > 0    
    ? this.props.groups.groups.reduce((accum, group) => {
      return accum.concat(group.units);
    }, [])
    : [];

    return <div style={{ paddingLeft: '2px', height: '100%' }}>
      <div className="card-header1" >Alerts <small>- Alerts will trigger for all units in account.</small></div>
      <AlertSettings unit_id={0} />
      <div className="card-header1" style={{ padding: '7px', paddingLeft: '10px', borderRadius: 0, marginTop: '2px'}}>Geofence Alerts</div>
      <GeofenceAlerts units={units} contacts={this.props.contacts} />
    </div>
  }
}

export default compose(  
  graphql(GROUPS_QUERY, {
    name: 'groups'
  })
)(withApollo(AlertContent));