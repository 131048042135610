import React, { Component } from 'react';
import { Button } from 'primereact/components/button/Button';
import Contact from './Contact';

class SelectContacts extends Component {
  render() {

    console.log('Selected Contacts:', this.props.selectedContacts);

    return <React.Fragment>
      {
        this.props.contacts && this.props.contacts.contacts && this.props.contacts.contacts.length > 0
        ? this.props.contacts.contacts.map((contact) => {          
          return <Contact
            contact={contact}
            onSelect={this.props.onSelectContact}
            selected={this.props.selectedContacts.reduce((accum, c) => (c.contact_id == contact.contact_id ? true : accum), false)}
            selectedEmail={this.props.selectedContacts.reduce((accum, c) => (c.contact_id == contact.contact_id && c.selectedEmail ? true : accum), false)}
            selectedPhone={this.props.selectedContacts.reduce((accum, c) => (c.contact_id == contact.contact_id && c.selectedPhone ? true : accum), false)}
            onToggleEmail={this.props.onToggleEmail}
            onTogglePhone={this.props.onTogglePhone}
          />
        })
        : 'No Contacts'
      }
      {
        this.props.showDoneButton
        ? <Button className="ui-button-success" label="Done" onClick={this.props.onDone} style={{ width: '100px', height: '100px', fontSize: 20 }} />
        : ''
      }
    </React.Fragment>
  }
}

export default SelectContacts;