import React, { Component } from 'react';

import { ToggleButton } from 'primereact/components/togglebutton/ToggleButton';

class Unit extends Component {
  toggleSelectUnit() {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.unit);
    }
  }

  render() {
    return <div 
      style={{
        float: 'left',
        width: '100px',
        height: '100px',
        border: 0,
        padding: '5px',
        cursor: 'pointer'
      }}
      onClick={this.toggleSelectUnit.bind(this)}
    >
      <div style={{ textAlign: 'center' }}>{ this.props.unit.label }</div>
      <div style={{ textAlign: 'center' }}>
        <i 
          className="material-icons"
          style={{ color: this.props.selected || this.props.readOnly ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 50 }}
        >directions_car</i>
      </div>
      <div style={{ textAlign: 'center', color: this.props.selected || this.props.readOnly ? '#4385b4' : '#cccccc' }}>{ this.props.selected || this.props.readOnly ? 'Enabled' : 'Disabled' }</div>
    </div>;
  }
}

export default Unit;