import React, { Component } from 'react';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';

class Contact extends Component {
  render() {

    const name = this.props.contact.name.split(' ');

    return <div
      className="clearfix"
      style={{        
        width: '100px',
        height: '100px',
        float: 'left',
        marginRight: '10px',
        border: '1px '+ (this.props.selected ? 'solid' : 'dashed') + ' #dedede',
        padding: '5px',
        borderRadius: '5px',        
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 13, color: '#777777' }}>{ name[0] + (name.length > 1 ? ' ' + name[1][0] + '.' : '') }</div>
      <div style={{ textAlign: 'center' }}>
        <i 
          className="material-icons"
          style={{ color: this.props.selected == true ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 40 }}
          onClick={() => {
            if (this.props.onSelect) {
              this.props.onSelect(this.props.contact);
            }
          }}
        >contact_mail</i>
      </div>
      {
        this.props.selected
        ? <div style={{ textAlign: 'center' }}>
          <i
            className="material-icons"
            style={{ color: this.props.selectedEmail ? '#4385b4' : '#cccccc', cursor: 'pointer' }}
            id={'contact-email-'+this.props.contact.contact_id}
            onClick={() => {
              if (this.props.onToggleEmail) {
                this.props.onToggleEmail(this.props.contact)
              }
            }}
          >mail</i>
          <Tooltip for={'#contact-email-'+this.props.contact.contact_id} title={this.props.contact.email} tooltipPosition="bottom" />
          <i
            className="material-icons"
            style={{ color: this.props.selectedPhone ? '#4385b4' : '#cccccc', cursor: 'pointer' }}
            id={'contact-phone-'+this.props.contact.contact_id}
            onClick={() => {
              if (this.props.onTogglePhone) {
                this.props.onTogglePhone(this.props.contact)
              }
            }}
          >phone</i>
          <Tooltip for={'#contact-phone-'+this.props.contact.contact_id} title={this.props.contact.phone} tooltipPosition="bottom" />
        </div>
        : <div style={{ textAlign: 'center', color: '#cccccc' }}>Disabled</div>
      }      
    </div>;
  }
}

export default Contact;