import React, { Component } from 'react';

import { Button } from 'primereact/components/button/Button';

import Unit from './Unit';

class SelectUnits extends Component {
  render() {
    return <React.Fragment>
      {
        this.props.units && this.props.units.length > 0
        ? this.props.units.map(unit => <Unit unit={unit} onSelect={this.props.onSelectUnit} selected={this.props.selectedUnits.reduce((accum, u) => u.unit_id == unit.unit_id ? true : accum, false)} />)
        : 'No Units'
      }
      {
        this.props.showDoneButton
        ? <Button className="ui-button-success" label="Next" onClick={this.props.onDone} style={{ width: '100px', height: '100px', fontSize: 20 }} />
        : ''
      }      
    </React.Fragment>
  }
}

export default SelectUnits;