import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import { Card } from 'primereact/components/card/Card';
import { Column } from 'primereact/components/column/Column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { Panel } from 'primereact/components/panel/Panel';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';

import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import moment from 'moment-timezone';

import Helpers from '../libs/Helpers';
import Report from './Reports/Report';
import { relative } from 'path';

const DEVICE_TIME_FORMAT = 'UTC:YYYY/MM/DD HH:mm:ss.SSS';

const EVENTS = gql`
query lastEvents ($unit_id: Int!, $start: String!, $end: String!) {
  lastEvents(unit_id: $unit_id, start_date: $start, end_date: $end)
}`;

const GROUPS = gql`
query {
  groups {
    group_id
    compid
    name
    units {
      unit_id
      label
      serial
      vehicle {
        icon
      }
    }
  }
}`;

// reset
//localStorage.setItem('gpsleadertracking-reports', null);

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reports: [],
      selectedUnitId: 0,
      selectedReport: 0,
    }

    this.onUnitSelect = this.onUnitSelect.bind(this);
  }

  newReport() {
    /* Insert new report at the top */
    let reports = this.state.reports.concat();
    reports.push({
      show: true,
      step: 0,
      reportName: 'New Report',
    });
    //reports.splice(0, 0, {show: true, step: 0});
    //console.log('After insert new:', reports);
    this.setState({
      reports,
      selectedReport: reports.length-1,
    });
  }

  onUnitSelect(e) {
    this.setState({
      selectedUnitId: e.value
    });

    const variables = {
      unit_id: e.value,
      start: moment().startOf('day').utc().format(DEVICE_TIME_FORMAT),
      end: moment().endOf('day').utc().format(DEVICE_TIME_FORMAT)
    };

    //console.log('Vars:', variables);

    this.props.client.query({
      query: EVENTS,
      variables
    }).then((res) => {
      //console.log('Last Events:', res.data.lastEvents);
      if (res.data && res.data.lastEvents && res.data.lastEvents.length > 0) {
        this.setState({
          reportData: res.data.lastEvents.map((event, index) => {
            //console.log('Event:', event);
            if (event && event.event_data) {
              return {
                index,
                //seq: event.event_data.sequence_number,
                time: moment.unix(event.event_data.device_time).format('MMMM Do, YYYY h:mm:ss A'),
                event_type: Helpers.cleanEventType(event.device_type, event.event_data.event_type),
                coords: Helpers.cleanLatLong(event.event_data.latitude, event.event_data.longitude),
                address: event.event_data.address ? event.event_data.address : 'None',
                speed: Helpers.cleanSpeed(event.device_type, event.event_data.speed ? event.event_data.speed : 0),
                heading: Helpers.degreesToDirection(event.event_data.heading_degrees),
                satellites: event.event_data.satellite_count
              }
            }
          }).sort( (A, B) => {
            //console.log('A:', A.event_data.device_time);
            //console.log('B:', B.event_data.device_time);
            return A.event_data.device_time - B.event_data.device_time
          } )
        })
      } else {
        window.alert('No events found.');
      }
    })
  }

  onReportRun(state) {
    /*
    console.log('Report Ran:', state);
    let reports = this.state.reports.concat();
    reports[state.index] = {
      ...state,
      ...reports[state.index]
    };
    
    this.setState({
      reports
    });
    */
  }

  toggleShowReport(index) {
    /*
    let reports = this.state.reports.concat();
    let report = Object.assign({}, this.state.reports[index]);
    report.show = ! report.show;
    reports.splice(index, 1, report);
    this.setState({
      reports
    });
    */
  }

  onReportSave(index, state) {
    //console.log('Save Report:', index, state);
    let reports = this.state.reports.concat();
    reports[index] = {
      ...reports[index],
      ...state,
      //step: 3,
    };
    this.setState({
      reports
    });
    localStorage.setItem('gpsleadertracking-reports', JSON.stringify(reports));
    //console.log('Reports: ', reports)
  }

  componentDidMount() {
    const reports = JSON.parse(localStorage.getItem('gpsleadertracking-reports'));
    //console.log('Loaded Reports:', reports);
    reports && this.setState({
      reports: JSON.parse(localStorage.getItem('gpsleadertracking-reports'))
    });
  }

  render() {
    const units = (
      this.props.groups
      && this.props.groups.groups
      && this.props.groups.groups.length > 0
    )
    ? this.props.groups.groups.reduce((accum, group) => {
      return accum.concat(
        group.units && group.units.length > 0
        ? group.units.map((unit) => ({
          label: unit.label,
          value: unit.unit_id
        }))
        : []
      )
    }, [])
    : [];

    return <div className="ui-g" style={{ padding: 0 }}>
      <div className="ui-g-12" style={{ padding: 0 }}>
        <div className="card clearfix" style={{ padding: '5px' }}>
          <i
            className="material-icons"
            style={{color: '#51ce9e', cursor: 'pointer', fontSize: 30}}          
            id="newReport"
            onClick={this.newReport.bind(this)}
          >add</i><Tooltip for="#newReport" title="New Report" tooltipPosition="top" />
          <React.Fragment>
          {
            this.state.reports.length > 0
            ? this.state.reports.map((report, index) => (
              <React.Fragment>
                <span
                  style={{ margin: '10px', cursor: 'pointer', borderBottom: this.state.selectedReport == index ? '2px solid #51ce9e' : '' }}
                  onClick={ () => {
                    this.setState({
                      selectedReport: index
                    });
                  } }
                >
                  <i
                    className="material-icons"
                    style={{
                      color: this.state.selectedReport == index ? '#51ce9e' : '#cccccc',
                      cursor: 'pointer',
                      fontSize: 30,
                    }}
                    id={'report-icon-'+report.index}                    
                  >description</i>
                  <span style={{ fontSize: 16, position: 'relative', top: -5, color: this.state.selectedReport == index ? '#232323' : '#ababab' }}>{report.reportName}</span>
                  <i
                    className="material-icons"
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                    }}
                    onClick={ () => {
                      //console.log('Deleting Report:', index);
                      this.setState({
                        reports: this.state.reports.filter( (r, i) => i != index)
                      }, () => {
                        localStorage.setItem('gpsleadertracking-reports', JSON.stringify(this.state.reports));
                        if (this.state.selectedReport >= this.state.reports.length) {
                          this.setState({
                            selectedReport: this.state.reports.length-1
                          })
                        }                    
                      });                      
                    } }
                  >delete_forever</i>
                </span>                
              </React.Fragment>
            ))
            : <Card title="Click the + above to add a report" />
          }
          </React.Fragment>
        </div>
        {
          this.state.reports.length > 0
          ? <Report
            report={ this.state.reports[this.state.selectedReport] }
            index={ this.state.selectedReport }
            key={'report-'+this.state.selectedReport}
            onRun={ this.onReportRun.bind(this) }
            onSave={ this.onReportSave.bind(this) }
          />
          : ''          
        }            
      </div>
    </div>;
  }
}

export default compose(
  withApollo,
  graphql(GROUPS, {
    name: 'groups'
  })
)(Reports);