const eventTable = [
  { columnKey: "time", style: null, sortable: true, header: "Time" },
  { columnKey: "event_type", style: { textAlign: 'center', width: '75px' }, sortable: false, header: "Event" },
  { columnKey: "coords", style: { textAlign: 'center', width: '200px' }, sortable: false, header: "Coordinates" },
  { columnKey: "address", style: null, sortable: false, header: "Address" },
  { columnKey: "speed", style: { textAlign: 'center', width: '75px' }, sortable: false, header: "Speed" },
  { columnKey: "heading", style: { textAlign: 'center', width: '100px' }, sortable: false, header: "Heading" },
  { columnKey: "satellites", style: { textAlign: 'center', width: '100px' }, sortable: false, header: "Satellites" },
  { columnKey: "actions", style: { textAlign: 'center', width: '100px' }, sortable: false, header: "Actions" },
];

export const REPORTTYPES = [
  { name: 'HISTORY', columns: eventTable },
  {
    name: 'ALERT',
    columns: [
      { columnKey: "time", style: { width: '20%' }, sortable: true, header: "Time" },
      { columnKey: "label", style: { width: '15%' }, sortable: true, header: "Unit Label" },
      { columnKey: "type", style: { width: '10%' }, sortable: true, header: "Type" },
      { columnKey: "details", style: { width: '50%' }, sortable: false, header: "Details" },
    ]
  },
  { name: 'LOCATION', columns: eventTable },
  {
    name: 'USER',
    columns: [
      { columnKey: "time", style: { width: '100px' }, sortable: true, header: "Time" },
      { columnKey: "username", style: { width: '100px' }, sortable: true, header: "Username" },
      { columnKey: "fullname", style: { width: '100px' }, sortable: true, header: "User name" },
      { columnKey: "origin", style: { width: '225px' }, sortable: false, header: "Origin" },
      { columnKey: "remote", style: { width: '175px' }, sortable: false, header: "Remote" }
    ]
  },
  { name: 'STOPS', columns: eventTable },
];
export const topTable = {
  name: 'TOP',
  columns: [
    { columnKey: "address", style: { width: '60%' }, sortable: false, header: "Address" },
    { columnKey: "stop_count", style: { width: '20%' }, sortable: false, header: "# of Stops" },
    { columnKey: "actions", style: { textAlign: 'center', width: '20%' }, sortable: false, header: "Actions" }
  ]
};
export const repoTop = {
  name: 'TOP',
  columns: [
    { columnKey: "actions", style: { textAlign: 'center', width: '20%' }, sortable: false, header: "View" },
    { columnKey: "address", style: { width: '60%' }, sortable: false, header: "Address" },
    { columnKey: "stop_count", style: { width: '20%' }, sortable: false, header: "# of Stops" },
  ]
};
export const repoHistory = {
  name: 'HISTORY',
  columns: [
    { columnKey: "actions", style: { textAlign: 'center', width: '20%' }, sortable: false, header: "View" },
    { columnKey: "address", style: null, sortable: false, header: "Address" },
    { columnKey: "event_type", style: { textAlign: 'center', width: '75px' }, sortable: false, header: "Event" },
    { columnKey: "time", style: null, sortable: true, header: "Time" },
  ]
};
