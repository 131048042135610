import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'primereact/components/button/Button';
import { Card } from 'primereact/components/card/Card';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import { ListBox } from 'primereact/components/listbox/ListBox';

import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';
import Modal from 'react-modal';

import Helpers from '../../libs/Helpers';
import { customStyles, inputStyles } from '../../libs/AppStyle';




const AlertTypes = {
  lowBattery: 1,
  tamper: 2,
  state: 3,
  country:4,
  inactivity:5,
  power: 6,
  speed: 7
}

const ALERTS_QUERY = gql`
query alerts ($unit_id: Int!) {
  alerts (unit_id: $unit_id) {
    alert_id
    alerttype_id
    value
    alertType {
      name
    }
    alertContacts {
      contact_id
      email
      phone
      contact {
        contact_id
        name
        email
        phone
      }
    }
  }
}`;

const UNIT_QUERY = gql`
query unit ($unit_id: Int!) {
  unit (unit_id: $unit_id) {
    unit_id
    imei
    serial
    label
  }
}`;

const CONTACTS_QUERY = gql`
query {
  contacts {
    contact_id
    name
    email
    phone
  }
}
`;

class AlertSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalType: 0,
      lowbattery: {
        contacts: [],
        alertId: 0,
        active: false
      },
      country: {
        contacts: [],
        alertId: 0,
        active: false,
        value: '',
        hasModal: {
          units: [...Helpers.countries],
        }        
      },
      inactivity: {
        contacts: [],
        alertId: 0,
        active: false
      },
      power: {
        contacts: [],
        alertId: 0,
        active: false
      },
      speed: {
        contacts: [],
        alertId: 0,
        active: false,
        value: '',        
        hasModal: {
          units: [],
          minUnit: 5,
          maxUnit: 100,
          unitMeasurement: 'mph',
        }
      },
      state: {
        contacts: [],
        alertId: 0,
        active: false,
        value: '',
        hasModal: {
          units: [...Helpers.states],
        }
      },
      tamper: {
        contacts: [],
        alertId: 0,
        active: false
      },
      displayModal: false
    }
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ displayModal: false, showStateModal: false, showSpeedModal: false });
  }

  clearState() {
    this.setState({
      lowbattery: {
        contacts: [],
        alertId: 0,
        active: false
      },
      country: {
        contacts: [],
        alertId: 0,
        active: false,
        value: '',
        hasModal: {
          units: [...Helpers.countries],
        }           
      },
      inactivity: {
        contacts: [],
        alertId: 0,
        active: false
      },
      power: {
        contacts: [],
        alertId: 0,
        active: false
      },
      speed: {
        contacts: [],
        alertId: 0,
        active: false,
        value: '',
        hasModal: {
          units: [],
          minUnit: 40,
          maxUnit: 100,
          unitMeasurement: 'mph'
        }
      },
      state: {
        contacts: [],
        alertId: 0,
        active: false,
        value: '',
        hasModal: {
          units: [...Helpers.states]
        }
      },
      tamper: {
        contacts: [],
        alertId: 0,
        active: false
      }
    });
  }

  passState(alert) {
    const active = true;
    this.setState({
      [alert.alertType.name]: {
        active,
        alertId: alert.alert_id,
        contacts: alert.alertContacts,
        value: alert.value
      }
    })
  }

  componentWillReceiveProps(props) {
    this.clearState();
    if (props.alerts && props.alerts.alerts && props.alerts.alerts.length > 0) {
      props.alerts.alerts.forEach((alert) => this.setState(this.passState(alert)));
    }
  }

  componentDidMount() {
    this.clearState();
    if (this.props.alerts && this.props.alerts.alerts && this.props.alerts.alerts.length > 0) {
      this.props.alerts.alerts.forEach((alert) => this.passState(alert));
    }
  }

  toggleAlert(currentState, currentId, alerttype_id) {
    if (currentState && currentId) {
      // Delete Attached Alert Contacts first
      const mutation = gql`
      mutation deleteAlertContact ($alert_id: Int!) {
        deleteAlertContact (alert_id: $alert_id)
      }`;
      const variables = {
        alert_id: currentId
      };
      this.props.client.mutate({ mutation, variables })
        .then((res) => {
          // Delete alert
          const mutation = gql`
        mutation deleteAlert ($alert_id: Int!) {
          deleteAlert (alert_id: $alert_id)
        }`;
          this.props.client.mutate({ mutation, variables })
            .then((res) => {
              this.props.alerts.refetch();
            });
        });
    } else {
      // Create new alert
      this.createAlert(alerttype_id);
    }
  }

  createAlert(alerttype_id, value) {
    const mutation = gql`
      mutation addAlert($alerttype_id: Int!, $unit_id: Int!, $value: String) {
        addAlert(alerttype_id: $alerttype_id, unit_id: $unit_id, value: $value)
      }
    `;
    const variables = {
      alerttype_id,
      unit_id: this.props.unit_id,
      value
    };
    this.props.client.mutate({ mutation, variables })
      .then((res) => {
        this.props.alerts.refetch();
        this.closeModal();
      })
      .catch((err) => {
        console.log('AddAlertError:', err);
      });
  }

  toggleAlertContact(contact, contactList, alert_id, callback) {
    if (this.contactInList(contact, contactList)) {
      // Delete alert contact
      const mutation = gql`
      mutation deleteAlertContact ($alert_id: Int!, $contact_id: Int!) {
        deleteAlertContact (alert_id: $alert_id, contact_id: $contact_id)
      }`;
      const variables = {
        alert_id,
        contact_id: contact.contact_id
      };
      this.props.client.mutate({ mutation, variables }).then((res) => {
        this.props.alerts.refetch();
      });
    } else {
      this.createAlertContact(alert_id, contact.contact_id);
      if (callback) callback();
    }
  }

  _renderAlertType(alertConfig) {
    let { alertName, id, active, alertId, alertTypeId, alertIcon, contacts, iconId, hasModal = false, value = false } = alertConfig;
    return (
      <tr>
        <td>
          <div style={{ color: '#888888', fontSize: 12, fontWeight: 'bold', textAlign: 'center' }}>{alertName}</div>
          <div style={{ position: 'relative' }}>
            <div>
              <i
                className="material-icons"
                id={id + this.props.unit_id}
                style={{ color: active ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 80 }}
                onClick={() => (hasModal) ?
                  this.openModal(alertTypeId) :
                  this.toggleAlert(active, alertId, alertTypeId, hasModal)}

              >{alertIcon}</i>            
              {/* <Tooltip for={'#unitTamperAlert-'+this.props.unit_id} title="Tamper Alert" tooltipPosition="top" /> */}
            </div>
            {
              value
              ? <div style={{ fontWeight: 'bolder', fontSize: '30px', position: 'absolute', left: '50%', top: '75%', transform: 'translate(-50%, -50%)' }}>{value}</div>
              : ''
            }
          </div>          
        </td>
        <td>
          {(hasModal) ? this.renderModal() : ''}
          {active ? this.renderAlertContacts({ alertId, iconId, contacts }) : <span style={{ color: '#aaaaaa' }}>&lt; {`Click to enable ${alertName} Alert.`}</span>}
        </td>
      </tr>
    );
  }

  openModal(alertTypeId) {
    this.setState({ modalType: alertTypeId, displayModal: true })
  }
  
  renderModal() {
    try {
      let controlLabel;
      let options = [];
      let alertTypeId = this.state.modalType

      if (alertTypeId=== AlertTypes.speed) {
        const { minUnit, maxUnit, unitMeasurement} = this.state.speed.hasModal;
        for (let unit = minUnit; unit <= maxUnit; unit+=5) {
          options.push({ label: `${unit} ${unitMeasurement}`, value: unit });
        }
        controlLabel = 'Speed'
      } else {
        if (alertTypeId === AlertTypes.state) {
          options = this.state.state.hasModal.units;
          controlLabel = 'State'
        } else {
          options = this.state.country.hasModal.units;
          controlLabel = 'Country'
        }
      } 
  
      return (
        <Modal isOpen={this.state.displayModal} onRequestClose={this.closeModal} style={customStyles} >
 
          <div class="p-inputgroup">
            <label htmlFor="unit"> { `Select ${controlLabel}` }</label>
              <ListBox 
              multiple={false}
              options={options}
              value={this.state.modalVal}
              onChange={(e) => { this.setState({ modalVal: e.value }) }}
              id="unit" options={options} style={inputStyles}
             />
 
          </div>
          <Button style={{ float: 'right', marginTop: "10px" }} label='Submit' className="ui-button-primary"
            onClick={() => this.createAlert(alertTypeId, this.state.modalVal)} />
          <Button style={{ float: 'right', marginTop: "10px" }} label='Cancel' className="ui-button-danger"
            onClick={this.closeModal} />  
        </Modal>
      );      
    } catch (error) {
      console.info(error);
    }
  }

  renderAlertContacts(alertConfig) {
    const { contacts, iconId, alertId } = alertConfig;
    return <span>
      {
        this.props.contacts.contacts && this.props.contacts.contacts.length > 0 ? this.props.contacts.contacts.map((contact) => {

          const enabled = this.contactInList(contact, contacts);

          const name = contact.name.split(' ');

          return (<div
            className="clearfix"
            style={{
              padding: '5px',
              marginRight: '5px',
              float: 'left',
              textAlign: 'center',
              border: '1px ' + (enabled ? 'solid' : 'dashed') + ' #cccccc',
              borderRadius: '5px',
              width: '62px',
              height: '100px',
              filter: enabled ? 'drop-shadow(0px 1px 1px #cccccc)' : ''
            }}
          >
            <div style={{ color: '#454545', fontSize: 11, textAlign: 'center' }}>{(name[0].length > 5 ? name[0].substring(0, 5) + '.' : name[0]) + (name.length > 1 ? ' ' + name[1][0] + '.' : '')}</div>
            <div style={{ textAlign: 'center' }}>
              <i
                className="material-icons"
                id={`${iconId}-${contact.contact_id}`}
                style={{ color: enabled ? '#4385b4' : '#cccccc', cursor: 'pointer', fontSize: 40 }}
                onClick={(e) => { this.toggleAlertContact(contact, contacts, alertId, () => { }); }} //panel.toggle(e);
              >contact_mail</i>
              {/* <Tooltip for={'#alertContact-'+contact.contact_id} title={contact.name} tooltipPosition="top" /> */}
            </div>
            {
              enabled
                ? <div>
                  <i
                    className="material-icons"
                    id={`${iconId}Email-${contact.contact_id}`}
                    style={{ color: this.getAlertContactStatus(contact.contact_id, contacts, 'email') ? '#4385b4' : '#cccccc', cursor: 'pointer' }}
                    onClick={() => { this.toggleAlertContactEmail(alertId, contact.contact_id) }}
                  >mail</i>
                  <Tooltip for={`#${iconId}Email` + contact.contact_id} title={contact.email.length > 0 ? contact.email : 'No Email'} tooltipPosition="bottom" />
                  <i
                    className="material-icons"
                    id={`${iconId}Phone-${contact.contact_id}`}
                    style={{ color: this.getAlertContactStatus(contact.contact_id, contacts, 'phone') ? '#4385b4' : '#cccccc', cursor: 'pointer' }}
                    onClick={() => { this.toggleAlertContactPhone(alertId, contact.contact_id) }}
                  >phone</i>
                  <Tooltip for={`#${iconId}Phone` + contact.contact_id + contact.contact_id} title={contact.phone.length > 0 ? contact.phone : 'No Phone'} tooltipPosition="bottom" />
                </div>
                : <span style={{ fontSize: 10, color: '#aaaaaa' }}>Disabled</span>
            }
          </div>
          );
        })
          : 'No Contacts.'
      }
    </span>
  }

  contactInList(contact, list) {
    if (list) {
      return list.reduce((accum, current, index) => {
        return contact.contact_id == current.contact_id ? true : accum
      }, false);
    } else {
      return false;
    }
  }

  getAlertContactStatus(contact_id, list, field) {
    return list.reduce((accum, ac, index) => {
      if (ac.contact_id == contact_id) {
        return ac[field];
      } else {
        return accum;
      }
    }, false);
  }

  createAlertContact(alert_id, contact_id) {
    const mutation = gql`
    mutation addAlertContact ($alert_id: Int!, $contact_id: Int!) {
      addAlertContact (alert_id: $alert_id, contact_id: $contact_id)
    }
    `;
    const variables = { alert_id, contact_id };
    this.props.client.mutate({ mutation, variables })
      .then((res) => {
        this.props.alerts.refetch();
      });
  }

  async toggleAlertContactEmail(alert_id, contact_id) {
    const mutation = gql`
    mutation updateAlertContact ($alert_id: Int!, $contact_id: Int!, $email: Boolean!) {
      updateAlertContact (alert_id: $alert_id, contact_id: $contact_id, email: $email)
    }`;
    const variables = {
      alert_id,
      contact_id,
      email: ! await this.getAlertContactEmailStatus(alert_id, contact_id)
    };
    this.props.client.mutate({ mutation, variables })
      .then((res) => {
        this.props.alerts.refetch();
      });
  }

  async toggleAlertContactPhone(alert_id, contact_id) {
    const mutation = gql`
    mutation updateAlertContact ($alert_id: Int!, $contact_id: Int!, $phone: Boolean!) {
      updateAlertContact (alert_id: $alert_id, contact_id: $contact_id, phone: $phone)
    }`;
    const variables = {
      alert_id,
      contact_id,
      phone: ! await this.getAlertContactPhoneStatus(alert_id, contact_id)
    };
    this.props.client.mutate({ mutation, variables })
      .then((res) => {
        this.props.alerts.refetch();
      });
  }

  async getAlertContactEmailStatus(alert_id, contact_id) {
    const query = gql`
    query alertContact ($alert_id: Int!, $contact_id: Int!) {
      alertContact (alert_id: $alert_id, contact_id: $contact_id) {
        email
      }
    }`;
    const variables = { alert_id, contact_id };
    return await this.props.client.query({ query, variables })
      .then((res) => {
        console.log('getAlertContactEmailStatus:', res.data.alertContact.email)
        if (res.data && res.data.alertContact && res.data.alertContact) {
          console.log('getAlertContactEmailStatus:', res.data.alertContact.email)
          return res.data.alertContact.email;
        } else {
          return false;
        }
      });
  }

  async getAlertContactPhoneStatus(alert_id, contact_id) {
    const query = gql`
    query alertContact ($alert_id: Int!, $contact_id: Int!) {
      alertContact (alert_id: $alert_id, contact_id: $contact_id) {
        phone
      }
    }`;
    const variables = { alert_id, contact_id };
    return await this.props.client.query({ query, variables })
      .then((res) => {
        if (res.data && res.data.alertContact && res.data.alertContact) {
          console.log('getAlertContactPhoneStatus:', res.data.alertContact.phone)
          return res.data.alertContact.phone;
        } else {
          return false;
        }
      });
  }

  render() {
    if (this.props.alerts.loading) {
      return <ProgressSpinner />;
    } else {
      return (
        <Card className="alert-card-unit" style={{ padding: 0, margin: 0 }} title={
          (
            this.props.unit
            && this.props.unit.unit
            && this.props.unit.unit.label
          )
            ? <div className="card-alert-header"><i className="material-icons" style={{ position: 'relative', top: '5px', color: '#4385b4' }}>drive_eta</i> {this.props.unit.unit.label}</div>
            : ''
        }>
          <div>
            <table>
              <tbody>
                { /* //Hide tamper alert until we have a device working that uses it...
                {this._renderAlertType({
                  alertName: 'Tamper',
                  id: 'unitTamperAlert-',
                  active: this.state.tamper.active,
                  alertId: this.state.tamper.alertId,
                  alertTypeId: AlertTypes.tamper,
                  alertIcon: 'lock_open',
                  contacts: this.state.tamper.contacts,
                  iconId: 'tamperContact'
                })}
                */ }
                {this._renderAlertType({
                  alertName: 'Low Battery',
                  id: 'unitBatteryAlert-',
                  alertTypeId: AlertTypes.lowBattery,
                  active: this.state.lowbattery.active,
                  alertIcon: 'battery_alert',
                  contacts: this.state.lowbattery.contacts,
                  iconId: 'lowBatteryContact',
                  alertId: this.state.lowbattery.alertId
                })}
                {this._renderAlertType({
                  alertName: 'Exit State',
                  id: 'exitStateAlert-',
                  alertTypeId: AlertTypes.state,
                  active: this.state.state.active,
                  alertIcon: 'filter',
                  contacts: this.state.state.contacts,
                  iconId: 'stateContact',
                  alertId: this.state.state.alertId,
                  hasModal: this.state.state.hasModal,
                  value: this.state.state.value
                })}
                {this._renderAlertType({
                  alertName: 'Exit Country',
                  id: 'exitCountryAlert-',
                  alertTypeId: AlertTypes.country,
                  active: this.state.country.active,
                  alertIcon: 'flag',
                  contacts: this.state.country.contacts,
                  iconId: 'countryContact',
                  alertId: this.state.country.alertId,
                  hasModal: this.state.country.hasModal,
                  value: this.state.country.value
                })}
                {this._renderAlertType({
                  alertName: 'Inactivity',
                  id: 'inactivityAlert-',
                  alertTypeId: AlertTypes.inactivity,
                  active: this.state.inactivity.active,
                  alertIcon: 'done',
                  contacts: this.state.inactivity.contacts,
                  iconId: 'inactivityContact',
                  alertId: this.state.inactivity.alertId
                })}
                {this._renderAlertType({
                  alertName: 'Power on/off',
                  id: 'powerAlert-',
                  alertTypeId: AlertTypes.power,
                  active: this.state.power.active,
                  alertIcon: 'power',
                  contacts: this.state.power.contacts,
                  iconId: 'powerContact',
                  alertId: this.state.power.alertId
                })}
                {this._renderAlertType({
                  alertName: 'Speeding',
                  id: 'speedAlert-',
                  alertTypeId: AlertTypes.speed,
                  active: this.state.speed.active,
                  alertIcon: 'traffic',
                  contacts: this.state.speed.contacts,
                  iconId: 'speedContact',
                  alertId: this.state.speed.alertId,
                  hasModal: this.state.speed.hasModal,
                  value: this.state.speed.value
                })}
              </tbody>
            </table>
          </div>
        </Card>
      );
    }
  }
}

AlertSettings.propTypes = {
  unit_id: PropTypes.integer
}

export default compose(
  graphql(ALERTS_QUERY, {
    name: 'alerts',
    options: (props) => ({
      variables: props
    })
  }),
  graphql(UNIT_QUERY, {
    name: 'unit',
    options: (props) => ({
      variables: props
    })
  }),
  graphql(CONTACTS_QUERY, {
    name: 'contacts'
  }),
)(withApollo(AlertSettings));