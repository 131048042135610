import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

import Reference from '../libs/Reference';
import fetch from 'node-fetch';

import { Button } from 'primereact/components/button/Button';
import { Card } from 'primereact/components/card/Card';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';
import { InputText } from 'primereact/components/inputtext/InputText';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';

import GeofenceMap from './Geofences/GeofenceMap';

//const projections = require('../libs/projections');
const sinusoidal = require('../libs/projections/sinusoidal');
const inside = require('point-in-polygon');

const radiusList = [100,150,200,250,300,500,1000,1320,2640,5280,10560,26400,52800];
const typeOptions = [{
  label: 'Circle',
  value: 'circle'
}, {
  label: 'Square',
  value: 'square'
}, {
  label: 'Polygon',
  value: 'polygon'
}];

const GF_QUERY = gql`
query {
  boundaries {
    boundary_id
    type
    active
    compid
    name
    address
    latitude
    longitude
    radius
    points {
      type
      coordinates {
        latitude
        longitude
      }
    }
    notes
  }
}`;

const heightOffset = 'calc(100vh - 175px)';

class Geofences extends Component {

  constructor(props) {
    console.log('Geofences Constructor');
    super(props);

    let showGeofenceList = true;

    if (localStorage.getItem('gpsleaders-showGeofenceList') == 1
    || localStorage.getItem('gpsleaders-showGeofenceList') == 0) {
      showGeofenceList = localStorage.getItem('gpsleaders-showGeofenceList') == 1 ? true : false;
    }

    this.state = {
      showNewGeofenceMarker: true,
      geofences: [],
      newGeofenceName: props.match.params.unitLabel ? props.match.params.unitLabel : '',
      newGeofencePosition: props.match.params.coordinates ? props.match.params.coordinates.split(',').map(c=>parseFloat(c)) : [42.877742, -97.380979],
      newGeofenceRadius: (100/3.28084),
      newGeofenceType: props.match.params.unitLabel ? 'circle' : '',
      newGeofencePoints: [],
      center: props.match.params.coordinates ? props.match.params.coordinates.split(',').map(c=>parseFloat(c)) : [42.877742, -97.380979],
      zoom: props.match.params.unitLabel ? 16 : null,
      addressSearch: '',
      setFromAddress: false,
      mapId: 'mapbox.streets',
      showGeofenceList,
      disabled: true,
    }
    console.log('Initial State: ', this.state)
  }

  loadGeofences() {
    this.props.client.query({
      query: GF_QUERY
    }).then((res) => {
      console.log('Data:', res.data);
      if (res.data.boundaries && res.data.boundaries.length > 0) {        
        this.setState({
          geofences: res.data.boundaries.map((gf) => {
            return {
              ...gf,
              infoChanged: false
            }
          })
        });
        if (this.props.match.params.unitLabel && this.props.match.params.coordinates) {
          console.log('Not zooming');
          this.setState({
            center: this.props.match.params.coordinates.split(',').map(c=>parseFloat(c)),
            zoom: 16
          })
        } else {
          console.log('Zoom to units');
          this.zoomToUnits(res.data.boundaries);
        }
      }
    });
  }

  newGeofence() {
    this.setState({
      showNewGeofenceMarker: true,
      zoom: 14
    });
  }

  onRadiusChange(e) {
    this.setState({
      newGeofenceRadius: e.value
    });
  }

  onTypeChange(e) {
    // Changing to polygon clears the polygon points
    if (e.value == 'polygon') {
      this.setState({
        newGeofencePoints: []
      });
    }

    this.setState({
      newGeofenceType: e.value
    });
  }

  onNameChange(e) {
    this.setState({
      newGeofenceName: e.target.value
    }, () => {
      console.log('Current Positon: ', this.state.newGeofencePosition)
      let disabled = this.state.newGeofenceName.length > 0 ? false : true
      
      this.setState({
        disabled: disabled
      })
    });
  }

  onNewGeofencePositionChange(pos) {
    if (this.state.newGeofenceType == 'polygon') {
      console.log('Polygon')
      // Add to polygon list
      let points = this.state.newGeofencePoints.concat();
      points.push([pos[0].toFixed(6), pos[1].toFixed(6)]);
      
      //console.log('Points:', points);
      if (points.length >= 3) {
        // test
        //const area = this.getTriangleArea(points);
        //console.log('Triangle Area:', area);
        this.state.geofences.forEach((gf) => {
          const {x, y} = sinusoidal({lon: gf.longitude, lat: gf.latitude});
          console.log(gf.name + ' inside poly?', inside([x, y], points.map((point) => {
            const {x, y} = sinusoidal({lon: point[1], lat: point[0]});
            return [x, y];
          })));
        });
      }
      
      this.setState({
        showNewGeofenceMarker: true,
        newGeofencePoints: points,
        newGeofencePosition: this.getCenterFromPoints(points)
      });
    } else {
      this.setState({
        showNewGeofenceMarker: true,
        newGeofencePosition: pos
      });
    }
  }

  getTriangleArea(points) {
    // Map to x,y
    const xy = points.map((pos) => {
      const {x, y} = sinusoidal({lon: pos[1], lat: pos[0]});
      //console.log('x:', x, 'y:', y);
      return {x: x*10000, y: y*10000};
    });

    //console.log('xy:', xy);

    const area = Math.abs(0.5 * (xy[0].x * (xy[1].y - xy[2].y) + xy[1].x * ( xy[2].y - xy[0].y) + xy[2].x * (xy[0].y - xy[1].y)));
    //const area = Math.abs(0.5 * (x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)));
    //console.log('Area:', area);
    return area;
  }

  deleteGeofence(boundary_id) {
    if (window.confirm("Are you sure you want to delete this Geofence?  Doing so will also automatically delete any geofence alert associated with this geofence.  THIS ACTION CANNOT BE UNDONE!")) {
      const mutation = gql`
      mutation deleteBoundary ($boundary_id: Int!) {
        deleteBoundary (boundary_id: $boundary_id)
      }`;
      const variables = {
        boundary_id
      };
      this.props.client.mutate({
        mutation,
        variables
      }).then((res) => {
        this.loadGeofences();
        //this.props.geofences.refetch();
      });
    }
  }

  saveNewGeofence() {
    if (this.state.newGeofenceType == 'polygon') {
      const mutation = gql`
      mutation addBoundary ($type: String!, $name: String!, $latitude: Float!, $longitude: Float!, $points: [[Float!]!]) {
        addBoundary (type: $type, name: $name, latitude: $latitude, longitude: $longitude, points: $points)
      }`;
      const variables = {
        type: this.state.newGeofenceType,
        name: this.state.newGeofenceName,
        latitude: this.state.newGeofencePosition[0],
        longitude: this.state.newGeofencePosition[1],
        points: this.state.newGeofencePoints
      };
      this.props.client.mutate({
        mutation,
        variables
      }).then((res) => {
        this.setState({
          newGeofenceName: '',
          newGeofenceRadius: 100,
          //showNewGeofenceMarker: false,
          newGeofencePoints: [],
          newGeofenceType: ''
        });
        //this.props.geofences.refetch();
        //this.loadGeofences();
        this.props.history.push('/geofences');
        this.loadGeofences();
      });
    } else {
      const mutation = gql`
      mutation addBoundary ($type: String!, $name: String!, $radius: Int!, $latitude: Float!, $longitude: Float!) {
        addBoundary (type: $type, name: $name, radius: $radius, latitude: $latitude, longitude: $longitude)
      }`;
      const variables = {
        type: this.state.newGeofenceType,
        name: this.state.newGeofenceName,
        radius: parseInt(this.state.newGeofenceRadius*3.28084),
        latitude: this.state.newGeofencePosition[0],
        longitude: this.state.newGeofencePosition[1]
      };
      this.props.client.mutate({
        mutation,
        variables
      }).then((res) => {
        this.setState({
          newGeofenceName: '',
          newGeofenceRadius: 100,
          //showNewGeofenceMarker: false,
          newGeofencePoints: [],
          newGeofenceType: '',
          addressSearch: ''
        });
        //this.props.geofences.refetch();
        //this.loadGeofences();
        this.props.history.push('/geofences');
        this.loadGeofences();
      });
    }
  }

  buildRadiusDropdownOptions(values) {
    return radiusList.map((value) => {
      let label = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' feet';

      // Convert to miles over 1000 ft - 1/4 mile
      if (value > 1000) {
        label = (value * 0.000189394).toFixed(2) + ' miles';
      }

      // Parse Int if over 5000 ft - 1 mile
      if (value > 5000) {
        label = parseInt(value * 0.000189394) + ' mile';
      }

      // Use miles for anything over 1 mile
      if (value > 5280) {
        label = parseInt(value * 0.000189394) + ' miles';
      }

      return {
        label,
        value: (value/3.28084)
      };
    })
  }

  componentDidMount() {
    this.loadGeofences();
  }

  geofenceInfoChange(index, newValue, field) {
    console.log('New Value:', newValue);

    let geofences = this.state.geofences.concat();
    let gf = Object.assign({}, geofences[index]);
    gf.updated = true;
    gf[field] = newValue;
    geofences.splice(index, 1, gf);
    this.setState({
      geofences
    });
  }

  saveGeofence(index) {
    const mutation = gql`
    mutation updateBoundary ($boundary_id: Int!, $name: String!, $radius: Int!, $type: String!) {
      updateBoundary (boundary_id: $boundary_id, name: $name, radius: $radius, type: $type)
    }`;
    const variables = this.state.geofences[index];
    console.log('Vars:', variables);
    this.props.client.mutate({
      mutation,
      variables
    }).then((res) => {
      this.loadGeofences();
      this.setState({
        addressSearch: '',
      })
    });
  }

  onNewPolygonVertexDrag(e, index) {
    //console.log(e);
    let newGeofencePoints = this.state.newGeofencePoints.concat();
    let point = newGeofencePoints[index].concat();
    point[0] = e.latlng.lat;
    point[1] = e.latlng.lng;
    newGeofencePoints.splice(index, 1, point);
    this.setState({
      newGeofencePoints,
      newGeofencePosition: this.getCenterFromPoints(newGeofencePoints)
    });
  }

  insertNewPolygonVertex(e, index) {
    //console.log(e);
    let newGeofencePoints = this.state.newGeofencePoints.concat();
    let point = newGeofencePoints[index].concat();
    point[0] = e.latlng.lat;
    point[1] = e.latlng.lng;
    newGeofencePoints.splice(index+1, 0, point);
    this.setState({
      newGeofencePoints,
      newGeofencePosition: this.getCenterFromPoints(newGeofencePoints)
    });
  }

  getCenterFromPoints(points) {
    //console.log('Points:', points);

    const avgLat = points.reduce((accum, pos) => {
      return accum += parseFloat(pos[0]);
    }, 0) / points.length;
    const avgLon = points.reduce((accum, pos) => {
      return accum += parseFloat(pos[1])
    }, 0) / points.length;
    
    //console.log('Avg:', avgLat, avgLon);

    return [avgLat, avgLon];
  }

  zoomToUnits(geofences) {
    // Map center and zoom
    /*
    const avgLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return accum+=gf.latitude;
      } else {
        return accum;
      }
    }, 0) / geofences.length;
    const avgLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return accum+=gf.longitude;
      } else {
        return accum;
      }
    }, 0) / geofences.length;
    */
    const minLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return (gf.latitude < accum ? gf.latitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].latitude);
    const maxLat = geofences.reduce((accum, gf, index) => {
      if (gf.latitude) {
        return (gf.latitude > accum ? gf.latitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].latitude);
    const minLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return (gf.longitude < accum ? gf.longitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].longitude);
    const maxLong = geofences.reduce((accum, gf, index) => {
      if (gf.longitude) {
        return (gf.longitude > accum ? gf.longitude : accum)
      } else {
        return accum;
      }
    }, geofences[0].longitude);
    const latDist = maxLat-minLat;
    const longDist = maxLong-minLong;
    const avgLat = (minLat+maxLat)/2;
    const avgLong = (minLong+maxLong)/2;

    // const zoom = 13-parseInt(latDist*9);

    this.setState({
      center: [avgLat, avgLong],
      mapBounds: [[minLat, minLong], [maxLat, maxLong]],
    });
  }

  onGeofenceClick(pos) {
    this.setState({
      // zoom: 13,
      center: pos
    });
  }

  searchAddress() {

    const url = Reference.geocodio.geocodeUrl+'/?api_key='+Reference.geocodio.apiKey+'&q='+this.state.addressSearch;

    fetch(url)
    .then(res => res.json())
    .then((json) => {
      //console.log('Geocode JSON:', json);
      if (
        json.results
        && json.results.length > 0
        && json.results[0].location
        && json.results[0].location.lat
        && json.results[0].location.lng
      ) {
        const pos = [json.results[0].location.lat, json.results[0].location.lng];
        this.onNewGeofencePositionChange(pos)
        this.setState({
          center: pos,
          newGeofencePosition: pos,
          showNewGeofence: true,
          showNewGeofenceMarker: true,
          setFromAddress: true
        }, () => {
          this.setState({
            setFromAddress: false
          });
        });
      } else {
        window.alert('Coordinates could not be found from address.');
      }
    });
  }

  toggleShow(name) {
    let newState = {};
    newState[name] = !this.state[name];
    localStorage.setItem('gpsleaders-'+name, newState[name] ? 1 : 0);
    console.log(name + ': ' + localStorage.getItem('gpsleaders-'+name));
    this.setState(newState);
  }

  render() {
    return <div className="ui-g" style={{ padding: 0 }}>
      <div className="ui-g-12" style={{ padding: 0 }}>
        <div className="card clearfix" style={{ padding: 0 }}>
          <div className="ui-g-6">
            <i
                className="material-icons"
                style={{color: this.state.showGeofenceList ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30}}
                id="geofenceList"
                onClick={() => {this.toggleShow('showGeofenceList')}}
              >gps_fixed</i><Tooltip for="#geofenceList" title="Geofence List" tooltipPosition="top" />
              <i
                className="material-icons"
                style={{ color: this.state.mapId == 'mapbox.streets-satellite' ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 30 }}
                onClick={() => { this.setState({ mapId: this.state.mapId == 'mapbox.streets-satellite' ? 'mapbox.streets' : 'mapbox.streets-satellite' }) }}
                id="toggleSatelliteView"
              >satellite</i><Tooltip for="#toggleSatelliteView" title="Toggle Satellite View" tooltipPosition="top" />
          </div>
          {/*
          <div className="ui-g-6" style={{textAlign: 'right'}}>
            <Button
              label="Street"
              style={{
                backgroundColor: this.state.mapId == 'mapbox.streets' ? '#51ce9e' : '#cccccc'
              }}
              onClick={() => {this.setState({mapId: 'mapbox.streets'})}}
            />
            <Button
              label="Satellite"
              style={{
                backgroundColor: this.state.mapId == 'mapbox.streets-satellite' ? '#51ce9e' : '#cccccc'
              }}
              onClick={() => {this.setState({mapId: 'mapbox.streets-satellite'})}}
            />
          </div>
          */}
        </div>
        <div className="card clearfix ui-g-12" style={{ borderRadius: 0, border: 0, padding: 0 }}>
          { 
            this.state.showGeofenceList
            ? <ScrollPanel
              className="ui-g-2 ui-lg-3 ui-md-4 ui-sm-12 geofenceResponsive clearfix"
              style={{
                //height: heightOffset,
                padding: 0,
                backgroundColor: '#efefef',
              }}
            >
              { /* <div className="card-group-header geofence-list-header"><i className="material-icons" style={{ position: 'relative', top: '5px', color: '#ffffff', paddingBottom: '10px' }}>gps_fixed</i> Geofences</div> */ }
              <div className="card-group-header geofence-list-header" style={{ padding: '10px', borderRadius: 0 }}>Geofences</div>
              <div className="card-group-content" style={{ padding: '5px', paddingRight: '10px', backgroundColor: '#efefef', backgroundImage: 'none' }}>
              {
                this.state.showNewGeofenceMarker
                ? <div className="geofence-list-item" id="slide">
                  <div className="card-alert-header">{this.state.newGeofenceName.length > 0 ? this.state.newGeofenceName : (this.state.newGeofenceType == '' ? 'Create a new geofence' : (this.state.newGeofenceType == 'polygon' ? 'Click the map to start your polygon.' : 'Click the map to set the center.'))}</div>
                  <div>
                    <table>
                      <tbody>
                        {
                          this.state.newGeofenceType == ''
                          ? <tr>
                            <td className="right-align">Type:</td>
                            <td><Dropdown options={typeOptions} value={this.state.newGeofenceType} onChange={this.onTypeChange.bind(this)} appendTo={document.getElementById('lastElement')} /></td>
                          </tr>
                          : <React.Fragment>
                            <tr>
                              <td className="right-align">Type:</td>
                              <td><Dropdown options={typeOptions} /* disabled={true} */ value={this.state.newGeofenceType} onChange={this.onTypeChange.bind(this)} appendTo={document.getElementById('lastElement')} /></td>
                            </tr>
                            <tr>
                              <td className="right-align">Name:</td>
                              <td><InputText value={this.state.newGeofenceName} placeholder="Name" onChange={this.onNameChange.bind(this)} /></td>
                            </tr>
                            {
                              this.state.newGeofenceType == 'polygon'
                              ? ''
                              : <React.Fragment>
                                <tr>
                                  <td className="right-align">Radius:</td>
                                  <td><Dropdown options={this.buildRadiusDropdownOptions(radiusList)} value={(this.state.newGeofenceRadius)} onChange={this.onRadiusChange.bind(this)} appendTo={document.getElementById('lastElement')} /></td>
                                </tr>
                              </React.Fragment>
                            }        
                            <tr>
                              <td className="right-align">Address:</td>
                              <td>
                                <InputText value={this.state.addressSearch} onChange={(e) => {this.setState({addressSearch: e.target.value})}} />
                                <Button label="Search" className="ui-button-success" onClick={this.searchAddress.bind(this)} />
                              </td>
                            </tr>                  
                            <tr>
                              <td></td>
                              <td>
                                <Button 
                                  className="ui-button-success" 
                                  label="Save" 
                                  onClick={this.saveNewGeofence.bind(this)} 
                                  disabled={this.state.disabled}
                                />
                                <Button 
                                  className="ui-button-warning" 
                                  label="Cancel" 
                                  onClick={() => {
                                    this.setState({
                                      addressSearch: '',
                                      newGeofenceType: '',
                                      newGeofenceName: '',
                                      newGeofencePoints: []
                                    })
                                  }}
                                /></td>
                            </tr>
                          </React.Fragment> 
                        }
                      </tbody>
                    </table>
                  </div>                  
                </div> 
                : ''
              }
              {
                this.state.geofences && this.state.geofences.length > 0
                ? this.state.geofences.map((gf, index) => {
                  return <div className="geofence-list-item">
                    { /* <div className="card-alert-header"> */ }
                    <div>
                      <Button
                        className="p-button-raised p-button-rounded" 
                        style={{width: '100%', backgroundColor: '#4385B3'}}
                        label={gf.name} 
                        onClick={() => { 
                          this.setState({center: [gf.latitude, gf.longitude]}) 
                          setTimeout(() => {
                            this.setState({
                              center: [gf.latitude-.0000001, gf.longitude-.0000001]
                            })
                          }, 10);                      
                        }}
                      />                    
                    </div>
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td className="right-align">Name:</td>
                            <td>
                              <InputText 
                                value={gf.name} 
                                placeholder="Name" 
                                onChange={(e) => {
                                  this.geofenceInfoChange(index, e.target.value, 'name');
                                }}
                              /></td>
                          </tr>
                          {
                            gf.type != 'polygon' ? <tr>
                            <td className="right-align">Radius:</td>
                            <td>
                              <Dropdown 
                                options={this.buildRadiusDropdownOptions(radiusList)} 
                                value={(gf.radius/3.28084)} 
                                appendTo={document.getElementById('lastElement')}
                                onChange={(e) => {
                                  this.geofenceInfoChange(index, parseInt(e.value*3.28084), 'radius');
                                }}
                              /></td>
                            </tr>
                            : ''
                          }
                          <tr>
                            <td className="right-align">Type:</td>
                            <td>
                              <Dropdown 
                                disabled={gf.type == 'polygon'}
                                options={gf.type == 'polygon' ? typeOptions : typeOptions.filter(type => type.value != 'polygon')}
                                value={gf.type} 
                                appendTo={document.getElementById('lastElement')}
                                onChange={(e) => {
                                  this.geofenceInfoChange(index, e.value, 'type');
                                }}
                              /></td>
                          </tr>
                          <tr>
                            <td>
                              <Button 
                                className={gf.updated ? 'ui-button-success' : 'ui-button-danger'} 
                                label={gf.updated ? 'Update' : 'Delete'} 
                                onClick={() => {
                                  if (gf.updated) {
                                    this.saveGeofence(index);
                                  } else { 
                                    this.deleteGeofence(gf.boundary_id);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <Button 
                                label="Google Map" 
                                onClick={() => { window.open('https://www.google.com/maps/search/?api=1&query=' + gf.latitude + ',' +gf.longitude, '_blank') }} />
                            </td>                
                          </tr>
                        </tbody>
                      </table>
                    </div>                  
                  </div> 
                })
                : 'No Geofences'
              }
              </div>
            </ScrollPanel>
            : ''
          }
          <div className={(this.state.showGeofenceList ? 'ui-g-10 ui-lg-9 ui-md-8' : 'ui-g-12') + ' ui-sm-12 clearfix geofence-map'} style={{padding: 0}}>
            <GeofenceMap
              center={this.state.center}
              bounds={this.state.mapBounds}
              zoom={this.state.zoom}
              style={{
                height: heightOffset,
                borderTopRightRadius: 0, //'10px',
                borderBottomRightRadius: 0, //'10px',
                cursor: this.state.newGeofenceType == 'polygon' ? 'crosshair' : 'default'
                
              }}
              
              //className="ui-g-10 ui-lg-9 ui-md-8 ui-sm-12 clearfix geofence-map"
              //className="ui-g-9 geofence-map"
              showNewGeofence={this.state.showNewGeofenceMarker}
              newGeofenceName={this.state.newGeofenceName}
              newGeofencePosition={this.state.newGeofencePosition}
              newGeofenceType={this.state.newGeofenceType}
              newGeofenceRadius={this.state.newGeofenceRadius}
              newGeofencePoints={this.state.newGeofencePoints}
              onNewPolygonVertexDrag={this.onNewPolygonVertexDrag.bind(this)}
              onNewGeofencePositionChange={this.onNewGeofencePositionChange.bind(this)}
              insertNewPolygonVertex={this.insertNewPolygonVertex.bind(this)}
              geofences={this.state.geofences}
              onGeofenceClick={this.onGeofenceClick.bind(this)}
              setFromAddress={this.state.setFromAddress}
              mapId={this.state.mapId}
            />
          </div>
        </div>
      </div>
      <div id="lastElement" />
    </div>;
  }
}

export default withApollo(Geofences);