import React, { Component } from 'react';

import { Card } from 'primereact/components/card/Card';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { ListBox } from 'primereact/components/listbox/ListBox';
import { Panel } from 'primereact/components/panel/Panel';
import { TabView } from 'primereact/components/tabview/TabView';

import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

const GROUPS_QUERY = gql`
query {
  groups {
    group_id
    compid
    name
    units {
      unit_id
      compid
      unittype_id
      imei
      serial
      simnumber
      simphone
      label
      createdAt      
    }
  }
}`;

class VehicleList extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(props) {
    console.log('New Props:', props);
  }

  render() {
    return <div className="right-panel-scroll-content">
      <Panel header="Vehicle List">
        <div className="layout-rightpanel-content">
          {
            (this.props.groups && this.props.groups.groups && this.props.groups.groups.length > 0)
            ? this.props.groups.groups.map((group) => {
              return (
                <div>
                  <h3 className="first"><i className="material-icons">location_on</i>{group.name}</h3>
                  {
                    (group.units && group.units.length > 0)
                    ? <ListBox options={
                        group.units.map((unit) => ({
                          label: unit.label,
                          value: unit.unit_id
                        }))
                      }
                      />
                    : <span>No Units</span>
                  }                  
                </div>
              );
            })
            : <span>No Groups</span>
          }
        </div>
      </Panel>
    </div>
  }
}

export default compose(
  graphql(GROUPS_QUERY, {
    name: 'groups'
  })
)(VehicleList);