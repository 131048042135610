import React, { Component } from 'react';

import Contact from './Contact';
import Unit from './Unit';

import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

const ALERTS_QUERY = gql`
query {
  alerts {
    alert_id
    compid
    alerttype_id
    alertType {
      alerttype_id
      name
      description
    }
    unit_id
  }
}`;

class AccountAlerts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hasLowBatteryAlerts: false,
      hasTamperAlerts: false,
    }
  }

  componentWillReceiveProps(props) {
    if (props.alerts && props.alerts.alerts && props.alerts.alerts.length > 0) {
      props.alerts.alerts.forEach((alert) => {
        if (alert.alertType.name == 'lowbattery') {
          this.setState({
            lowBatteryAlertId: alert.alert_id
          });
        }

        if (alert.alertType.name == 'tamper') {
          this.setState({
            tamperAlertId: alert.alert_id
          });
        }
      })
    }
  }

  toggleLowBatteryAlert() {  }

  render() {
    return <div style={{ padding: '5px' }}>
      <table>
        <tbody>
        <tr>
            <td style={{ width: '150px' }}>
              <div style={{ width: '100%', textAlign: 'center', color: '#555555', fontWeight: 'bold' }}>Low Battery</div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <i
                  className="material-icons"
                  style={{ color: this.state.lowBatteryAlertId > 0 ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 130 }}
                  onClick={this.toggleLowBatteryAlert.bind(this)}
                >battery_alert</i>
              </div>
            </td>
            <td>
              <div>
                <div>
                  {
                    this.state.lowBatteryAlertId > 0  
                    ? this.props.units.length > 0
                      ? this.props.units.map(unit => <Unit unit={unit} contacts={this.props.contacts} alertId={this.state.lowBatteryAlertId} />)
                      : 'No Units'
                    : '< Click to enable'
                  }                  
                </div>
                <div className="clearfix" style={{ padding: '10px', border: '1px solid #dedede', borderTop: 0 }}>
                  {
                    this.props.contacts && this.props.contacts.contacts && this.props.contacts.contacts.length > 0
                    ? this.props.contacts.contacts.map(contact => <Contact contact={contact} />)
                    : 'No Contacts'
                  }
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: '150px' }}>
              <div style={{ width: '100%', textAlign: 'center', color: '#555555', fontWeight: 'bold' }}>Tamper</div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <i
                  className="material-icons"
                  style={{ color: this.state.tamperAlertId > 0 ? '#51ce9e' : '#cccccc', cursor: 'pointer', fontSize: 130 }}
                >lock_open</i>
              </div>
            </td>
            <td>
              <div>
                {
                  this.state.tamperAlertId > 0  
                  ? this.props.units.length > 0
                    ? this.props.units.map(unit => <Unit unit={unit} contacts={this.props.contacts} alertId={this.state.lowBatteryAlertId} />)
                    : 'No Units'
                  : '< Click to enable'
                }
              </div>
              <div className="clearfix" style={{ padding: '10px', borderTop: '2px solid #4385b4' }}>
                {
                  this.props.contacts && this.props.contacts.contacts && this.props.contacts.contacts.length > 0
                  ? this.props.contacts.contacts.map(contact => <Contact contact={contact} />)
                  : 'No Contacts'
                }
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }
}

export default compose(
  graphql(ALERTS_QUERY, {
    name: 'alerts'
  })
)(withApollo(AccountAlerts));