import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import createBrowserHistory from 'history/createBrowserHistory';

// Apollo imports
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { create } from 'domain';


const history = createBrowserHistory();
const location = history.location;
const unlisten = history.listen(((location, action) => {
  console.log(action, location.pathname, location.state);
}));

const httpLink = createHttpLink({
  uri: 'https://w0ansq5va5.execute-api.us-west-1.amazonaws.com/Prod/graphql', // Prod
  opts: {
    mode: 'no-cors',
  }
});

const authLink = setContext((_, { headers }) => {
  if (localStorage.getItem('GPSLeadersTrackingAuthToken') === null) {
    localStorage.setItem('GPSLeadersTrackingAuthToken', '');
  }
  const token = localStorage.getItem('GPSLeadersTrackingAuthToken');
  return {
    headers: {
      Authorization: token,
      ...headers,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter history={history}>
      <App client={client} history={history} />
    </BrowserRouter>
  </ApolloProvider>
  ,
  document.getElementById('root')
);

registerServiceWorker();