import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';
import ReactSelect from 'react-select';

import { AutoComplete } from 'primereact/components/autocomplete/AutoComplete';
import { Button } from 'primereact/components/button/Button';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { Card } from 'primereact/components/card/Card';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';

import moment from 'moment-timezone';

const VEHICLES_QUERY = gql`
query {
  units {
    unit_id
    label
    vehicle {
      icon
    }
  }
}`;

class TrailSetup extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      //dates: '',
      startDate: new Date(moment().startOf('day').valueOf()),
      endDate: new Date(moment().startOf('day').valueOf()),
      vehicles: [],
      selectedVehicles: [],
      vehicleSearch: '',
      vehicleSuggestions: [],
      heartbeat: this.props.heartbeat
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(props) {}

  getExtensibleObject(obj) {
    return Object.assign({}, obj);
  }

  suggestVehicles(e) {
    this.setState({
      vehicleSuggestions: this.props.vehicles && this.props.vehicles.units
      ? this.props.vehicles.units.filter( (unit) => (unit.label.toLowerCase().includes(e.query.toLowerCase())) ).map( (unit) => (this.getExtensibleObject(unit)) )
      : []
    }, () => {
      console.log('Suggestions:', this.state.vehicleSuggestions);
    });
  }

  updateTrails() {
    if (this.props.updateTrails) {
      this.props.updateTrails(this.state.startDate, this.state.endDate, this.state.selectedVehicles);
    }
  }

  checkAndUpdate() {
    if (this.state.startDate.length > 1 && this.state.endDate.length > 1 && this.state.selectedVehicles.length > 0) {
      this.updateTrails();
    }
  }

  toggleHeartbeat(){
    this.setState({heartbeat: !this.state.heartbeat})
    this.props.toggleHeartbeat(!this.state.heartbeat);
  }

  onSelectedUnitsChange(e) {
    this.setState({ selectedVehicles: e });
  }

  render() {
    return (
      <div style={{ position: 'relative', zIndex: 999, backgroundColor: '#f4f4f4' }}>
        <div className="card-group-header geofence-list-header" style={{ padding: '10px', borderRadius: 0 }}>Setup</div>
        <div className="card-group-content" style={{ padding: '5px', paddingRight: '10px', backgroundColor: '#f4f4f4', backgroundImage: 'none' }}>
          <Card className="alert-card-unit clearfix" style={{ marginBottom: '10px' }}>            
            <div>
              <Checkbox inputId="heartbeat" onChange={this.toggleHeartbeat.bind(this)} checked={this.state.heartbeat}></Checkbox>
              <label htmlFor="heartbeat" className="p-checkbox-label">Heartbeat</label>
              <Calendar
                dateFormat="mm/dd/y"
                //selectionMode="range"
                placeholder="Start Date"
                value={ this.state.startDate }
                onChange={ (e) => {
                  this.setState({
                    startDate: e.value
                  }, () => {
                    //this.checkAndUpdate();
                  })
                } }
                style={{ width: '100%', zIndex: 999, }}
                inputStyle={{ textAlign: 'center', width: '100%', zIndex: 999, padding: '10px' }}
                hideOnDateTimeSelect={true}
                panelStyle={{ zIndex: 999, }}
                icon="calendar_today"
              />
              <Calendar
                dateFormat="mm/dd/y"
                //selectionMode="range"
                placeholder="End Date"
                value={ this.state.endDate }
                onChange={ (e) => {
                  this.setState({
                    endDate: e.value
                  }, () => {
                    //this.checkAndUpdate();
                  })
                } }
                style={{ width: '100%', zIndex: 899, }}
                inputStyle={{ textAlign: 'center', width: '100%', zIndex: 999, padding: '10px' }}
                hideOnDateTimeSelect={true}
                panelStyle={{ zIndex: 999, }}
                icon="calendar_today"
              />
              { /*
              <Calendar
                dateFormat="mm/dd/y"
                selectionMode="range"
                placeholder="Choose Date Range"
                value={ this.state.dates }
                onChange={ (e) => {
                  this.setState({
                    dates: e.value
                  }, () => {
                    //this.checkAndUpdate();
                  })
                } }
                style={{ width: '100%', zIndex: 999, }}
                inputStyle={{ textAlign: 'center', width: '100%', zIndex: 999, padding: '10px' }}
                hideOnDateTimeSelect={true}
                panelStyle={{ zIndex: 999, }}
                icon="calendar_today"
              />
              */ }
            </div>
            <div className="ui-g-9 clearfix" style={{ padding: 0, margin: 0 }}>
              <ReactSelect
                classNamePrefix="react-select"                
                ref={this.reactSelect}
                isMulti={true}
                isSearchable={true}
                value={this.state.selectedVehicles}
                options={this.props.vehicles && this.props.vehicles.units
                  ? this.props.vehicles.units.map( unit => ( { label: unit.label, value: unit.unit_id, unit_id: unit.unit_id, icon: unit.vehicle.icon }) )
                  : []
                }
                placeholder="Choose units"
                onChange={this.onSelectedUnitsChange.bind(this)}
                closeMenuOnSelect={false}
              />
              { /*
              <AutoComplete
                field="label"
                multiple={true}
                placeholder="Select Vehicles"            
                suggestions={this.state.vehicleSuggestions}
                minLength={0}
                value={this.state.selectedVehicles}
                onChange={ (e) => {
                  //console.log('E:', e);              
                  this.setState({
                    selectedVehicles: e.value
                  }, () => {
                    console.log('Start:', this.state.startDate.toString().length);
                    console.log('End:', this.state.endDate);
                    console.log('Selected Vehicles:', this.state.selectedVehicles.length);
                    //console.log('Dates:', this.state.dates);
                    //this.checkAndUpdate();
                  });
                } }
                completeMethod={this.suggestVehicles.bind(this)}
                dropdown={true}
                style={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
              />
              */ }
            </div>
            <div className="ui-g-3 clearfix">
              <Button
                label="Run" 
                className="ui-button-success" 
                style={{ width: 'calc(100% - 10px)', margin: '5px', marginBottom: 0 }} 
                disabled={
                  !(
                    this.state.startDate &&
                    this.state.endDate &&
                    this.state.startDate <= this.state.endDate &&
                    this.state.startDate.toString().length > 1 &&
                    this.state.endDate.toString().length > 1 && 
                    this.state.selectedVehicles.length > 0
                  )}
                onClick={this.updateTrails.bind(this)}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(VEHICLES_QUERY, {
    name: 'vehicles'
  })
)(withApollo(TrailSetup));