import React, { Component } from "react";

import { Button } from "primereact/components/button/Button";
import { Card } from 'primereact/components/card/Card';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';

import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import moment from "moment";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import queryString from 'query-string'
import L from 'leaflet';

import ReportTable from "./components/Reports/ReportTable";
import Helpers from './libs/Helpers';


const DEVICE_TIME_FORMAT = 'UTC:YYYY/MM/DD HH:mm:ss.SSS';
const fancyTime = 'MM/DD/YY hh:mm A';
const start = moment().subtract(30, 'days').format(DEVICE_TIME_FORMAT);
const end = moment().format(DEVICE_TIME_FORMAT)

const UNIT_FROM_TOKEN_QUERY = gql`
      query unitFromRepoToken ($repotoken: String!) {
        unitFromRepoToken(repotoken: $repotoken) {
          unit_id
          compid
          hid
          unittype_id
          imei
          serial
          simnumber
          simphone
          simactive
          drive_off_compid
          next_renew_date
          expired_confirmed
          eot_version
          eot_retry
          alert_sent_7
          alert_sent_30
          alert_sent_60
          expiration_alert_sent
          subscription_cycle
          label
          timezone
          function
          programming
          yearly_price
          reseller_compid
          reseller_markup
          dealer_compid
          dealer_markup
          kore_status
          last_activated
          about_unit
          pings_enabled
          unlimited_pings
          preload
          salesrep
          category
          vzw_vpn
          vzw_bucket
          group_id
          conduit_status
          pu_status
          simtype_id
          bucket_id
          manufacturerPO
          carrierstatus_id
          active
          ignitiontype
          starterinterrupt
          trackingmode
          trackingexpire
          lastreportedvoltage
          repotoken
          repotokenexpire
          lot_id
          createdAt
          lastEvent
        }
      }
`;

class Repo extends Component {
  constructor(props) {
    super(props);
    const parseToken = queryString.parse(props.location.search)
    const { repotoken } = parseToken

    this.state = {
      mapWidth: 0,
      mapHeight: 0,
      selectedUnitId: 0,
      mapZoom: 5,
      overlayPosition: [41.850033, -87.6500523],
      inititalLoad: true,
      overlayInfo: {},
      mapId: "mapbox.streets",
      reportType: 'history',
      repotoken,
      clientNotified: false,
      // center:[41.850033, -87.6500523]
    };
    
    this.createGeofence = this.createGeofence.bind(this);
    this.notifyClient = this.notifyClient.bind(this);
  }

  async componentDidMount() {

    try {
      const query = UNIT_FROM_TOKEN_QUERY;
      const { repotoken } = this.state;
      const res = await this.props.client.query({ query, variables: { repotoken } })
      const { data: { unitFromRepoToken: unit } } = res;
      let center = [];
      let unitSet = false;
      try {
        center = [unit.lastEvent.event_data.latitude, unit.lastEvent.event_data.longitude];
        unitSet = true;
      } catch (error) {
        center = [41.850033, -87.6500523]
      }      
      this.setState({
        unit,
        event: unit.lastEvent,
        // mapWidth: document.getElementById("map-container").clientWidth - 30,
        mapHeight: 'calc(100vh - 250px)',
        center,
        unitSet
      });

      this.createTopStopsReport();
      this.createHistoryReport();

    } catch (error) {
      window.alert('token has expired')
      console.info(error);
    }
  }
  
  renderButtonBar() {
    return (
      <div className="card clearfix">
        <div className="ui-g-6" style={{ textAlign: "left" }}>
          {
            (this.state.geofenceCreated) ?
              <p>{this.state.geofenceMessage}</p>: <Button
                label="Geofence"
                style={{ backgroundColor: "#51ce9e" }}
                onClick={this.createGeofence}
                disabled={(this.state.geofenceCreated) ? true : false}
              />
          }
          {
            (this.state.clientNotified) ?
              <p>{this.state.clientMessage}</p> : <Button
              label="Notify"
              style={{ backgroundColor: "#51ce9e" }}
              disabled={(this.state.clientNotified) ? true : false}
              onClick={this.notifyClient}
            />
          }
        </div>
        <div className="ui-g-6" style={{ textAlign: "right" }}>
          <Button
            label="Street"
            style={{
              backgroundColor:
                this.state.mapId == "mapbox.streets" ? "#51ce9e" : "#cccccc"
            }}
            onClick={() => {
              this.setState({ mapId: "mapbox.streets" });
            }}
          />
          <Button
            label="Satellite"
            style={{
              backgroundColor:
                this.state.mapId == "mapbox.streets-satellite"
                  ? "#51ce9e"
                  : "#cccccc"
            }}
            onClick={() => {
              this.setState({ mapId: "mapbox.streets-satellite" });
            }}
          />
        </div>
      </div>
    );
  }

  async createHistoryReport() {
    try {
      /*
      const query = gql`
      query lastEventsFromRepoToken ($unit_id: Int!, $start: String!, $end: String!) {
        lastEvents(unit_id: $unit_id, start_date: $start, end_date: $end)
      }`;
      */
      const query = gql`
      query lastEventsFromRepoToken ($repoToken: String!, $start: String!, $end: String!) {
        lastEventsFromRepoToken(repoToken: $repoToken, start_date: $start, end_date: $end)
      }`;

      const { state: { unit: { unit_id }, repotoken } } = this;
      const variables = {
        repoToken: repotoken,
        start: 'UTC:'+moment().utc().add(-30, 'days').format('YYYY/MM/DD HH'),
        end: 'UTC:'+moment().utc().add(1, 'day').format('YYYY/MM/DD HH'),
      };
      const res = await this.props.client.query({ query, variables });

      this.eventBasedReport(res, 'lastEventsFromRepoToken');

    } catch (error) {
      console.info(error);
    }

  }

  async createTopStopsReport() {
    try {
      const { state: { unit: { unit_id }, repotoken } } = this;
      /*
      const query = gql`
      query topStopsFromRepoToken ($unit_id: Int!, $start: String!, $end: String!) {
        topStops(unit_id: $unit_id, start_date: $start, end_date: $end)
      }`;
      */
      const query = gql`
      query topStopsFromRepoToken ($repoToken: String!, $start: String!, $end: String!) {
        topStopsFromRepoToken(repoToken: $repoToken, start_date: $start, end_date: $end)
      }`;
      const variables = {
        repoToken: repotoken,
        start: 'UTC:'+moment().utc().add(-30, 'days').format('YYYY/MM/DD HH'),
        end: 'UTC:'+moment().utc().add(1, 'day').format('YYYY/MM/DD HH'),
      };
      const res = await this.props.client.query({ query, variables });
      this.eventBasedReport(res, 'topStopsFromRepoToken');

    } catch (error) {
      console.info(error);
    }

  }

  async notifyClient() {
    try {
      const { state: { repotoken } } = this;
      const mutation = gql`
      mutation completeRepo ($repotoken: String!) {
        completeRepo (repotoken: $repotoken)
      }`;
      const variables = { repotoken };
      await this.props.client.mutate({ mutation, variables })
      this.setState({
        clientNotified: true,
        clientMessage: `client has been notified`
      });
    } catch (error) {
      console.info(error);
    }
  }

  async createGeofence() {
    try {
      const radius = 300;

      const mutation = gql`
      mutation addBoundary ($type: String!, $name: String!, $radius: Int!, $latitude: Float!, $longitude: Float!) {
        addBoundary (type: $type, name: $name, radius: $radius, latitude: $latitude, longitude: $longitude)
      }`;
      const variables = { type: 'circle', name: `repoGeofence${moment().format("MMDDYYHHMMSS")}`, radius, 
      latitude: this.state.center[0], longitude:this.state.center[1] };
      await this.props.client.mutate({ mutation, variables })
      this.setState({
        geofenceCreated: true,
        geofenceMessage: `geofence with ${radius} ft radius created around coords ${this.state.center}`
      });
    } catch (error) {
      console.info(error);
    }
  }

  eventBasedReport(res, key) {

    let reportData = [];
    reportData = res.data[key].map((event, index) => {

      const coords = (key !== 'topStopsFromRepoToken') ? Helpers.cleanLatLong(event.event_data.latitude, event.event_data.longitude) :
      `${res.data[key][index].event_data.latitude}, ${res.data[key][index].event_data.longitude}`;

      /*
      if (key === 'topStopsFromRepoToken') {
        console.log(index, res.data[key][index]);
      }
      */
      
      if (event && event.event_data) {
        return {
          device_time: event.event_data.device_time,
          time: moment.unix(event.event_data.device_time).format(fancyTime),
          event_type: event.event_data.conduit_event_description
            ? event.event_data.conduit_event_description
            : Helpers.cleanEventType(event.device_type, event.event_data.event_type),
          coords,
          address: event.event_data.address ? event.event_data.address : 'None',
          stop_count: (key === 'topStopsFromRepoToken') ? res.data[key][index].stop_count : '',
          actions: this.setupInteractiveReport(coords, event)
        };
      }
    });
    reportData.sort((A, B) => B.device_time - A.device_time);

    if (key === 'topStopsFromRepoToken') {
      reportData.sort((A, B) => B.stop_count - A.stop_count);
      this.setState({ topStopsData: reportData });
    } else {
      this.setState({ historyData: reportData });
    }
  }

  renderReportBasedOnType(reportType) {
    this.setState({ reportType });
    switch (reportType) {
      case 'history':
        this.createHistoryReport();
        break;
      case 'top':
        this.createTopStopsReport();
        break;
      default:
        return ''
    }

  }

  setupInteractiveReport(coords, event) {
    if (coords) {
    
      return (
        <React.Fragment>
          <i
            className="material-icons"
            style={{ color: '#51ce9e', cursor: 'pointer', fontSize: 20 }}
            onClick={() => {
              console.log('Coords:', coords);
              this.setState({ center: coords.split(','), event });
            }}
          >map</i>
        </React.Fragment>
      );
    } else return null; 
  }

  renderMap() {

    return (
      <Map 
        center={this.state.center} 
        zoom={15} 
        //style={{ width: '100%', height: '50vw' }}
        style={{ width: '100%', height: '25vw' }}
        scrollWheelZoom={false}
        dragging={ !L.Browser.mobile }
      >
        <TileLayer
          url={`https://api.tiles.mapbox.com/v4/${this.state.mapId}/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoiZ3BzbGVhZGVycyIsImEiOiJjamw4aXhmNXIwMHVmM3BydW04cmJ5cXE0In0.mLNavvJJLhaP6DANzEyatw`}
          attribution=""
        />
        {
          (this.state.center) ? <React.Fragment>
            <Marker position={this.state.center}>
            {(this.state.center) ? this.renderPopup(this.state.unit) : ''}
            </Marker>
          </React.Fragment>: null
        }        
      </Map>
    )
  }

  renderPopup(unit) {
    return (
      <Popup className="map-marker-popup">
        <Card
          style={{ border: 0 }}
          header={<div className="card-alert-header"><b>{unit.label}</b></div>}
        >
          <table style={{
            padding: 0,
            cellPadding: '5px',
            fontSize: '12px',
            color: '#555555'
          }}>
            <tbody>
              <tr>
                <td style={{ textAlign: 'right', verticalAlign: 'top' }}><i className="material-icons" id="info">info</i><Tooltip for="#info" title="Event Information" tooltipPosition="top" /></td>
                <td>{Helpers.cleanEventType(this.state.event.device_type, this.state.event.event_data.event_type)} on {moment.unix(this.state.event.event_data.device_time).format('MMMM Do, YYYY')} @ {moment.unix(this.state.event.event_data.device_time).format('h:mm:ss A')}</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'right', verticalAlign: 'top' }}><i className="material-icons" id="location">place</i><Tooltip for="#location" title="Event Location" tooltipPosition="top" /></td>
                <td>{`${this.state.center}`}</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'right', verticalAlign: 'top' }}><i className="material-icons" id="address">home</i><Tooltip for="#address" title="Event Address" tooltipPosition="top" /></td>
                <td>{this.state.event.event_data.address ? this.state.event.event_data.address : 'None'}</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'right', verticalAlign: 'top' }}><i className="material-icons" id="speed">av_timer</i><Tooltip for="#speed" title="Speed in MPH" tooltipPosition="top" /></td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>{Helpers.cleanSpeed(this.state.event.device_type, this.state.event.event_data.speed)}</td>
                        <td style={{ textAlign: 'right' }}><i className="material-icons" id="heading">directions</i><Tooltip for="#heading" title="Heading" tooltipPosition="top" /></td>
                        <td>{Helpers.degreesToDirection(this.state.event.event_data.heading_degrees)}</td>
                        <td style={{ textAlign: 'right' }}><i className="material-icons" id="satellites">settings_input_antenna</i><Tooltip for="#satellites" title="Satellite Count" tooltipPosition="top" /></td>
                        <td>{this.state.event.event_data.satellite_count}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td></td>
                <td><Button label="Google Map" onClick={() => { window.open('https://www.google.com/maps/search/?api=1&query=' + this.state.center, '_blank') }} /></td>
              </tr>
            </tbody>
          </table>
        </Card>
      </Popup>
    );
  }
  render() {
    return (
      <div className="ui-g">
        <div className="ui-g-12">
          {this.renderButtonBar()}

          <div className="card clearfix">
            {this.renderMap()}
          </div>


          <div className="card clearfix">
            <div className="ui-g-6" style={{ textAlign: "left" }}>
              <Button
                label="Details"
                style={{ backgroundColor: (this.state.reportType === 'history') ? "#51ce9e" : "#cccccc" }}
                onClick={() => { this.renderReportBasedOnType('history') }}
              />
            </div>
            <div className="ui-g-6" style={{ textAlign: "right" }}>
              <Button
                label="Top Stops"
                style={{ backgroundColor: (this.state.reportType === 'top') ? "#51ce9e" : "#cccccc" }}
                onClick={() => { this.renderReportBasedOnType('top') }}
              />
            </div>
          </div>
          <div className="card" id="map-container" style={{ display: (this.state.reportType === 'history') ? '' : 'none' }} >
            <ReportTable data={this.state.historyData} reportType={'history'} repo={true} />
          </div>
          <div className="card" id="map-container" style={{ display: (this.state.reportType === 'top') ? '' : 'none' }} >
            <ReportTable data={this.state.topStopsData} reportType={'top'} repo={true} />
          </div>
        </div>
      </div>
    );

  }
}

export default withRouter(withApollo(Repo));
