import React, { Component } from 'react';

import GeofenceAlert from './GeofenceAlert';

class GeofenceAlertList extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      geofenceAlerts: []
    }
  }

  componentDidMount() {
    if (this.props.geofenceAlerts) {
      this.mapGeofenceAlerts(this.props.geofenceAlerts);
    }
  }

  componentWillReceiveProps(props) {
    if (props.geofenceAlerts) {
      console.log('GeofenceAlertList:', props);
      this.mapGeofenceAlerts(props.geofenceAlerts);
    }
  }

  mapGeofenceAlerts(geofenceAlerts) {
    this.setState({
      geofenceAlerts: geofenceAlerts.map(geofenceAlert => Object.assign({}, geofenceAlert, {
        editStep: 'done'
      }))
    })
  }

  onGeofenceAlertDelete(res) {
    if (this.props.reloadGeofenceAlerts) {
      this.props.reloadGeofenceAlerts();
    }
  }

  render() {
    return <div className="ui-g" style={{ padding: '10px' }}>
    <div className="ui-g-12 card-alert-header geofenceAlertResponsiveHeader">
      <div className="ui-g-2" style={{ paddingLeft: '5px' }}>Geofence</div>
      <div className="ui-g-2" style={{ paddingLeft: '5px' }}>Triggers</div>
      <div className="ui-g-3" style={{ paddingLeft: '5px' }}>Units</div>
      <div className="ui-g-3" style={{ paddingLeft: '5px' }}>Contacts</div>
      <div className="ui-g-2" style={{ paddingLeft: '5px' }}>Actions</div>
    </div>
    { this.state.geofenceAlerts.length > 0
      ? this.state.geofenceAlerts.map((geofenceAlert, index) => {
        return <GeofenceAlert
          geofenceAlert={geofenceAlert}
          units={this.props.units}
          contacts={this.props.contacts}
          style={{ borderBottom: index < this.state.geofenceAlerts.length - 1 ? '1px solid #cccccc' : 0 }}
          onDelete={this.onGeofenceAlertDelete.bind(this)}
        />
      })
      : 'No Geofence Alerts'
    }
    </div>
  }
}

export default GeofenceAlertList;